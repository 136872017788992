import { useState } from 'react';
import Square from '../general/Square';
import MainButton from '../general/MainButton';
import { Link } from 'react-router-dom';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import projectsApi from '../../API/projects/projects.api';
import Loader from '../general/Loader';
import { uploadFilesToGoogleDrive } from '../../services/uploadFileGoogleDrive';

function ProyectFivthStep({ step, next, previous }) {
  const [listDocs, setListDocs] = useState([]);

  const [isUploading, setIsUploading] = useState({
    faculty_council_approval: {
      loading: false,
      msg: '',
      success: null,
    },
    technical_board_approval: {
      loading: false,
      msg: '',
      success: null,
    },
    curriculum_vitae_teaching_staff: {
      loading: false,
      msg: '',
      success: null,
    },
    letters_dedication_personnel: {
      loading: false,
      msg: '',
      success: null,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [backendError, setBackendError] = useState('');

  const addDocument = ({ target }) => {
    if (!target.files[0]) return null;

    setIsUploading({
      ...isUploading,
      [target.name]: {
        loading: true,
        msg: 'Cargando...',
        success: false,
      },
    });

    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      const rawLog = reader.result.split(',')[1];

      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      };

      try {
        const docUrl = await uploadFilesToGoogleDrive(dataSend);

        if (!docUrl?.url) {
          setIsUploading((state) => ({
            ...isUploading,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        const data = {
          name: target.name,
          file: docUrl,
        };

        setListDocs([...listDocs, data]);

        setIsUploading((state) => ({
          ...isUploading,
          [target.name]: {
            loading: false,
            msg: `Archivo ${file.name} Guardado exitosamente.`,
            success: true,
          },
        }));
      } catch (error) {
        console.log('Error al subir archivo', error);
        return setIsUploading((state) => ({
          ...isUploading,
          [target.name]: {
            loading: false,
            msg: `Error al guardar documento ${file}, intente de nuevo.`,
            success: false,
          },
        }));
      }
    };
  };

  const checkSomeDocumentIsLoading = () =>
    Object.values(isUploading).some((loading) => loading.loading);

  const saveDocuments = async () => {
    if (listDocs.length < 2) {
      return setBackendError('Debe ingresar todo los documentos requeridos.');
    }

    const id_project = handleStorage.getItemLocalStorage(
      'id_new_project_condes'
    );

    const data = {
      id_project: id_project,
      documents: listDocs,
    };

    setIsLoading(true);

    const response = await projectsApi.updateProjectByFlag(data, 'stepFive');

    setIsLoading(false);

    if (response?.data?.success === true) {
      next();
      handleStorage.clearItemLocalStorage('id_new_project_condes');
      handleStorage.clearItemLocalStorage('condes_project_step');
      return;
    }

    if (response.data.success === false) {
      setBackendError(response.data.msg);
    }
  };

  return (
    <div
      className={
        step === 5
          ? 'w-full flex flex-col justify-center items-center'
          : 'hidden'
      }
    >
      <Square>
        <div className='w-10/12 flex items-center justify-between text-xl'>
          <p className='text-link'>Paso 5/5</p>
          <Link to={'/'}>
            <p className='font-bold text-red-600'>x</p>
          </Link>
        </div>
        <h3 className='text-link text-center my-12'>DOCUMENTOS</h3>
        <form className='form-content'>
          <div className='w-10/12 space-y-2 flex-col flex justify-center items-start'>
            <p>Aprobación del consejo de la facultad</p>
            <div className='w-full flex flex-col justify-center items-center'>
              <input
                accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                type='file'
                name='faculty_council_approval'
                onChange={addDocument}
                disabled={checkSomeDocumentIsLoading()}
              />
              {isUploading.faculty_council_approval.success !== null && (
                <p className='text-green-600'>
                  {isUploading.faculty_council_approval.msg}
                </p>
              )}
            </div>
          </div>

          <div className='w-10/12 space-y-2 flex-col flex justify-center items-start'>
            <p>
              Aprobación del consejo técnico o del jefe del departamento o
              instancia equivalente
            </p>
            <div className='w-full flex flex-col justify-center items-center'>
              <input
                accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                type='file'
                name='technical_board_approval'
                onChange={addDocument}
                disabled={checkSomeDocumentIsLoading()}
              />
              {isUploading.technical_board_approval.success !== null && (
                <p className='text-green-600'>
                  {isUploading.technical_board_approval.msg}
                </p>
              )}
            </div>
          </div>

          {/* <div className='w-10/12 space-y-2 flex-col flex justify-center items-start'>
            <p>
              Curriculum Vitae del personal docente y de investigación adscrito
              al proyecto (Solo para aquellos que no sean miembros del LUZ)
            </p>
            <div className='w-full flex flex-col justify-center items-center'>
              <input
                accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                type='file'
                name='curriculum_vitae_teaching_staff'
                onChange={addDocument}
                disabled={checkSomeDocumentIsLoading()}
              />
              {isUploading.curriculum_vitae_teaching_staff.success !== null && (
                <p className='text-green-600'>
                  {isUploading.curriculum_vitae_teaching_staff.msg}
                </p>
              )}
            </div>
          </div>

          <div className='w-10/12 space-y-2 flex-col flex justify-center items-start'>
            <p>
              Cartas que indiquen las horas de dedicación del personal docente y
              de investigación del proyecto, incluyendo becarios académicos,
              auxiliares docentes y PIN, firmada por el/la investigador(a) y por
              el/la jefe de la unidad docente de la adscripción
            </p>
            <div className='w-full flex flex-col justify-center items-center'>
              <input
                accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                type='file'
                name='letters_dedication_personnel'
                onChange={addDocument}
                disabled={checkSomeDocumentIsLoading()}
              />
              {isUploading.letters_dedication_personnel.success !== null && (
                <p className='text-green-600'>
                  {isUploading.letters_dedication_personnel.msg}
                </p>
              )}
            </div>
          </div> */}

          {backendError.length > 0 && (
            <p className='text-red-600 text-center'>{backendError}</p>
          )}
          <div className='flex justify-between items-center w-10/12'>
            <MainButton text='Atras' event={previous} type='button' />
            <MainButton text='Continuar' event={saveDocuments} type='button' />
          </div>
        </form>
        <Loader isOpen={isLoading} />
      </Square>
    </div>
  );
}

export default ProyectFivthStep;
