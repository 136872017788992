import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import countries from '../resources/js/countries.json';
import contactUsSchema from '../security/schemas/contactUs.schema';
import publicApi from '../API/public/public.api';

function Credentials() {
  const json = countries;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });

  const [messageNotification, setMessageNotification] = useState('');

  const sendCredentials = async (data) => {
    const response = await publicApi.sendCredencials(data);
    response.data.success && setMessageNotification('Envio exitoso.');
    !response.data.success && setMessageNotification(response.data.msg);
    response.data.success && reset();
    console.log('data ->', response.data);
  };

  return (
    <div className="bg-main-background flex flex-col items-center justify-center">
      <h1 className="text-main-blue pt-12 font-bold text-4xl text-center">
        Solicitud de credenciales
      </h1>
      {errors?.name && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.name?.message}
        </div>
      )}
      {errors?.last_name && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.last_name?.message}
        </div>
      )}
      {errors?.email && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.email?.message}
        </div>
      )}
      {errors?.country && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.country?.message}
        </div>
      )}
      {errors?.cod_number && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.cod_number?.message}
        </div>
      )}
      {errors?.phone && (
        <div className="w-full flex items-center justify-center text-red-600">
          {errors?.phone?.message}
        </div>
      )}
      {messageNotification !== '' && (
        <div className="w-full flex items-center justify-center text-green-600">
          {messageNotification}
        </div>
      )}
      <Square>
        <form
          className="form-content"
          onSubmit={handleSubmit(sendCredentials)}
          encType="multipart/form-data"
        >
          <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
            <input
              {...register('name')}
              type="text"
              placeholder="Nombre"
              className="rounded-full border px-4 w-full md:w-1/2"
            />
            <input
              {...register('last_name')}
              type="text"
              placeholder="Apellido"
              className="rounded-full border px-4 w-full md:w-1/2"
            />
          </div>
          <input
            {...register('email')}
            type="email"
            placeholder="Email"
            className="rounded-full border px-4 w-10/12"
          />
          <select
            className="rounded-full border px-4 w-10/12"
            {...register('country')}
          >
            <option disabled selected className='text-gray-200'>Selecciona un pais</option>
            {Object.keys(json).map((key, i) => (
              <option key={i} value={json[key].name}>
                {json[key].name}
              </option>
            ))}
          </select>
          <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
            <input
              {...register('cod_number')}
              type="text"
              placeholder="+ Código"
              className="rounded-full border px-4 w-full md:w-1/3"
            />
            <input
              {...register('phone')}
              type="number"
              placeholder="Número de teléfono"
              className="rounded-full border px-4 w-full md:w-2/3"
            />
          </div>

          <MainButton text="Enviar" />
        </form>
      </Square>
    </div>
  );
}

export default Credentials;
