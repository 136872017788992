import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import images from '../resources/images';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import publicApi from '../API/public/public.api';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../components/general/Loader';

const SchemaEmail = yup.object().shape({
  email: yup
    .string()
    .email('Introduzca un correo valido.')
    .required('Correo es requerido.'),
});

function RecoverPassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SchemaEmail),
  });

  const [messageNotification, setMessageNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const recoverPassword = async (data) => {
    setIsLoading(true);
    const response = await publicApi.recoverPassword(data);
    setIsLoading(false);
    response.data.success && setMessageNotification(response.data.msg);
    !response.data.success && setMessageNotification(response.data.msg);
    response.data.success && reset();
  };

  return (
    <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
      <h1 className='text-4xl font-bold text-main-blue text-center'>
        Recuperar contraseña
      </h1>
      <Square>
        <form
          className='form-content'
          onSubmit={handleSubmit(recoverPassword)}
          encType='multipart/form-data'
        >
          <img
            src={images.logo_condes}
            className=''
            alt='logo'
            style={{ maxHeight: '70px' }}
          />
          <input
            {...register('email')}
            type='text'
            className='rounded-full border px-4 w-10/12'
            placeholder='Email'
          />
          {errors?.email && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.email?.message}
            </div>
          )}
          {messageNotification !== '' && (
            <div className='w-full flex items-center justify-center text-green-600'>
              {messageNotification}
            </div>
          )}
          <MainButton text='Recuperar' disabled={isLoading} />
        </form>

        {<Loader isOpen={isLoading} />}

        <div className='flex flex-row justify-between items-center text-blue-600 text-center w-10/12'>
          <Link to={'/acceder'}>
            <p>Acceder</p>
          </Link>
          <Link to={'/registro'}>
            <p>Registrarse</p>
          </Link>
        </div>
      </Square>
    </section>
  );
}

export default RecoverPassword;
