import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import frontRoutes from '../resources/constants/frontend.routes';
import usersApi from '../API/users/user.api';
import AdminOptions from '../components/admin/AdminOptions';
import MainButton from '../components/general/MainButton';

function AdminUsersList() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getUserList();
  }, [page]);

  const getUserList = async () => {
    const userList = await usersApi.getUserList(page);
    userList?.data?.success && setUserList(userList.data.data.data);
    userList?.data?.success &&
      setPageCount(userList.data.data.pagination.pageCount);
  };

  const handleSearchUsers = async ({ target }) => {
    const userList = await usersApi.searchUsers(target.value.toLowerCase());
    if (userList.data.success) {
      setUserList(userList.data.data.data);
      setPageCount(userList.data.data.pagination.pageCount);
    } else {
      getUserList();
    }
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const redirect = (id_user) => {
    navigate(`${frontRoutes.panelListaDeUsuariosEditarUsuario}/${id_user}`);
  };

  return (
    <div className='flex flex-col justify-center items-center space-y-8 p-6'>
      <AdminOptions />
      <div className='flex justify-center items-center w-full'>
        <input
          type='text'
          onChange={handleSearchUsers}
          className='border border-main-blue rounded-l-full py-2 px-2 w-10/12 md:w-6/12'
          placeholder='Buscar usuario...'
        />
        <MainButton
          text={'Buscar'}
          classes={'rounded-l-none border border-main-blue'}
        />
      </div>

      <div className='w-full py-2 flex justify-end'>
        <MainButton
          text={'Crear usuario'}
          classes={'border border-main-blue'}
          event={() => navigate(frontRoutes.panelAdminCrearNuevoUsuario)}
        />
      </div>

      <div className='w-full'>
        <div className='grid grid-cols-1 md:grid-cols-4 text-center w-full bg-main-blue text-white rounded-t-lg py-2'>
          <p className='p-2'>Administradores</p>
          <p className='p-2'>Redactores</p>
          <p className='p-2'>Investigadores</p>
          <p className='p-2'>Usuarios</p>
        </div>
        <div className='grid grid-rows-1 w-full border border-main-blue'>
          <div className='grid grid-cols-1 md:grid-cols-4 text-center text-link border-b border-main-blue'>
            <p className='border-x border-main-blue p-2'>Nombre</p>
            <p className='border-x border-main-blue p-2'>Apellido</p>
            <p className='border-x border-main-blue p-2'>Correo</p>
            <p className='border-x border-main-blue p-2'>Investigaciones</p>
          </div>
          {userList.length > 0 &&
            userList.map((user) => (
              <div
                key={user.id}
                className='grid grid-cols-1 md:grid-cols-4 text-center border-b border-main-blue'
              >
                <p
                  className='border-x border-main-blue p-2 cursor-pointer'
                  onClick={() => redirect(user.id)}
                >
                  {user.name}
                </p>
                <p className='border-x border-main-blue p-2'>
                  {user.last_name}
                </p>
                <p className='border-x border-main-blue p-2 break-words'>
                  {user.email}
                </p>
                <p className='border-x border-main-blue p-2 break-words'>
                  {user.investigations}
                </p>
              </div>
            ))}
        </div>
      </div>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
}

export default AdminUsersList;
