import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import { userRoles } from '../constanst/constants';
import * as programsApi from '../API/programs/programs.api';
import frontendRoutes from '../resources/constants/frontend.routes';
import { flagsToGetPrograms } from '../resources/constants';
import useAuth from '../security/auth/useAuth';
import Loader from '../components/general/Loader';
import handleStorage from '../services/localstorage/handelLocalStorage';
import ItemProgramAdmin from '../components/admin/ItemProgramAdmin';

const ProgramListByRole = () => {
  const navigate = useNavigate();

  const { isLogged } = useAuth();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [programsList, setProgramsList] = useState([]);
  const flatSearchProjects = 'por aprobar';

  const [isLoading, setIsLoading] = useState(false);
  const [modalAssignmentProject, setModalAssignmentProject] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    getPrograms();
  }, [page]);

  const getPrograms = async (flag = flagsToGetPrograms.toBeAssigned) => {
    setIsLoading(true);

    const user = await handleStorage.getItemLocalStorage('user_condes');
    const response =
      await programsApi.getProgramsByCommissionsRoleWithoutAssignment({
        id_user: user.id,
        role: user.role,
        commissionsRole: user.commissionsRole,
        skip: page,
        flag,
      });

    setIsLoading(false);
    setProgramsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  return (
    <>
      <div className='flex flex-col justify-center items-center space-y-8 p-6'>
        <h1 className='text-4xl font-bold text-main-blue'>Programas</h1>
        {backendMessage.message !== '' && (
          <p
            className={`text-center text-${
              backendMessage.type ? 'red' : 'green'
            }-600`}
          >
            {backendMessage.message}
          </p>
        )}

        {isLogged.role === userRoles.coordinator && (
          <div className='flex w-full items-start justify-around bg-main-blue rounded-lg text-white p-2 space-x-4'>
            <p
              className='cursor-pointer'
              onClick={() => getPrograms(flagsToGetPrograms.toBeAssigned)}
            >
              Programas
            </p>
            <p
              className='cursor-pointer'
              onClick={() => getPrograms(flagsToGetPrograms.assigned)}
            >
              Asignados
            </p>
          </div>
        )}

        {programsList.length > 0 &&
          programsList.map((program) => (
            <ItemProgramAdmin
              key={program?._id}
              programId={program._id}
              programType={program.type_project}
              programCode={program.program_code}
              programAssignment={() => {}}
              redirect={() =>
                navigate(`${frontendRoutes.program}/${program._id}`)
              }
              programAssignmentTo={program.assigned_to}
              title={program.title}
              author={program.name + ' ' + program.last_name}
              creationDate={program.createdAt}
              cover='https://i.ibb.co/LPWY38D/identificador-campana.jpg'
            />
          ))}

        <Loader isOpen={isLoading} />

        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={'m-8 flex items-center justify-center'}
          previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
          nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
          pageClassName={'border border-main-blue px-4 py-1'}
          breakClassName={'border border-main-blue px-4 py-1'}
          activeClassName={'text-indigo-600'}
        />
      </div>
    </>
  );
};

export default ProgramListByRole;
