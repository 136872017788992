import { Link } from 'react-router-dom';
function NavBarChildrenElement(props) {
  const saved = localStorage.getItem('user_condes');
  const user = JSON.parse(saved);

  if (!user && props.private) {
    return (
      <></>
    );
  }
  return (
    <Link to={'/' + props.link}>
      <p className="cursor-pointer">{props.text}</p>
    </Link>
  );



}

export default NavBarChildrenElement;
