import React from "react";
import images from "../../resources/images";

function AuthorityFrame({ name, position }) {
  return (
    <div className="border border-main-blue flex flex-col space-y-4 py-6 justify-start items-center">
      <img src={images.logo_luz} style={{ width: 75 }} alt="" />
      <h3> {name} </h3>
      <h3 className="text-center"> {position} </h3>
    </div>
  );
}

export default AuthorityFrame;
