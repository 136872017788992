import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import usersApi from '../API/users/user.api';
import frontRoutes from '../resources/constants/frontend.routes';
import userSchemas from '../security/schemas/user.schema';
import linesOfInvestigation from '../resources/js/linesOfInvestigation.json';
import countries from '../resources/js/countries.json';
import {
  commissionsRoles,
  userRoles,
  userRolesSelect,
} from '../constanst/constants';
import Square from '../components/general/Square';
import MainButton from '../components/general/MainButton';
import Loader from '../components/general/Loader';

const AdminCreateNewUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorBackend, setErrorBackend] = useState({
    message: '',
    type: false,
  });

  const {
    register,
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchemas.createUserSchemaFromAdmin),
  });
  const watchRole = watch('role', 'none');

  const createNewUser = async (data) => {
    setErrorBackend({ message: '', type: false });

    if (data.role === userRoles.user && !data.isCompany) {
      setError('isCompany', { type: 'custom', message: '¿Es empresa?' });
      return;
    }

    if (
      (data.role === userRoles.coordinator ||
        data.role === userRoles.evaluator) &&
      !data.expertise_area
    ) {
      setError('expertise_area', {
        type: 'custom',
        message: 'Área de experticia es requerida',
      });
      return;
    }

    if (
      (data.role === userRoles.coordinator ||
        data.role === userRoles.evaluator) &&
      data.line_research == 'none'
    ) {
      setError('line_research', {
        type: 'custom',
        message: 'Línea de investigación es requerida',
      });
      return;
    }

    if (
      (data.role === userRoles.coordinator ||
        data.role === userRoles.evaluator) &&
      !data.profession
    ) {
      setError('profession', {
        type: 'custom',
        message: 'Profesión es requerida',
      });
      return;
    }

    setIsLoading(true);

    const response = await usersApi.createUserFromAdmin(data);

    setIsLoading(false);

    if (!response.data.success) {
      setErrorBackend({ message: 'Error al registrar usuario', type: false });
      return;
    }

    setErrorBackend({ message: 'Usuario registrado exitosamente', type: true });
    reset();
  };

  return (
    <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
      <h1 className='text-4xl font-bold text-main-blue'>Crear usuario</h1>
      <Square>
        <div className='w-full flex items-center justify-end text-red-600'>
          <Link to={frontRoutes.panelListaDeUsuarios}>
            <p className='font-bold'>x</p>
          </Link>
        </div>

        <form
          className='form-content'
          onSubmit={handleSubmit(createNewUser)}
          autoComplete='off'
        >
          <select
            {...register('role')}
            defaultValue={'none'}
            className='rounded-full border px-4 w-10/12'
          >
            <option value='none' disabled>
              Rol
            </option>
            {Object.entries(userRolesSelect).map((role, i) => (
              <option key={i} value={role[0]} className='capitalize'>
                {role[1]}
              </option>
            ))}
          </select>
          {errors?.role && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.role?.message}
            </div>
          )}

          <select
            {...register('commissionsRole')}
            defaultValue={'none'}
            className='rounded-full border px-4 w-10/12'
          >
            <option value='none' disabled>
              Comisión
            </option>
            {Object.entries(commissionsRoles).map((commission, i) => (
              <option key={i} value={commission[0]} className='capitalize'>
                {commission[1]}
              </option>
            ))}
          </select>
          {errors?.commissionsRole && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.commissionsRole?.message}
            </div>
          )}

          <div className='w-10/12 flex justify-between items-center space-x-4'>
            <input
              {...register('name')}
              type='text'
              placeholder='Nombre'
              className='rounded-full border px-4 w-full md:w-1/2'
            />
            <input
              {...register('last_name')}
              type='text'
              placeholder='Apellido'
              className='rounded-full border px-4 w-full md:w-1/2'
            />
          </div>
          {errors?.name && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.name?.message}
            </div>
          )}
          {errors?.last_name && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.last_name?.message}
            </div>
          )}

          <select
            {...register('country')}
            className='rounded-full border px-2 w-10/12'
            defaultValue={'none'}
          >
            <option disabled className='text-gray-200' value={'none'}>
              Selecciona un pais
            </option>
            {Object.keys(countries).map((key, i) => (
              <option key={i} value={countries[key].name}>
                {countries[key].name}
              </option>
            ))}
          </select>
          {errors?.country && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.country?.message}
            </div>
          )}

          <input
            type='number'
            step={1}
            min={0}
            placeholder='Identificación'
            {...register('identification_number')}
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.identification_number && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.identification_number?.message}
            </div>
          )}

          {(watchRole === userRoles.coordinator ||
            watchRole === userRoles.evaluator) && (
            <>
              <input
                type='text'
                placeholder='Área de experticia'
                className='rounded-full border px-4 w-10/12'
                {...register('expertise_area')}
              />
              {errors?.expertise_area && (
                <div className='w-full flex items-center justify-center text-red-600'>
                  {errors?.expertise_area?.message}
                </div>
              )}

              <select
                {...register('line_research')}
                className='rounded-full border px-4 w-10/12'
                defaultValue={'none'}
              >
                <option disabled value={'none'}>
                  Linea de investigación
                </option>
                {linesOfInvestigation.map(
                  (element, index) =>
                    !element.hidden && (
                      <option key={index} value={element.nombre}>
                        {element.nombre}
                      </option>
                    )
                )}
              </select>
              {errors?.line_research && (
                <p className='text-center text-red-600 w-full'>
                  {errors.line_research.message}
                </p>
              )}

              <input
                type='text'
                placeholder='Profesión'
                className='rounded-full border px-4 w-10/12'
                {...register('profession')}
              />
              {errors?.profession && (
                <p className='text-center text-red-600 w-full'>
                  {errors.profession.message}
                </p>
              )}
            </>
          )}

          <input
            {...register('email')}
            type='email'
            placeholder='Email'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.email && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.email?.message}
            </div>
          )}

          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <input
              {...register('cod_number')}
              type='text'
              placeholder='+ Código'
              className='rounded-full border px-4 w-full md:w-1/3'
            />
            <input
              {...register('phone')}
              type='number'
              placeholder='Número de teléfono'
              className='rounded-full border px-4 w-full md:w-2/3'
            />
          </div>
          {errors?.cod_number && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.cod_number?.message}
            </div>
          )}
          {errors?.phone && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.phone?.message}
            </div>
          )}

          {watchRole === userRoles.user && (
            <>
              <p className='w-10/12 text-left'>Cuenta empresarial</p>
              <div className='flex w-10/12 justify-start items-center space-x-6'>
                <div className='flex items-center space-x-2'>
                  <p>Sí</p>
                  <input
                    type='radio'
                    value={'true'}
                    {...register('isCompany')}
                  />
                </div>
                <div className='flex items-center space-x-2'>
                  <p>No</p>
                  <input
                    type='radio'
                    value={'false'}
                    {...register('isCompany')}
                  />
                </div>
              </div>
              {errors?.isCompany && (
                <div className='w-full flex items-center justify-center text-red-600'>
                  {errors?.isCompany?.message}
                </div>
              )}
            </>
          )}

          {/* {messageNotification !== '' && (
            <div className='w-full flex items-center justify-center text-green-600'>
              {messageNotification}
            </div>
          )} */}
          <MainButton text='Crear' disabled={isLoading} />

          {errorBackend.message !== '' && (
            <div
              className={`w-full flex items-center justify-center text-${
                !errorBackend.type ? 'red' : 'green'
              }-600`}
            >
              {errorBackend.message}
            </div>
          )}
        </form>
        <Loader isOpen={isLoading} />
      </Square>
    </section>
  );
};

export default AdminCreateNewUser;
