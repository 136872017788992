import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MainButton from "../components/general/MainButton";
import Square from "../components/general/Square";
import countries from "../resources/js/countries.json";
import contactUsSchema from "../security/schemas/contactUs.schema";
import publicApi from "../API/public/public.api";

function ContactUs() {
  const json = countries;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  });

  const [messageNotification, setMessageNotification] = useState("");

  const sendContactUsMessage = async (data) => {
    const response = await publicApi.sendContactUsMessage(data);
    response.data.success && setMessageNotification("Envio exitoso.");
    !response.data.success && setMessageNotification(response.data.msg);
    response.data.success && reset();
  };

  return (
    <div className="bg-main-background flex flex-col items-center justify-center py-8">
      <h1 className="text-4xl font-bold text-main-blue">Contáctanos</h1>

      <Square>
        <form
          className="form-content"
          onSubmit={handleSubmit(sendContactUsMessage)}
          encType="multipart/form-data"
        >
          <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
            <input
              {...register("name")}
              type="text"
              placeholder="Nombre"
              className="rounded-full border px-4 w-full md:w-1/2"
            />
            <input
              {...register("last_name")}
              type="text"
              placeholder="Apellido"
              className="rounded-full border px-4 w-full md:w-1/2"
            />
          </div>
          <input
            {...register("email")}
            type="email"
            placeholder="Email"
            className="rounded-full border px-4 w-10/12"
          />
          <select
            className="rounded-full border px-4 w-10/12"
            {...register("country")}
          >
            <option disabled selected className='text-gray-200'>Selecciona un pais</option>
            {Object.keys(json).map((key, i) => (
              <option key={i} value={json[key].name}>
                {" "}
                {json[key].name}{" "}
              </option>
            ))}
          </select>
          <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
            <input
              {...register("cod_number")}
              type="text"
              placeholder="+ Código"
              className="rounded-full border px-4 w-full md:w-1/3"
            />
            <input
              {...register("phone")}
              type="number"
              placeholder="Número de teléfono"
              className="rounded-full border px-4 w-full md:w-2/3"
            />
          </div>
          <textarea
            {...register("messages")}
            className="rounded-md border px-4 w-10/12"
            placeholder="Escribe que curso te interesa o que dudas tienes..."
            rows="10"
          ></textarea>
          {errors?.name && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.name?.message}
            </div>
          )}
          {errors?.last_name && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.last_name?.message}
            </div>
          )}
          {errors?.email && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.email?.message}
            </div>
          )}
          {errors?.country && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.country?.message}
            </div>
          )}
          {errors?.cod_number && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.cod_number?.message}
            </div>
          )}
          {errors?.phone && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.phone?.message}
            </div>
          )}
          {errors?.messages && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.messages?.message}
            </div>
          )}
          {messageNotification !== "" && (
            <div className="w-full flex items-center justify-center text-green-600">
              {messageNotification}
            </div>
          )}
          <MainButton text="Enviar" />
        </form>
      </Square>
    </div>
  );
}

export default ContactUs;
