import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Paginate from 'react-paginate';
import courseAPI from '../API/course/course.api';
import AdminOptions from '../components/admin/AdminOptions';
import ElementListCourse from '../components/admin/ElementListCourse';
import MainButton from '../components/general/MainButton';

const AdminCoursesList = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [coursesList, setCoursesList] = useState([]);

  useEffect(() => {
    getAllCourses();
  }, []);
  useEffect(() => {
    getAllCourses();
  }, [page]);

  const getAllCourses = async () => {
    const res = await courseAPI.getEventsPagination(page);
    setCoursesList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleSearchCourses = async ({ target }) => {
    const { data } = await courseAPI.searchCourses(target.value.toLowerCase());
    if (data.success) {
      setCoursesList(data.data.data);
      setPageCount(data.data.pagination.pageCount);
    } else {
      getAllCourses();
    }
  };

  const handleDeleteCourse = async (id) => {
    const res = await courseAPI.deleteCourseById(id);
    if (res.data.success) {
      getAllCourses();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-8 p-6">
      <AdminOptions />
      <div className="flex justify-center items-center w-full">
        <input
          type="text"
          onChange={handleSearchCourses}
          className="border border-main-blue rounded-l-full py-2 px-2 w-10/12 md:w-6/12"
          placeholder="Buscar curso..."
        />
        <MainButton
          text={'Buscar'}
          classes={'rounded-l-none border border-main-blue'}
        />
      </div>
      <div className="w-full flex justify-end items-center">
        <Link to={'/panel/lista-de-cursos/agregar'}>
          <button className="px-4 py-2 bg-main-blue text-white rounded-full">
            Agregar curso
          </button>
        </Link>
      </div>
      {coursesList.map((course) => (
        <div
          key={course._id}
          className="grid grid-rows-1 w-full border border-main-blue rounded-lg"
        >
          <ElementListCourse
            idItem={course._id}
            cover={course.pathImage}
            title={course.titulo}
            author={course.profesor}
            data={course}
            creationDate={course.createdAt}
            releaseDate={course.createdAt}
            onDelete={handleDeleteCourse}
          />
        </div>
      ))}

      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
};

export default AdminCoursesList;
