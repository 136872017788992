import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validations from '../../security/schemas/formProjectsSchemas/registerProjects.schema';
import MainButton from '../general/MainButton';
import { uploadFilesToGoogleDrive } from '../../services/uploadFileGoogleDrive';

function ProyectComplement({ handleAdd }) {
  const facultades = [
    'Facultad de Agronomía',
    'Facultad de Arquitectura y diseño',
    'Facultad Experimental de Arte',
    'Facultad Experimental de Ciencias',
    'Nucleo Costa Oriental',
    'Facultad de Ciencias Economicas y Sociales',
    'Facultad de humanidades y educación',
    'Facultad de Ingeniería',
    'Facultad de Medicina',
    'Facultad de Odontología',
    'Facultad de ciencias Jurídicas y Políticas',
    'Nucleo Punto Fijo',
    'Facultad de Ciencias Veterinaria',
    'Vicerrectorado Académico',
    'Vicerrectorado Administrativo',
  ];
  const positions = [
    'Asesor',
    'Co-investigador',
    'Co-investigador auxiliar',
    'Asistente de investigación',
    'Asistente de investigación auxiliar',
    // 'TSU',
    // 'Técnico medio',
    'Colaborador',
    'Otros',
  ];

  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations.projectParticipantsSchema),
  });

  const watchInstitute = watch('institution', 'luz');

  const [isUploadingDocuments, setIsUploadingDocuments] = useState({
    curriculum_vitae_teaching_staff: {
      loading: false,
      msg: '',
      success: null,
    },
    letters_dedication_personnel_hours: {
      loading: false,
      msg: '',
      success: null,
    },
  });
  const [participantsDocuments, setParticipantsDocuments] = useState({});

  const addDocument = ({ target }) => {
    if (!target.files[0]) return null;

    setIsUploadingDocuments({
      ...isUploadingDocuments,
      [target.name]: {
        loading: true,
        msg: 'Cargando...',
        success: false,
      },
    });

    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      const rawLog = reader.result.split(',')[1];

      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      };

      try {
        const docUrl = await uploadFilesToGoogleDrive(dataSend);

        if (!docUrl?.url) {
          setIsUploadingDocuments((state) => ({
            ...isUploadingDocuments,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        const data = {
          name: target.name,
          file: docUrl,
        };

        setParticipantsDocuments({
          ...participantsDocuments,
          [target.name]: data,
        });

        setError(target.name, {
          type: 'custom',
          message: '',
        });

        setIsUploadingDocuments((state) => ({
          ...isUploadingDocuments,
          [target.name]: {
            loading: false,
            msg: `Archivo ${file.name} Guardado exitosamente.`,
            success: true,
          },
        }));
      } catch (error) {
        console.log('Error al subir archivo', error);
        return setIsUploadingDocuments((state) => ({
          ...isUploadingDocuments,
          [target.name]: {
            loading: false,
            msg: `Error al guardar documento ${file}, intente de nuevo.`,
            success: false,
          },
        }));
      }
    };
  };

  const checkSomeDocumentIsLoading = () =>
    Object.values(isUploadingDocuments).some((loading) => loading.loading);

  const addParticipantsProject = (data) => {
    // if (data.faculty_core === 'none')
    //   return setError('faculty_core', {
    //     type: 'custom',
    //     message: 'Introduzca una facultad/núcleo valido.',
    //   });

    if (data.category === 'none')
      return setError('category', {
        type: 'custom',
        message: 'Introduzca una categoría valida.',
      });

    if (data.position === 'none')
      return setError('position', {
        type: 'custom',
        message: 'Introduzca una posición valida.',
      });

    data.last_date_promotion = new Date(data.last_date_promotion).toISOString();

    if (
      data.institution !== 'luz' &&
      !participantsDocuments.curriculum_vitae_teaching_staff
    ) {
      return setError('curriculum_vitae_teaching_staff', {
        type: 'custom',
        message: 'Introduzca un curriculum vitae.',
      });
    }

    data.curriculum_vitae_teaching_staff =
      participantsDocuments.curriculum_vitae_teaching_staff;

    if (!participantsDocuments.letters_dedication_personnel_hours) {
      return setError('letters_dedication_personnel_hours', {
        type: 'custom',
        message: 'Introduzca carta horas de dedicación del personal.',
      });
    }

    data.letters_dedication_personnel_hours =
      participantsDocuments.letters_dedication_personnel_hours;

    handleAdd(data);
    setIsUploadingDocuments({
      curriculum_vitae_teaching_staff: {
        loading: false,
        msg: '',
        success: null,
      },
      letters_dedication_personnel_hours: {
        loading: false,
        msg: '',
        success: null,
      },
    });
    setParticipantsDocuments({});
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(addParticipantsProject)}
        className='w-full flex flex-col items-center justify-center space-y-8'
        autoComplete='off'
      >
        <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
          <input
            {...register('name')}
            type='text'
            placeholder='Nombre'
            className='rounded-full border px-4 w-full md:w-1/2'
          />
          <input
            {...register('last_name')}
            type='text'
            placeholder='Apellido'
            className='rounded-full border px-4 w-full md:w-1/2'
          />
        </div>
        {errors?.name && (
          <p className='text-center text-red-600 w-full'>
            {errors.name.message}
          </p>
        )}
        {errors?.last_name && (
          <p className='text-center text-red-600 w-full'>
            {errors.last_name.message}
          </p>
        )}

        <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
          <select
            {...register('type_identification')}
            className='rounded-full border px-4 w-full md:w-2/12'
            name=''
            id=''
          >
            <option value='V'>V</option>
            <option value='E'>E</option>
            <option value='J'>J</option>
          </select>

          <input
            {...register('identification')}
            type='number'
            placeholder='cedula de identidad'
            className='rounded-full border px-4 w-full md:w-10/12'
          />
        </div>
        {errors?.type_identification && (
          <p className='text-center text-red-600 w-full'>
            {errors.type_identification.message}
          </p>
        )}
        {errors?.identification && (
          <p className='text-center text-red-600 w-full'>
            {errors.identification.message}
          </p>
        )}

        <input
          {...register('profession')}
          type='text'
          placeholder='Profesión'
          className='rounded-full border px-4 w-10/12'
        />
        {errors?.profession && (
          <p className='text-center text-red-600 w-full'>
            {errors.profession.message}
          </p>
        )}

        <input
          {...register('specialty')}
          type='text'
          placeholder='Especialidad'
          className='rounded-full border px-4 w-10/12'
        />
        {errors?.specialty && (
          <p className='text-center text-red-600 w-full'>
            {errors.specialty.message}
          </p>
        )}

        <select
          className='rounded-full border px-4 w-10/12'
          defaultValue={'luz'}
          {...register('institution')}
        >
          <option value='luz' selected>
            LUZ
          </option>
          <option value='national'>Nacional</option>
          <option value='international'>Internacional</option>
        </select>
        {errors?.institution && (
          <p className='text-center text-red-600 w-full'>
            {errors.institution.message}
          </p>
        )}
        {/* <input
        {...register('institution')}
        type='text'
        placeholder='Institución'
        className='rounded-full border px-4 w-10/12'
      /> */}

        {watchInstitute === 'luz' ? (
          <>
            <select
              {...register('faculty_core')}
              className='rounded-full border px-4 w-10/12'
            >
              <option disabled selected value=''>
                Facultad/Núcleo
              </option>
              {facultades.map((facultad, key) => {
                return (
                  <option key={key} value={facultad}>
                    {facultad}
                  </option>
                );
              })}
            </select>
            {errors?.faculty_core && (
              <p className='text-center text-red-600 w-full'>
                {errors.faculty_core.message}
              </p>
            )}
          </>
        ) : (
          <>
            <input
              {...register('institution_name', {
                required: false,
                pattern: /^[a-zA-Zá-üÁ-Ü ]+$/,
              })}
              type='text'
              placeholder='Nombre instituto'
              className='rounded-full border px-4 w-10/12'
            />
            <div className='w-10/12 space-y-2 flex-col flex justify-center items-center'>
              <p>
                Curriculum Vitae del personal docente y de investigación
                adscrito al proyecto (Solo para aquellos que no sean miembros
                del LUZ)
              </p>
              <div className='w-full'>
                <input
                  className='w-full'
                  accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                  type='file'
                  name='curriculum_vitae_teaching_staff'
                  onChange={addDocument}
                  disabled={checkSomeDocumentIsLoading()}
                />
                {isUploadingDocuments.curriculum_vitae_teaching_staff
                  .success !== null && (
                  <p className='text-green-600'>
                    {isUploadingDocuments.curriculum_vitae_teaching_staff.msg}
                  </p>
                )}

                {errors.curriculum_vitae_teaching_staff && (
                  <p className='text-center text-red-600 w-full'>
                    {errors.curriculum_vitae_teaching_staff.message}
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        <select
          {...register('category')}
          className='rounded-full border px-4 w-10/12'
        >
          <option disabled selected value='none'>
            Categoria
          </option>
          <option value='agregado'>Agregado</option>
          <option value='asistente'>Asistente</option>
          <option value='asociado'>Asociado</option>
          <option value='titular'>Titular</option>
        </select>
        {errors?.category && (
          <p className='text-center text-red-600 w-full'>
            {errors.category.message}
          </p>
        )}

        <input
          {...register('research_entity')}
          type='text'
          placeholder='Ente de investigación'
          className='rounded-full border px-4 w-10/12'
        />
        {errors?.research_entity && (
          <p className='text-center text-red-600 w-full'>
            {errors.research_entity.message}
          </p>
        )}

        <div className='flex flex-col space-y-4 w-10/12'>
          <p className='px-1'>Última fecha de ascenso</p>
          <input
            {...register('last_date_promotion')}
            type='date'
            placeholder='Fecha de ascenso'
            className='rounded-full border px-4 w-full'
          />
        </div>
        {errors?.last_date_promotion && (
          <p className='text-center text-red-600 w-full'>
            {errors.last_date_promotion.message}
          </p>
        )}

        <input
          {...register('email')}
          type='email'
          placeholder='Email'
          className='rounded-full border px-4 w-10/12'
        />
        {errors?.email && (
          <p className='text-center text-red-600 w-full'>
            {errors.email.message}
          </p>
        )}

        <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
          <input
            {...register('cod_number')}
            type='text'
            placeholder='+ Código'
            className='rounded-full border px-4 w-full md:w-1/3'
          />
          <input
            {...register('phone')}
            type='number'
            placeholder='Número de teléfono'
            className='rounded-full border px-4 w-full md:w-2/3'
          />
        </div>
        {errors?.cod_number && (
          <p className='text-center text-red-600 w-full'>
            {errors.cod_number.message}
          </p>
        )}
        {errors?.phone && (
          <p className='text-center text-red-600 w-full'>
            {errors.phone.message}
          </p>
        )}

        <select
          {...register('position')}
          className='rounded-full border px-4 w-10/12'
        >
          <option selected disabled value='none'>
            Cargo
          </option>
          {positions.map((position, key) => (
            <option key={key} value={position}>
              {position}
            </option>
          ))}
        </select>
        {errors?.position && (
          <p className='text-center text-red-600 w-full'>
            {errors.position.message}
          </p>
        )}

        <div className='w-10/12 space-y-2 flex-col flex justify-center items-center'>
          <p>
            Cartas que indiquen las horas de dedicación del personal docente y
            de investigación del proyecto, incluyendo becarios académicos,
            auxiliares docentes y PIN, firmada por el/la investigador(a) y por
            el/la jefe de la unidad docente de la adscripción
          </p>
          <div className='w-full'>
            <input
              className='w-full'
              accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
              type='file'
              name='letters_dedication_personnel_hours'
              onChange={addDocument}
              disabled={checkSomeDocumentIsLoading()}
            />
            {isUploadingDocuments.letters_dedication_personnel_hours.success !==
              null && (
              <p className='text-green-600'>
                {isUploadingDocuments.letters_dedication_personnel_hours.msg}
              </p>
            )}
            {errors.letters_dedication_personnel_hours && (
              <p className='text-center text-red-600 w-full'>
                {errors.letters_dedication_personnel_hours.message}
              </p>
            )}
          </div>
        </div>

        <div className=''>
          <MainButton text='Agregar' disabled={checkSomeDocumentIsLoading()} />
        </div>
        <div className='border border-gray-600/25 w-10/12' />
      </form>
    </>
  );
}

export default ProyectComplement;
