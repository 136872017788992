import React, { useState } from "react";
import images from "../resources/images";
import MidTittle from "../components/general/MidTittle";
import AuthorityFrame from "../components/general/AuthorityFrame";
import AuthorityFrameAlt from "../components/general/AuthorityFrameAlt";
import "animate.css";
import agronomia from "../resources/js/delegados/agronomia.json";
import arquitectura from "../resources/js/delegados/arquitectura.json";
import arte from "../resources/js/delegados/arte.json";
import ciencias from "../resources/js/delegados/ciencias.json";
import costaOriental from "../resources/js/delegados/costaOriental.json";
import economicas from "../resources/js/delegados/economicas.json";
import humanidades from "../resources/js/delegados/humanidades.json";
import ingenieria from "../resources/js/delegados/ingenieria.json";
import medicina from "../resources/js/delegados/medicina.json";
import odontologia from "../resources/js/delegados/odontologia.json";
import politicas from "../resources/js/delegados/politicas.json";
import puntoFijo from "../resources/js/delegados/puntoFijo.json";
import veterinaria from "../resources/js/delegados/veterinaria.json";

function Authorities() {
  const [selected, setSelected] = useState("");
  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };
  const faculties = [
    "Facultad de Agronomía",
    "Facultad de Arquitectura y Diseño",
    "Facultad de Ciencias Económicas y Sociales",
    "Facultad de Ciencias Jurídicas y Políticas",
    "Facultad de Ciencias Veterinarias",
    "Facultad de Humanidades y Educación",
    "Facultad de Ingeniería",
    "Facultad de Medicina",
    "Facultad de Odontología",
    "Facultad Experimental de Arte",
    "Facultad Experimental de Ciencias",
    "Núcleo Punto Fijo",
    "Núcleo Costa Oriental de Lago"
  ];

  let type = agronomia;

  if (selected === "Facultad de Agronomía") {
    type = agronomia;
  } else if (selected === "Facultad de Arquitectura y Diseño") {
    type = arquitectura;
  } else if (selected === "Facultad de Ciencias Económicas y Sociales") {
    type = economicas;
  } else if (selected === "Facultad de Ciencias Jurídicas y Políticas") {
    type = politicas;
  } else if (selected === "Facultad de Ciencias Veterinarias") {
    type = veterinaria;
  } else if (selected === "Facultad de Humanidades y Educación") {
    type = humanidades;
  } else if (selected === "Facultad de Ingeniería") {
    type = ingenieria;
  } else if (selected === "Facultad de Medicina") {
    type = medicina;
  } else if (selected === "Facultad de Odontología") {
    type = odontologia;
  } else if (selected === "Facultad Experimental de Arte") {
    type = arte;
  } else if (selected === "Facultad Experimental de Ciencias") {
    type = ciencias;
  } else if (selected === "Núcleo Punto Fijo") {
    type = puntoFijo;
  } else if (selected === "Núcleo Costa Oriental de Lago") {
    type = costaOriental;
  }

  return (
    <>
      <section className="bg-pattern2 object-cover bg-repeat-x h-50%-vh min-h-600 grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-center items-center flex-col space-y-6 md:items-start md:pl-20 flex-col">
          <h1 className="text-main-blue text-4xl font-bold py-8 animate__animated animate__fadeInDown">
            AUTORIDADES
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center p-8 animate__animated animate__fadeInDown">
          <img
            className="min-h-90 max-h-250 rounded"
            src={images.logo_luz}
            alt=""
          />
          <img
            className="min-h-90 max-h-250 rounded"
            src={images.logo_condes}
            alt=""
          />
        </div>
      </section>
      <section className="my-8">
        <MidTittle
          title={"Autoridades universitarias"}
          classes={"text-2xl md:text-4xl"}
        />
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2 p-4">
          <AuthorityFrame name={"Dra. Judith Aular de Durán"} position={"Rectora"} />
          <AuthorityFrame
            name={"Dr. Clotilde Navarro"}
            position={
              "Vicerrector Administrativo Encargado Del Vicerrectorado Académico"
            }
          />
          <AuthorityFrame
            name={"Dra. Marlene Primera"}
            position={
              "Secretaria De LUZ Encargada Del Vicerrectorado Administrativo"
            }
          />
          <AuthorityFrame
            name={"Dra. Ixora Gómez"}
            position={"Secretaria Encargada"}
          />
          <AuthorityFrame name={"Dra. Luz Maritza Reyes"} position={"Coordinadora CONDES"} />
        </div>
      </section>
      <section className="my-8">
        <MidTittle title={"Delegados"} classes={"text-2xl md:text-4xl"} />
        <div className="px-8">
          <select onChange={changeSelectOptionHandler} name="" id="">
            {Object.keys(faculties).map((key, i) => (
              <option key={i} value={faculties[key].name}>
                {faculties[key]}
              </option>
            ))}
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2 p-4">
          {Object.keys(type).map((key, i) => (
            <AuthorityFrameAlt
              name={type[key].name}
              position={type[key].charge}
              key={i}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default Authorities;
