import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './resources/css/index.css';
import frontRouter from './resources/constants/frontend.routes';
// validate routes
import PrivateRoute from './security/auth/PrivateRoute';
import PublicRoute from './security/auth/PublicRoute';
import PrivateAdminRoute from './security/auth/PrivateAdminRoute';
import './resources/css/index.css';
import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home';
import Login from './pages/Login';
import Footer from './components/Footer';
import Register from './pages/Register';
import ContactUs from './pages/ContactUs';
import Courses from './pages/Courses';
import Course from './pages/Course';
import Credentials from './pages/Credentials';
import AboutUs from './pages/AboutUs';
import AdminUsersList from './pages/AdminUsersList';
import AdminCoursesList from './pages/AdminCourseList';
import News from './pages/News';
import Article from './pages/Article';
import Congress from './pages/Congress';
import RecoverPassword from './pages/RecoverPassword';
import ScrollToTop from './resources/js/ScrollToTop';
import Events from './pages/Events';
import Authorities from './pages/Authorities';
import LinesOfResearch from './pages/LinesOfResearch';
import RulesAndRegulations from './pages/RulesAndRegulations';
import NotFound from './pages/NotFound';
import Consultancies from './pages/Consultancies';
import AdminEventsList from './pages/AdminEventsList';
import AdminNewsList from './pages/AdminNewsList';
import AdminPanel from './pages/AdminPanel';
import EditNews from './pages/EditNews';
import EditCourses from './pages/EditCourses';
import EditEvents from './pages/EditEvents';
import AdminCreateNewUser from './pages/AdminCreateNewUser';
import EditUsersList from './pages/EditUsersList';
import AddNews from './pages/AddNews';
import AddCourse from './pages/AddCourse';
import AddEvent from './pages/AddEvent';
import Agreement from './pages/Agreement';
import MyAccount from './pages/MyAccount';
import ArticleEvent from './pages/ArticleEvent';
import PrivateLayout from './components/layouts/PrivateLayout';
// * projects
import RegisterProyects from './pages/RegisterProyects';
import AdminProyectsList from './pages/AdminProyectsList';
import Proyect from './pages/Proyect';
import MyProjects from './pages/MyProjects';
import ProjectsListByRole from './pages/ProjectsListByRole';
import AdminPanelSettings from './pages/AdminPanelSettings';
// * programs
import RegisterPrograms from './pages/RegisterPrograms';
import Program from './pages/Program';
import AdminProgramsList from './pages/AdminProgramsList';
import ProgramListByRole from './pages/ProgramListByRole';
import MyPrograms from './pages/MyPrograms';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavBar />

      <Routes>
        {/* Rutas publicas */}
        <Route element={<PublicRoute />}>
          <Route path={frontRouter.home} element={<Home />} />
          <Route path={frontRouter.login} element={<Login />} />
          <Route path={frontRouter.register} element={<Register />} />
          <Route path={frontRouter.contactanos} element={<ContactUs />} />
          <Route
            path={frontRouter.recuperarContrasena}
            element={<RecoverPassword />}
          />
          <Route path={frontRouter.cursos} element={<Courses />} />
          <Route path={`${frontRouter.curso}/:id`} element={<Course />} />
          <Route
            path={frontRouter.consignacionDeCredenciales}
            element={<Credentials />}
          />
          <Route path={frontRouter.quienesSomos} element={<AboutUs />} />
          <Route path={frontRouter.eventos} element={<Events />} />
          <Route
            path={`${frontRouter.eventosEvento}/:id`}
            element={<ArticleEvent />}
          />
          <Route path={frontRouter.noticias} element={<News />} />
          <Route
            path={`${frontRouter.noticiasNoticia}/:id`}
            element={<Article />}
          />
          <Route path={frontRouter.autoridades} element={<Authorities />} />
          <Route
            path={frontRouter.lineasDeInvestigacion}
            element={<LinesOfResearch />}
          />
          <Route
            path={frontRouter.normasYReglamentos}
            element={<RulesAndRegulations />}
          />
          <Route path={frontRouter.congreso} element={<Congress />} />
          <Route path={frontRouter.asesorias} element={<Consultancies />} />
          <Route path={frontRouter.convenios} element={<Agreement />} />
          <Route path={frontRouter.rest} element={<NotFound />} />
        </Route>

        {/* Rutas a trabajar en el futuro */}
        <Route
          path={frontRouter.registrarProyectos}
          element={<RegisterProyects />}
        />

        <Route path={`${frontRouter.program}/:id`} element={<Program />} />

        <Route path={`${frontRouter.Proyecto}/:id`} element={<Proyect />} />

        {/* Rutas privadas */}
        <Route element={<PrivateLayout />}>
          <Route element={<PrivateRoute />}>
            <Route path={frontRouter.miCuenta} element={<MyAccount />} />
            <Route
              path={frontRouter.programsByRole}
              element={<ProgramListByRole />}
            />
            <Route path={frontRouter.misProyectos} element={<MyProjects />} />
            <Route path={frontRouter.misProgramas} element={<MyPrograms />} />
            <Route
              path={frontRouter.proyectosByRole}
              element={<ProjectsListByRole />}
            />
            <Route
              path={frontRouter.registrarProgramas}
              element={<RegisterPrograms />}
            />
          </Route>

          <Route element={<PrivateAdminRoute />}>
            <Route path={frontRouter.panel} element={<AdminPanel />} />
            <Route
              path={frontRouter.panelListaDeUsuarios}
              element={<AdminUsersList />}
            />
            <Route
              path={frontRouter.panelAdminCrearNuevoUsuario}
              element={<AdminCreateNewUser />}
            />
            <Route
              path={`${frontRouter.panelListaDeUsuariosEditarUsuario}/:id`}
              element={<EditUsersList />}
            />
            <Route
              path={frontRouter.panelListaDeProyectos}
              element={<AdminProyectsList />}
            />
            <Route
              path={frontRouter.panelListaDeProyectosEditarProyecto}
              element={<AdminEventsList />}
            />
            <Route
              path={frontRouter.panelListaDeEventos}
              element={<AdminEventsList />}
            />
            <Route
              path={frontRouter.panelListaDeEventosEditarEvento}
              element={<EditEvents />}
            />
            <Route
              path={frontRouter.panelListaDeEventosAgregar}
              element={<AddEvent />}
            />
            <Route
              path={frontRouter.panelListaDeCursos}
              element={<AdminCoursesList />}
            />
            <Route
              path={frontRouter.panelListaDeCursosEditarCurso}
              element={<EditCourses />}
            />
            <Route
              path={frontRouter.panelListaDeCursosAgregar}
              element={<AddCourse />}
            />
            <Route
              path={frontRouter.panelListaDeNoticias}
              element={<AdminNewsList />}
            />
            <Route
              path={frontRouter.panelListaDeNoticiasEditar}
              element={<EditNews />}
            />
            <Route
              path={frontRouter.panelListaDeNoticiasAgregar}
              element={<AddNews />}
            />
            <Route path={frontRouter.panelAdminDocumentsProjects} element={<AdminPanelSettings />} />

            {/* Programs */}
            <Route
              path={frontRouter.panelListPrograms}
              element={<AdminProgramsList />}
            />
          </Route>
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
