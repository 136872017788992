import * as yup from 'yup';
import { commissionsRoles, userRolesSelect } from '../../constanst/constants';
import countries from '../../resources/js/countries.json';

const userSchemaFirstPart = yup.object().shape({
  email: yup
    .string('Correo debe ser textp')
    .email('Ingrese un correo corecto')
    .required('Correo electrónico es requerido'),
  password: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Contraseña es requerida'),
  password_two: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Por favor repita su contraseña')
    .oneOf([yup.ref('password')], 'Contraseñas no coinciden'),
});

const userSchemaSecondPart = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un nombre valido')
    .required('Nombre es requerido'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un apellido valido')
    .required('Apellido es requerido'),
  country: yup.string().required('Seleccione un pais'),
  isCompany: yup
    .boolean('Debe indicar si es una empresa')
    .typeError('Debe indicar si es una empresa'),
});

const userLoginSchema = yup.object().shape({
  email: yup
    .string('Correo debe ser textp')
    .email('Ingrese un correo corecto')
    .required('Correo electrónico es requerido'),
  password: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres para una contraseña valida.')
    .required('Contraseña es requerida'),
});

const userDataSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un formato valido')
    .required('Nombre es requerido'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un apellido valido')
    .required('Apellido es requerido'),
  email: yup
    .string('Correo debe ser textp')
    .email('Ingrese un correo corecto')
    .required('Correo electrónico es requerido'),
  password: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Contraseña es requerida'),
  password_two: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Por favor repita su contraseña')
    .oneOf([yup.ref('password')], 'Contraseñas no coinciden'),
  // country: yup.string().required('Seleccione un pais'),
  isCompany: yup
    .boolean('Debe indicar si es una empresa')
    .typeError('Indique si es una compañia.'),
  // cod_number: yup.string(),
  // phone: yup.number().typeError('Teléfono debe ser númerico'),
});

const createUserSchemaFromAdmin = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un nombre valido')
    .required('Nombre es requerido'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un apellido valido')
    .required('Apellido es requerido'),
  email: yup
    .string('Correo debe ser texto')
    .email('Ingrese un correo valido')
    .required('Correo electrónico es requerido'),
  country: yup
    .string()
    .not(['none'], 'Debe escoger un pais')
    .required('Pais es requerido'),
  identification_number: yup
    .number()
    .integer()
    .required('Identificación es requerida')
    .typeError('Identificación es requerida'),
  role: yup
    .string()
    .equals(Object.keys(userRolesSelect), 'Debe escoger un rol para el usuario')
    .required('Role del usuario es requerido'),
  commissionsRole: yup
    .string()
    .equals(
      Object.keys(commissionsRoles),
      'Debe escoger a que comisión pertenecerá el usuario.'
    )
    .required('Comisión del usuario es requerido'),
});

const editUserSchemaFromAdmin = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un nombre valido')
    .required('Nombre es requerido'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un apellido valido')
    .required('Apellido es requerido'),
  email: yup
    .string('Correo debe ser texto')
    .email('Ingrese un correo valido')
    .required('Correo electrónico es requerido'),
  country: yup.string().required('Pais es requerido'),
  role: yup
    .string()
    .equals(Object.keys(userRolesSelect), 'Debe escoger un rol para el usuario')
    .required('Role del usuario es requerido'),
});

// update data user
const nameAndLastNameSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un nombre valido')
    .required('Nombre es requerido'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Debe ingresar un apellido valido')
    .required('Apellido es requerido'),
});

const emailSchema = yup.object().shape({
  email: yup
    .string('Correo debe ser texto')
    .email('Ingrese un correo valido')
    .required('Correo electrónico es requerido'),
});

const passwordsSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Contraseña es requerida'),
  password_two: yup
    .string()
    .min(8, 'Se requieren, al menos, 8 caracteres')
    .required('Por favor repita su contraseña')
    .oneOf([yup.ref('password')], 'Contraseñas no coinciden'),
});

const userSchemas = {
  userSchemaFirstPart: userSchemaFirstPart,
  userSchemaSecondPart: userSchemaSecondPart,
  userSchemaLogin: userLoginSchema,
  userDataSchema,
  createUserSchemaFromAdmin,
  editUserSchemaFromAmin: editUserSchemaFromAdmin,
  // update data user
  nameAndLastNameSchema,
  emailSchema,
  passwordsSchema,
};

export default userSchemas;
