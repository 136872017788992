import React from 'react';
import MidTittle from '../components/general/MidTittle';
import images from '../resources/images';
import 'animate.css';

function RulesAndRegulations() {
  return (
    <>
      <section className='bg-rules bg-no-repeat bg-bottom lg:bg-right bg-cover h-50%-vh min-h-600 grid grid-cols-1 lg:grid-cols-2'>
        <div className='flex justify-center px-4 items-center flex-col space-y-6 md:items-start md:pl-20'>
          <h1 className='text-main-blue text-center text-4xl font-bold animate__animated animate__fadeInDown'>
            Normas y reglamentos
          </h1>
        </div>
        <div className='hidden'></div>
      </section>
      <section className='my-8'>
        <MidTittle title={'Descargas'} />
        <div className='flex justify-center items-center'>
          <div className='border border-main-blue rounded p-8 flex justify-center items-center space-x-4'>
            <div className='flex flex-col justify-center items-center space-y-4'>
              <img src={images.folder} style={{ width: 65 }} alt='' />
              <a
                target={'_blank'}
                rel='noreferrer'
                href='https://www.mediafire.com/file/7pzhfn2baef3blg/normasgenerales.pdf/file'
              >
                <p className='text-link'>Normas.pdf</p>
              </a>
            </div>
            <div className='flex flex-col justify-center items-center space-y-4'>
              <img src={images.folder} style={{ width: 65 }} alt='' />
              <a
                target={'_blank'}
                rel='noreferrer'
                href='https://www.mediafire.com/file/6d7zushxiw22amp/reglamentodeinvestigacion2009.pdf/file'
              >
                <p className='text-link'>Reglamentos.pdf</p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RulesAndRegulations;
