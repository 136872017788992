import { useEffect, useState } from 'react';
import Paginate from 'react-paginate';
import projectsApi from '../API/projects/projects.api';
import Square from '../components/general/Square';
import ProyectListElement from '../components/ProyectListElement';
import routes from '../resources/constants/frontend.routes';
import handleStorage from '../services/localstorage/handelLocalStorage';

function MyProjects() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [projectsList, setProjectsList] = useState([]);

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    getAllProjects();
  }, [page]);

  const getAllProjects = async () => {
    const id_user = handleStorage.getIdUser();
    const res = await projectsApi.getProjectsByIdUser(id_user, page);
    setProjectsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  return (
    <>
      <div className='bg-main-background flex flex-col items-center justify-center py-8'>
        <h1 className='text-4xl font-bold text-main-blue'>Mis proyetos</h1>
        <Square>
          {projectsList.map((project) => (
            <ProyectListElement
              key={project._id}
              title={project.title}
              code={project.project_code}
              status={project.status_project}
              link={`${routes.Proyecto}/${project._id}`}
            />
          ))}
        </Square>
      </div>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </>
  );
}

export default MyProjects;
