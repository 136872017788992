import React, { useState } from 'react';
import RegisterFirstStep from '../components/forms/RegisterFirstStep';
import RegisterSecondStep from '../components/forms/RegisterSecondStep';

function Register() {
  const [hidden, setHidden] = useState('');
  const [userIdRegister, setUserIdRegister] = useState(
    '62167ec908ef37a437c59fc6'
  );

  return (
    <section className="bg-main-background py-8 flex flex-col items-center justify-center w-full">
      <h1 className="text-4xl font-bold text-main-blue">Registro</h1>
      <RegisterFirstStep
        hidden={hidden}
        handleHidden={() => setHidden('hidden')}
        setUserId={setUserIdRegister}
      />
      <RegisterSecondStep hidden={hidden} userID={userIdRegister} />
    </section>
  );
}

export default Register;
