import React from 'react';
import useAuth from '../../security/auth/useAuth';
import NavBarChildElement from './NavBarChildElement';
function NavBarChildren({ texts, reference, classes, secClasses }) {
  const { handleLogged } = useAuth();

  const handle = (e) => {
    if (e.target.innerHTML.toLowerCase() === 'salir') {
      handleLogged(false);
    }
  };

  return (
    <div
      onClick={handle}
      className={
        classes ? classes + ' md:absolute hidden' : 'md:absolute hidden'
      }
      ref={reference}
    >
      <div
        className={
          secClasses
            ? secClasses +
              ' bg-main-blue relative text-xs md:text-base flex flex-col space-y-2 md:p-4'
            : 'bg-main-blue relative w-full text-xs md:text-base flex flex-col space-y-2 md:p-4'
        }
      >
        {texts.map((text, index) => (
          <NavBarChildElement
            key={index}
            text={text.titulo}
            link={text.link}
            private={text.private}
          />
        ))}
      </div>
    </div>
  );
}

export default NavBarChildren;
