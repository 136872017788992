import React from 'react';

function news({ img, title, handleClick }) {
  return (
    <div
      className="flex flex-col items-center justify-center space-y-4"
      onClick={handleClick}
    >
      <img
        src={img}
        alt=""
        className="rounded-lg drop-shadow-md border-2 border-solid border-main-blue cursor-pointer object-cover"
        style={{ width: '300px', height: '200px' }}
      />
      <p className="font-semibold text-black cursor-pointer">{title}</p>
    </div>
  );
}

export default news;
