export const flagsToGetProjects = {
  assigned: 'asignados',
  toBeAssigned: 'por asignar',
};

export const flagsToGetPrograms = {
  assigned: 'asignados',
  toBeAssigned: 'por asignar',
};

export const typesProjectsKeys = {
  'no financiado': 'no financiado',
  financiado: 'financiado',
  asociado: 'asociado',
};

export const numberOfProjectsAllowed = 3;
