import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { userRoles } from '../constanst/constants';
import usersApi from '../API/users/user.api';
import handleStorage from '../services/localstorage/handelLocalStorage';
import frontRoute from '../resources/constants/frontend.routes';
import userSchemas from '../security/schemas/user.schema';
import images from '../resources/images';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import Loader from '../components/general/Loader';

function Login() {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchemas.userSchemaLogin),
  });

  const [errorBackend, setErrorBackend] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [seePassword, setSeePassword] = useState(false);

  const handleLogin = async (data) => {
    setIsLoading(true);

    const res = await usersApi.login(data);

    setIsLoading(false);

    if (res?.data?.success) {
      handleStorage.setItemLocalStorage('user_condes', res.data.data);
      if (
        [userRoles.user, userRoles.coordinator, userRoles.evaluator].includes(
          res.data.data.role
        )
      ) {
        navigate(frontRoute.miCuenta);
        return;
      }

      navigate(frontRoute.panel);
    } else {
      return setErrorBackend(res.data.msg);
    }
  };

  return (
    <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
      <h1 className='text-4xl font-bold text-main-blue'>Acceso</h1>
      <Square classes={'mt-12 mb-3'}>
        <img
          src={images.logo_condes}
          className=''
          alt='logo'
          style={{ maxHeight: '70px' }}
        />
        <div className='flex flex-col space-y-1'>
          {errorBackend !== '' && (
            <p className='text-center text-red-600 w-full'>{errorBackend}</p>
          )}
          {errors?.email && (
            <p className='text-center text-red-600 w-full'>
              {errors.email.message}
            </p>
          )}
          {errors?.password && (
            <p className='text-center text-red-600 w-full'>
              {errors.password.message}
            </p>
          )}
        </div>
        <form onSubmit={handleSubmit(handleLogin)} className='form-content'>
          <input
            type='text'
            className='rounded-full border px-4 w-10/12'
            placeholder='Email'
            {...register('email')}
          />
          <div className='w-10/12 flex flex-col md:flex-row items-start space-x-2 relative'>
            <input
              {...register('password')}
              type={seePassword ? 'text' : 'password'}
              placeholder='Contraseña'
              className='w-full rounded-full px-4 pr-12 border border-blue-600'
            />
            <div className='h-7 flex justify-center items-center absolute right-2'>
              {seePassword ? (
                <IoIosEyeOff
                  onClick={() => setSeePassword(!seePassword)}
                  className='w-8 h-8 text-main-blue cursor-pointer'
                />
              ) : (
                <IoIosEye
                  onClick={() => setSeePassword(!seePassword)}
                  className='w-8 h-8 text-main-blue cursor-pointer'
                />
              )}
            </div>
          </div>
          {/* <input
            type='password'
            className='rounded-full border px-4 w-10/12'
            placeholder='Contraseña'
            {...register('password')}
          /> */}
          <MainButton type='submit' text='Acceder' disabled={isLoading} />
        </form>
        <div className='flex flex-row justify-between items-center text-blue-600 text-center w-10/12'>
          <Link to={'/recuperar-contrasena'}>
            <p>¿Olvidaste tu contraseña</p>
          </Link>
          <Link to={'/registro'}>
            <p>¿No tienes cuenta?</p>
          </Link>
        </div>
      </Square>

      <Loader isOpen={isLoading} />
    </section>
  );
}

export default Login;
