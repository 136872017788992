import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import frontendRoutes from '../resources/constants/frontend.routes';
import courseAPI from '../API/course/course.api';
import ContentGridCourse from '../components/general/ContentGridCourse';
import SliderCourses from '../sections/courses/SliderCourses';

const Courses = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [coursesList, setCoursesList] = useState([]);

  useEffect(() => {
    getAllCourses();
  }, []);
  useEffect(() => {
    getAllCourses();
  }, [page]);

  const getAllCourses = async () => {
    const res = await courseAPI.getEventsPagination(page);
    setCoursesList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleRedirect = (id) => {
    navigate(`${frontendRoutes.curso}/${id}`);
  };

  return (
    <>
      <SliderCourses />
      <ContentGridCourse
        data={coursesList}
        handleClick={handleRedirect}
        title={'CURSOS'}
      />
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </>
  );
};

export default Courses;
