import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextApp } from '../../store/GeneralContext.js';
import types from '../../store/types/courses.types';
import timeUtils from '../../utils/handletime.utils.js';

function ElementListCourse(props) {
  const { cover, title, author, creationDate, releaseDate, onDelete, data } =
    props;
  const { dispatch } = useContext(ContextApp);

  const history = useLocation();
  const navigate = useNavigate();

  const redirect = () => {
    dispatch({
      type: types.SET_COURSE_TO_EDIT,
      payload: data,
    });
    navigate(history.pathname + '/editar');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 p-4 gap-4 border-b border-main-blue rounded-lg">
      <div className="">
        <img src={cover} alt="cover-course" />
      </div>
      <div className="col-span-10 text-gray-500 flex flex-col space-y-2">
        <p>
          <span className="font-semibold">Titulo:</span> {title}
        </p>
        <p>
          <span className="font-semibold">Autor:</span> {author}
        </p>
        {creationDate ? (
          <p>
            <span className="font-semibold">Fecha de publicación: </span>{' '}
            {timeUtils.formatDateofISOS(creationDate)}{' '}
          </p>
        ) : (
          <span className="hidden"></span>
        )}
        {releaseDate ? (
          <p>
            <span className="font-semibold">Fecha de inicio: </span>{' '}
            {timeUtils.formatDateofISOS(releaseDate)}{' '}
          </p>
        ) : (
          <span className="hidden"></span>
        )}
      </div>
      <div className="flex justify-end items-end space-x-10">
        <p
          className="text-red-500 cursor-pointer"
          onClick={() => onDelete(data._id)}
        >
          Eliminar
        </p>
        <p className="text-link cursor-pointer" onClick={redirect}>
          Editar
        </p>
      </div>
    </div>
  );
}

export default ElementListCourse;
