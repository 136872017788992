import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextApp } from '../../store/GeneralContext.js';
import newsTypes from '../../store/types/news.types';
import eventsTypes from '../../store/types/events.types.js';
import frontRouter from '../../resources/constants/frontend.routes';
import timeUtils from '../../utils/handletime.utils.js';
import utils from '../../utils/commonFunctions.js';
import useAuth from '../../security/auth/useAuth.js';
import { userRoles } from '../../constanst/constants.js';

function ElementList(props) {
  const {
    type,
    idItem,
    cover,
    title,
    content,
    author,
    creationDate,
    releaseDate,
    onDelete,
    projectType,
    projectCode,
    projectState,
    projectApproval,
    projectUnApproval,
  } = props;

  const { dispatch } = useContext(ContextApp);

  const history = useLocation();
  const navigate = useNavigate();

  const { isLogged } = useAuth();

  const redirect = () => {
    if (type === 'news') {
      const state = utils.createEditorStateFromHTML(content);

      dispatch({
        type: newsTypes.SET_NEW_TO_EDIT,
        payload: { idItem, cover, title, author, content: state },
      });
    }

    if (type === 'events') {
      const state = utils.createEditorStateFromHTML(content);

      dispatch({
        type: eventsTypes.SET_EVENT_TO_EDIT,
        payload: { idItem, cover, title, author, content: state },
      });
    }

    if (type === 'proyects') {
      return navigate(`${frontRouter.Proyecto}/${idItem}`);
    }

    navigate(history.pathname + '/editar');
  };

  const optionsNotProyect = () => {
    return (
      <>
        <p
          className='text-red-500 cursor-pointer'
          onClick={() => onDelete(idItem)}
        >
          Eliminar
        </p>
        <p onClick={redirect} className='text-link cursor-pointer'>
          Editar
        </p>
      </>
    );
  };

  const options = () => {
    return (
      <>
        <p onClick={redirect} className='cursor-pointer'>
          Ver
        </p>
        {projectState.toLowerCase() === 'aprobado' ? (
          <p className='text-green-600'>Aprobado</p>
        ) : projectState.toLowerCase() === 'por aprobar' &&
          isLogged.role === userRoles.admin ? (
          <>
            <p
              className='text-red-500 cursor-pointer'
              onClick={() => projectUnApproval(false, idItem)}
            >
              Desaprobar
            </p>
            <p
              className='text-link cursor-pointer'
              onClick={() => projectApproval(true, idItem)}
            >
              Aprobar
            </p>
          </>
        ) : (
          <p className='text-red-600'>{projectState}</p>
        )}
      </>
    );
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-12 p-4 gap-4 border-b border-main-blue rounded-lg'>
      <div className='flex justify-center items-center w-full'>
        <img src={`${cover}`} alt='' />
      </div>
      <div className='md:col-span-10 text-gray-500 flex flex-col space-y-2'>
        <p>
          <span className='font-semibold'>Titulo:</span> {title}
        </p>
        {projectType ? (
          <p>
            <span className='font-semibold'>Tipo de proyecto: </span>{' '}
            {projectType}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        {projectCode ? (
          <p>
            <span className='font-semibold'>Código de proyecto: </span>{' '}
            {projectCode}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        <p>
          <span className='font-semibold'>
            {type !== 'proyects' ? 'Autor' : 'Investigador principal'}:
          </span>{' '}
          {author}
        </p>
        {creationDate ? (
          <p>
            <span className='font-semibold'>Fecha de publicación: </span>{' '}
            {timeUtils.formatDateofISOS(creationDate)}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        {releaseDate ? (
          <p>
            <span className='font-semibold'>Fecha de inicio: </span>{' '}
            {timeUtils.formatDateofISOS(releaseDate)}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
      </div>
      <div className='flex justify-end items-end space-x-10'>
        {type !== 'proyects' ? optionsNotProyect() : options()}
      </div>
    </div>
  );
}

export default ElementList;
