import logo_condes from "./images/logo-condes.png";
import logo_condes_white from "./images/logo-condes-white.png";
import menu from "./images/menu.svg";
import menu_closed from "./images/menu_closed.svg";
import slide_1 from "./images/slide-1.jpg";
import conferencia from "./images/conferencia.jpg";
import studying from "./images/studying.jpg";
import facebook_white from "./images/facebook-white.png";
import instagram_white from "./images/instagram-white.png";
import twitter_white from "./images/twitter-white.png";
import logo_luz from "./images/LUZ-escudo.png";
import cursos from "./images/cursos-slider.png";
import curso from "./images/curso.png";
import condes50 from "./images/condes50.jpg";
import congreso from "./images/congreso-efecto-alt.png";
import exponentes from "./images/exponentes.jpg";
import lineas_de_investigacion from "./images/lineas-de-investigacion.png";
import folder from "./images/folder.png";
import normas from "./images/normas.jpg";
import congreso_evento from "./images/conferencia-evento.jpg";
import calendar from "./images/calendar-solid.svg";
import success from "./images/happy-people.png";
import cintillo_congreso from "./images/cintillo-congreso.png";
import poster_congreso from "./images/poster-congreso.png";
import identificador_campaña from "./images/identificador-campana.jpg";
import vision from "./images/eye.png";
import mision from "./images/mission.png";
import target from "./images/target.png";
import location from "./images/location-dot-solid.svg";
import user from "./images/user-icon.png";

const images = {
  logo_condes,
  logo_condes_white,
  menu,
  menu_closed,
  slide_1,
  conferencia,
  studying,
  facebook_white,
  instagram_white,
  twitter_white,
  logo_luz,
  cursos,
  curso,
  condes50,
  congreso,
  exponentes,
  lineas_de_investigacion,
  folder,
  normas,
  congreso_evento,
  calendar,
  success,
  cintillo_congreso,
  poster_congreso,
  identificador_campaña,
  vision,
  mision,
  target,
  location,
  user
};

export default images;
