import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Square from '../general/Square';
import { Link } from 'react-router-dom';
import MainButton from '../general/MainButton';
import validations from '../../security/schemas/formProjectsSchemas/registerProjects.schema';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import projectsApi from '../../API/projects/projects.api';
import { useState } from 'react';
import Loader from '../general/Loader';
import { uploadFilesToGoogleDrive } from '../../services/uploadFileGoogleDrive';

function ProyectThirdStep({ step, next, previous }) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations.registerProjectsStepThreeSchema),
  });

  const [backendError, setBackendError] = useState('');
  const [uploadingFiles, setUploadingFiles] = useState({
    chronogram_activities: {
      loading: false,
      message: '',
      success: null,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeInputFile = async ({ target }) => {
    if (!target) return null;

    const file = target.files[0];

    setUploadingFiles({
      ...uploadingFiles,
      [target.name]: {
        loading: true,
        message: 'Subiendo archivo...',
        success: false,
      },
    });

    setIsLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      const rawLog = reader.result.split(',')[1];
      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      };

      const docUrl = await uploadFilesToGoogleDrive(dataSend);

      if (!docUrl?.url) {
        setUploadingFiles({
          ...uploadingFiles,
          [target.name]: {
            loading: false,
            message: 'Error al guardar documento, intente de nuevo.',
            success: false,
          },
        });

        return;
      }

      setUploadingFiles({
        ...uploadingFiles,
        [target.name]: {
          loading: false,
          message: 'Guardado exitosamente.',
          success: true,
        },
      });

      clearErrors('chronogram_activities');

      const data = {
        name: target.name,
        file: docUrl,
      };

      setValue('chronogram_activities', data);
      setIsLoading(false);
    };
  };

  const registerStepTwo = async (data) => {
    const id_project = handleStorage.getItemLocalStorage(
      'id_new_project_condes'
    );

    data.id = id_project;

    if (!data.chronogram_activities) {
      setError('chronogram_activities', {
        type: 'custom',
        message: 'Debe ingresar un cronograma de actividades.',
      });
      return;
    }

    setIsLoading(true);

    const response = await projectsApi.updateProjectByFlag(data, 'stepThree');

    setIsLoading(false);

    if (response?.data?.success === true) {
      return next();
    }

    if (response.data.success === false) {
      setBackendError(response.data.msg);
    }
  };

  return (
    <div
      className={
        step === 3
          ? 'w-full flex flex-col justify-center items-center'
          : 'hidden'
      }
    >
      <Square>
        <div className='w-10/12 flex items-center justify-between text-xl'>
          <p className='text-link'>Paso 3/5</p>
          <Link to={'/'}>
            <p className='font-bold text-red-600'>x</p>
          </Link>
        </div>
        <h3 className='text-link text-center my-12'>
          DESCRIPCIÓN DEL PROYECTO
        </h3>

        {backendError.length > 0 && (
          <p className='text-red-600 text-center'>{backendError}</p>
        )}

        <form
          onSubmit={handleSubmit(registerStepTwo)}
          className='form-content'
          autoComplete='off'
        >
          <textarea
            {...register('project_summary')}
            className='rounded-md border px-4 w-10/12'
            placeholder='Resumen del proyecto'
            rows='10'
          ></textarea>
          {errors?.project_summary && (
            <p className='text-center text-red-600 w-full'>
              {errors.project_summary.message}
            </p>
          )}

          <input
            {...register('general_objective')}
            type='text'
            placeholder='Objetivo general'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.general_objective && (
            <p className='text-center text-red-600 w-full'>
              {errors.general_objective.message}
            </p>
          )}

          <textarea
            {...register('specific_objectives')}
            className='rounded-md border px-4 w-10/12'
            placeholder='Objetivos especificos'
            rows='10'
          ></textarea>
          {errors?.specific_objectives && (
            <p className='text-center text-red-600 w-full'>
              {errors.specific_objectives.message}
            </p>
          )}

          <input
            {...register('hypothesis')}
            type='text'
            placeholder='Hipótesis (Si la hubiera)'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.hypothesis && (
            <p className='text-center text-red-600 w-full'>
              {errors.hypothesis.message}
            </p>
          )}

          <input
            {...register('methodology_used')}
            type='text'
            placeholder='Metodología a utilizar'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.methodology_used && (
            <p className='text-center text-red-600 w-full'>
              {errors.methodology_used.message}
            </p>
          )}

          <textarea
            {...register('feasibility_research')}
            className='rounded-md border px-4 w-10/12'
            placeholder='Viabilidad y aplicabilidad de los resultados de la investigación'
            rows='10'
          ></textarea>
          {errors?.feasibility_research && (
            <p className='text-center text-red-600 w-full'>
              {errors.feasibility_research.message}
            </p>
          )}

          <div className='w-10/12 flex flex-col justify-center items-center'>
            <p>Cronograma de actividades (18 meses)</p>
            <input
              accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
              type='file'
              name='chronogram_activities'
              placeholder='Cronograma de actividades (18 meses)'
              className='w-full px-4 rounded-md border'
              onChange={handleChangeInputFile}
            />
            {errors?.chronogram_activities && (
              <p className='text-center text-red-600 w-full'>
                {errors.chronogram_activities.message}
              </p>
            )}

            {uploadingFiles.chronogram_activities.success !== null && (
              <p className='text-green-600'>
                {uploadingFiles.chronogram_activities.message}
              </p>
            )}
          </div>

          {/* <textarea
            {...register('chronogram_activities')}
            className='rounded-md border px-4 w-10/12'
            placeholder='Cronograma de actividades (18 meses)'
            rows='10'
          ></textarea>
          {errors?.chronogram_activities && (
            <p className='text-center text-red-600 w-full'>
              {errors.chronogram_activities.message}
            </p>
          )} */}

          <div className='flex justify-between items-center w-10/12'>
            <MainButton type='button' text='Atras' event={previous} />
            <MainButton text='Continuar' />
          </div>
        </form>
        <Loader isOpen={isLoading} />
      </Square>
    </div>
  );
}

export default ProyectThirdStep;
