import { useRef } from 'react';

const ContainerModal = ({ children, title, closeModal }) => {
  const refModal = useRef(null);

  const callback = (e) => {
    refModal.current.removeEventListener('animationend', callback);
  };

  const onCloseModal = () => {
    refModal.current.classList.add('fadeOut');
    closeModal();
    refModal.current.addEventListener('animationend', callback, { once: true });
  };

  return (
    <div
      ref={refModal}
      className='w-full h-screen fixed top-0 bg-black flex justify-center items-center p-5 fadeIn z-50'
    >
      <div className='w-full h-90% md:w-3/4 md:h-80%-vh text-black rounded-lg bg-white'>
        <div className='w-full h-14 flex justify-center items-center relative'>
          <label className='text-lg font-bold'>{title}</label>
          <span
            onClick={onCloseModal}
            className='w-6 h-6 text-center absolute top-1 right-1 cursor-pointer text-black bg-gray-300 hover:text-white rounded-lg'
          >
            X
          </span>
        </div>
        <div className='p-5 h-85% max-h-90%'>{children}</div>
      </div>
    </div>
  );
};

export default ContainerModal;
