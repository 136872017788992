import * as yup from 'yup';

const newsSchema = yup.object().shape({
  titulo: yup
    .string('Ingrese un título corecto')
    .required('Título es requerido'),
  contenido: yup.string('Ingrese un contenido corecto'),
  // .required('Contenido es requerido'),
});

export default newsSchema;
