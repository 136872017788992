import React from "react";
// import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import images from "../../resources/images";
import { Link } from "react-router-dom";

// import Swiper core and required modules

SwiperCore.use([Navigation, Autoplay]);

function Slider() {
  return (
    <Swiper
      navigation={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: true,
      }}
      loop={true}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="bg-conferencia bg-cover bg-center flex justify-center items-center h-70%-vh min-h-400 ">
          <div className="flex justify-center items-center flex-col space-y-6 md:pl-20 text-center bg-gradient-to-tr from-gray-700/50 via-gray-900/50 to-black h-70%-vh w-full min-h-400 z-10 px-8">
            <h1 className="text-main-blue px-12 leading-10 tracking-wide text-2xl md:text-4xl font-bold GFG animate__animated animate__fadeInDown">
              CONGRESO INTERNACIONAL DE CIENCIA, TECNOLOGÍA Y HUMANIDADES
            </h1>
            <div className="w-full flex justify-center items-center">
              <Link to={"congreso"}>
                <h3 className="md:text-2xl text-lg font-light text-white bg-main-blue rounded-full py-2 px-4 animate__animated animate__fadeInDown">
                  Conoce más
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="flex justify-center items-center h-70%-vh min-h-400 p-4 backdrop-blur-sm">
          <img
            src={images.slide_1}
            className="object-cover h-70%-vh absolute"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
          <div className="flex flex-col items-center justify-center space-y-6">
            <h1 className="text-center GFG text-main-blue z-10 px-12 text-2xl leading-10 tracking-wide md:text-4xl font-bold">
              CONSEJO DE DESARROLLO CIENTÍFICO HUMANÍSTICO Y TECNOLÓGICO
            </h1>
            <Link to={"quienes-somos"}>
              <h3 className="md:text-2xl text-lg font-light text-white bg-main-blue rounded-full py-2 px-4 animate__animated animate__fadeInDown">
                Conoce más sobre nosotros
              </h3>
            </Link>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Slider;
