import { useState, useEffect } from 'react';
import * as settingsApi from '../API/settings/settings.api';
import { uploadFilesToGoogleDrive } from '../services/uploadFileGoogleDrive';
import Loader from '../components/general/Loader';
import images from '../resources/images';

const AdminPanelSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });
  const [settings, setSettings] = useState({});
  const [isUploadingDocument, setIsUploadingDocument] = useState({
    grant_application_for_project_approval: {
      loading: false,
      msg: '',
      success: null,
    },
    progress_report_eight_months: {
      loading: false,
      msg: '',
      success: null,
    },
    progress_report_eighteen_months: {
      loading: false,
      msg: '',
      success: null,
    },
  });

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    setIsLoading(true);

    const settingsList = await settingsApi.getSettingsList();

    setIsLoading(false);

    if (settingsList) {
      setSettings(settingsList);
    }
  };

  const addDocument = ({ target }) => {
    if (!target.files[0]) return null;

    setIsUploadingDocument({
      ...isUploadingDocument,
      [target.name]: {
        loading: true,
        msg: 'Cargando...',
        success: false,
      },
    });

    const file = target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function () {
      const rawLog = reader.result.split(',')[1];

      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      };

      try {
        const docUrl = await uploadFilesToGoogleDrive(dataSend);

        if (!docUrl?.url) {
          setIsUploadingDocument((_state) => ({
            ...isUploadingDocument,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        const data = {
          name: target.name,
          file: docUrl,
        };

        const response = await settingsApi.updateSettings({
          [target.name]: data,
        });

        if (response?.data?.success === false) {
          setIsUploadingDocument((_state) => ({
            ...isUploadingDocument,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        setIsUploadingDocument((_state) => ({
          ...isUploadingDocument,
          [target.name]: {
            loading: false,
            msg: `Archivo ${file.name} Guardado exitosamente.`,
            success: true,
          },
        }));

        getSettings();
      } catch (error) {
        console.log('Error al subir archivo', error);
        return setIsUploadingDocument((_state) => ({
          ...isUploadingDocument,
          [target.name]: {
            loading: false,
            msg: `Error al guardar documento ${file}, intente de nuevo.`,
            success: false,
          },
        }));
      }
    };
  };

  const checkSomeDocumentIsLoading = () =>
    Object.values(isUploadingDocument).some((loading) => loading.loading);

  return (
    <div className='flex flex-col justify-center items-center space-y-8 p-6 lg:py-6 lg:px-32'>
      {isLoading ? (
        <Loader isOpen={isLoading} />
      ) : (
        <p className={`text-${backendMessage.type ? 'red' : 'green'}-600`}>
          {backendMessage.message}
        </p>
      )}
      <div className='w-full min-h-[50vh] flex flex-col justify-center items-center space-y-2'>
        <div className='w-full p-3 border border-main-blue'>
          <h3>Formato de Solicitud de Subvención</h3>
          <input
            className='w-4/5 md:w-2/5'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
            type='file'
            name='grant_application_for_project_approval'
            onChange={addDocument}
            disabled={checkSomeDocumentIsLoading}
          />
          {isUploadingDocument.grant_application_for_project_approval
            .success !== null && (
            <p className='text-green-600'>
              {isUploadingDocument.grant_application_for_project_approval.msg}
            </p>
          )}
          {settings?.grant_application_for_project_approval && (
            <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
              <img src={images.folder} style={{ width: 65 }} alt='' />
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href={
                  settings?.grant_application_for_project_approval?.file?.url
                }
              >
                <p className='text-link'>Solicitud de subvención</p>
              </a>
            </div>
          )}
        </div>

        <div className='w-full p-3 border border-main-blue'>
          <h3>Formato Informe de Avance 8 meses</h3>
          <input
            className='w-4/5 md:w-2/5'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
            type='file'
            name='progress_report_eight_months'
            onChange={addDocument}
            disabled={checkSomeDocumentIsLoading}
          />
          {isUploadingDocument.progress_report_eight_months.success !==
            null && (
            <p className='text-green-600'>
              {isUploadingDocument.progress_report_eight_months.msg}
            </p>
          )}
          {settings?.progress_report_eight_months && (
            <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
              <img src={images.folder} style={{ width: 65 }} alt='' />
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href={settings?.progress_report_eight_months?.file?.url}
              >
                <p className='text-link'>Informe de Avance 8 meses</p>
              </a>
            </div>
          )}
        </div>

        <div className='w-full p-3 border border-main-blue'>
          <h3>Formato Informe de Avance 18 meses</h3>
          <input
            className='w-4/5 md:w-2/5'
            accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
            type='file'
            name='progress_report_eighteen_months'
            onChange={addDocument}
            disabled={checkSomeDocumentIsLoading}
          />
          {isUploadingDocument.progress_report_eighteen_months.success !==
            null && (
            <p className='text-green-600'>
              {isUploadingDocument.progress_report_eighteen_months.msg}
            </p>
          )}
          {settings?.progress_report_eighteen_months && (
            <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
              <img src={images.folder} style={{ width: 65 }} alt='' />
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href={settings?.progress_report_eighteen_months?.file?.url}
              >
                <p className='text-link'>Informe de Avance 18 meses</p>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanelSettings;
