import React from 'react';

function MainButton({
  type = 'submit',
  text,
  classes,
  event,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      onClick={event}
      type={type}
      className={
        classes
          ? 'bg-main-blue text-white px-6 py-2 rounded-full ' + classes
          : `${
              disabled ? 'bg-gray-400' : 'bg-main-blue'
            } text-white px-6 py-2 rounded-full`
      }
    >
      {text}
    </button>
  );
}

export default MainButton;
