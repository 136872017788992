import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { userRoles } from '../../constanst/constants';
import images from '../../resources/images';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import NavBarChild from './NavBarChild';

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const nosotros = useRef(null);
  const regulaciones = useRef(null);
  const servicios = useRef(null);
  const unetenos = useRef(null);
  const nosotrosm = useRef(null);
  const regulacionesm = useRef(null);
  const serviciosm = useRef(null);
  const unetenosm = useRef(null);
  const options = useRef(null);
  const optionsm = useRef(null);

  const { pathname } = useLocation();

  const [isLogged, setIsLogged] = useState(null);

  useEffect(() => {
    setExpanded(false);
    const isAuth = handleStorage.getItemLocalStorage('user_condes');
    setIsLogged(isAuth);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      let { innerWidth } = e.target;
      if (innerWidth >= 1024 && expanded === true) {
        setExpanded(false);
      }
    });
  }, [expanded]);

  const toggle = (e, ref) => {
    if (e.type === 'mouseenter') {
      ref.current.classList.toggle('hidden');
    }
    if (e.type === 'mouseleave') {
      ref.current.classList.toggle('hidden');
    }
  };

  const togglem = (e, ref) => {
    if (e.type === 'click') {
      ref.current.classList.toggle('hidden');
    }
  };

  const saved = localStorage.getItem('user_condes');
  const user = JSON.parse(saved);

  const services = [
    {
      titulo: 'Cursos',
      link: 'cursos',
    },
    {
      titulo: 'Congreso',
      link: 'congreso',
    },
    {
      titulo: 'Asesorias',
      link: 'asesorias',
    },
    {
      titulo: 'Solicitud de credenciales',
      link: 'solicitud-de-credenciales',
    },
    {
      titulo: 'Registro de programas',
      link: 'registrar-programas',
      private: true,
    },
    {
      titulo: 'Registro de proyectos',
      link: 'registrar-proyectos',
      private: true,
    },
  ];

  return (
    <nav className='text-sm font-semibold sticky top-0 z-[500]'>
      <section className='bg-main-blue py-2 px-4 lg:px-10 flex items-center justify-between text-white'>
        <div className='hidden lg:flex space-x-8 items-center'>
          <Link to='/'>
            <img
              className='cursor-pointer'
              src={images.logo_condes}
              alt='logo'
              style={{ width: '50px' }}
            />
          </Link>
          <div onMouseLeave={(e) => toggle(e, nosotros)}>
            <p
              className='cursor-pointer'
              onMouseEnter={(e) => toggle(e, nosotros)}
            >
              Nosotros <span className='text-xs font-thin'>V</span>
            </p>
            <NavBarChild
              reference={nosotros}
              texts={[
                {
                  titulo: 'Quienes somos',
                  link: 'quienes-somos',
                },
                {
                  titulo: 'Autoridades',
                  link: 'autoridades',
                },
                {
                  titulo: 'Líneas de investigación',
                  link: 'lineas-de-investigacion',
                },
                {
                  titulo: 'Noticias',
                  link: 'noticias',
                },
                {
                  titulo: 'Eventos',
                  link: 'eventos',
                },
              ]}
            />
          </div>

          <div onMouseLeave={(e) => toggle(e, regulaciones)}>
            <p
              onMouseEnter={(e) => toggle(e, regulaciones)}
              className='cursor-pointer'
            >
              Regulaciones <span className='text-xs font-thin'>V</span>
            </p>
            <NavBarChild
              reference={regulaciones}
              texts={[
                {
                  titulo: 'Normas y reglamentos',
                  link: 'normas-y-reglamentos',
                },
              ]}
            />
          </div>

          <div onMouseLeave={(e) => toggle(e, servicios)}>
            <p
              className='cursor-pointer servicios'
              onMouseEnter={(e) => toggle(e, servicios)}
            >
              Servicios <span className='text-xs font-thin'>V</span>
            </p>
            <NavBarChild reference={servicios} texts={services} />
          </div>
          <div onMouseLeave={(e) => toggle(e, unetenos)}>
            <p
              className='cursor-pointer unetenos'
              onMouseEnter={(e) => toggle(e, unetenos)}
            >
              Únetenos <span className='text-xs font-thin'>V</span>
            </p>
            <NavBarChild
              reference={unetenos}
              texts={[
                {
                  titulo: 'Solicitud de convenio',
                  link: 'convenios',
                },
                {
                  titulo: 'Contáctanos',
                  link: 'contactanos',
                },
              ]}
            />
          </div>
        </div>
        <div className='hidden lg:flex space-x-8 items-center'>
          <div className={user ? 'hidden' : 'flex space-x-8 items-center'}>
            <Link to={'/registro'}>
              <p className='cursor-pointer'>Registrarse</p>
            </Link>
            <Link to={'/acceder'}>
              <p className='cursor-pointer'>Acceder</p>
            </Link>
          </div>
          <img
            className={user ? 'bg-white rounded-full' : 'hidden'}
            width='35px'
            src={images.user}
            alt=''
          />
          <div
            className={user ? 'text-white' : 'hidden'}
            onMouseLeave={(e) => toggle(e, options)}
          >
            <p
              className='cursor-pointer options'
              onMouseEnter={(e) => toggle(e, options)}
            >
              <span className='text-xs font-thin'>V</span>
            </p>
            <NavBarChild
              reference={options}
              classes={'w-0'}
              secClasses={'w-40 right-32'}
              texts={[
                {
                  titulo: 'Mi cuenta',
                  link: 'mi-cuenta',
                },
                isLogged?.role === userRoles.admin
                  ? { titulo: 'Panel admin', link: 'panel' }
                  : {},
                [userRoles.coordinator, userRoles.evaluator].includes(
                  isLogged?.role
                )
                  ? {
                      titulo: 'Programas',
                      link: 'programas',
                    }
                  : {},
                [userRoles.coordinator, userRoles.evaluator].includes(
                  isLogged?.role
                )
                  ? {
                      titulo: 'Proyectos',
                      link: 'proyectos',
                    }
                  : {},
                {
                  titulo: 'Mis programas',
                  link: 'mis-programas',
                },
                {
                  titulo: 'Mis proyectos',
                  link: 'mis-proyectos',
                },
                {
                  titulo: 'Salir',
                  link: 'salir',
                },
              ]}
            />
          </div>
        </div>
        <div className='flex lg:hidden items-center'>
          <Link to='/'>
            <img
              src={images.logo_condes}
              alt='logo'
              style={{ width: '40px' }}
            />
          </Link>
        </div>
        <div className='flex lg:hidden space-x-6 items-center'>
          <div className={user ? 'hidden' : 'flex space-x-6 items-center'}>
            <Link to={'/registro'}>
              <p className='cursor-pointer'>Registrarse</p>
            </Link>
            <Link to={'/acceder'}>
              <p className='cursor-pointer'>Acceder</p>
            </Link>
          </div>
          <img
            src={expanded === false ? images.menu : images.menu_closed}
            alt=''
            className='cursor-pointer fill-white'
            style={expanded === false ? { width: '30px' } : { width: '30px' }}
            onClick={(e) => {
              expanded === true ? setExpanded(false) : setExpanded(true);
            }}
          />
        </div>
      </section>
      <section
        className={`${
          expanded === true
            ? 'bg-main-blue text-white flex flex-col text-center p-4 space-y-4 border-y border-white'
            : 'hidden'
        }`}
      >
        <div className='flex flex-col space-y-2'>
          <div
            className={
              user ? 'flex items-center justify-center space-x-2' : 'hidden'
            }
            onClick={(e) => togglem(e, optionsm)}
          >
            <img
              className='bg-white rounded-full'
              width='35px'
              src={images.user}
              alt=''
            />
            <p className='cursor-pointer optionsm'>
              <span className='text-xs font-thin'>V</span>
            </p>
          </div>

          <div className={user ? 'text-white' : 'hidden'}>
            <div className='flex flex-col'>
              <NavBarChild
                reference={optionsm}
                texts={[
                  {
                    titulo: 'Mi cuenta',
                    link: 'mi-cuenta',
                  },
                  isLogged?.role === userRoles.admin
                    ? { titulo: 'Panel admin', link: 'panel' }
                    : {},
                  [userRoles.coordinator, userRoles.evaluator].includes(
                    isLogged?.role
                  )
                    ? {
                        titulo: 'Programas',
                        link: 'programas',
                      }
                    : {},
                  [userRoles.coordinator, userRoles.evaluator].includes(
                    isLogged?.role
                  )
                    ? {
                        titulo: 'Proyectos',
                        link: 'proyectos',
                      }
                    : {},
                  {
                    titulo: 'Mis programas',
                    link: 'mis-programas',
                  },
                  {
                    titulo: 'Mis proyectos',
                    link: 'mis-proyectos',
                  },
                  {
                    titulo: 'Salir',
                    link: 'salir',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col space-y-2'>
          <p className='cursor-pointer' onClick={(e) => togglem(e, nosotrosm)}>
            Nosotros <span className='text-xs font-thin'>V</span>
          </p>
          <NavBarChild
            reference={nosotrosm}
            texts={[
              {
                titulo: 'Quienes somos',
                link: 'quienes-somos',
              },
              {
                titulo: 'Autoridades',
                link: 'autoridades',
              },
              {
                titulo: 'Líneas de investigación',
                link: 'lineas-de-investigacion',
              },
              {
                titulo: 'Noticias',
                link: 'noticias',
              },
              {
                titulo: 'Eventos',
                link: 'eventos',
              },
            ]}
          />
        </div>
        <div className='flex flex-col space-y-2'>
          <p
            className='cursor-pointer'
            onClick={(e) => togglem(e, regulacionesm)}
          >
            Regulaciones <span className='text-xs font-thin'>V</span>
          </p>
          <NavBarChild
            reference={regulacionesm}
            texts={[
              {
                titulo: 'Normas y reglamentos',
                link: 'normas-y-reglamentos',
              },
            ]}
          />
        </div>
        <div className='flex flex-col space-y-2'>
          <p className='cursor-pointer' onClick={(e) => togglem(e, serviciosm)}>
            Servicios <span className='text-xs font-thin'>V</span>
          </p>
          <NavBarChild reference={serviciosm} texts={services} />
        </div>
        <div className='flex flex-col space-y-2'>
          <p className='cursor-pointer' onClick={(e) => togglem(e, unetenosm)}>
            Únetenos <span className='text-xs font-thin'>V</span>
          </p>
          <NavBarChild
            reference={unetenosm}
            texts={[
              {
                titulo: 'Solicitud de convenio',
                link: 'convenios',
              },
              {
                titulo: 'Contáctanos',
                link: 'contactanos',
              },
            ]}
          />
        </div>
      </section>
    </nav>
  );
}

export default NavBar;
