import { useContext, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import newsSchema from '../security/schemas/news.schema';
import newsApi from '../API/news/news.api';
import newsTypes from '../store/types/news.types';
import { ContextApp } from '../store/GeneralContext';
import formValidations from '../security/validations/validationForms';
import EditorContent from '../components/forms/EditorContent';
import utilsFunctions from '../utils/commonFunctions';
import handleStorage from '../services/localstorage/handelLocalStorage';
import Loader from '../components/general/Loader';

function EditNews() {
  const navigate = useNavigate();

  const { state, dispatch } = useContext(ContextApp);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newsSchema),
  });

  const formRef = useRef(null);

  const [messageNotification, setMessageNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue('id_new', state.newsToEdit.idItem);
    setValue('titulo', state.newsToEdit.title);
  }, [state, setValue]);

  const onEditorStateChange = (editorState) => {
    dispatch({
      type: newsTypes.HANDLE_CHANGE_EDITOR_NEWS,
      payload: editorState,
    });
  };

  const editNews = async (data) => {
    const user = handleStorage.getNameUser('user_condes');

    const newsForm = {
      id_new: data.id_new,
      titulo: data.titulo,
      author: `${user.name} ${user.last_name}`,
    };

    setIsLoading(true);

    if (data.portada[0]) {
      const finalPathImg = await utilsFunctions.uploadImageCallBack(
        data.portada[0]
      );
      newsForm.finalPathImg = finalPathImg.data.link;
    }

    const contentStringHTML = utilsFunctions.getStringHTMLFromStateEditor(
      state.stateEditorNews.getCurrentContent()
    );

    newsForm.contenido = contentStringHTML;

    const res = await newsApi.editNews(newsForm);

    setIsLoading(true);

    setMessageNotification(res.data.msg);

    if (res.data.success) {
      reset();
      dispatch({ type: newsTypes.RESET_NEW_TO_EDIT });
      navigate('/panel/lista-de-noticias');
    }
  };

  return (
    <>
      <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
        <h1 className='text-4xl font-bold text-main-blue'>Editar noticia</h1>
        <Square classes='lg:min-w-[70%] lg:py-8'>
          <div className='w-full flex items-center justify-end text-red-600'>
            <Link to={'/panel/lista-de-noticias'}>
              <p className='font-bold'>x</p>
            </Link>
          </div>
          <form
            ref={formRef}
            className='form-content'
            onSubmit={handleSubmit(editNews)}
            encType='multipart/form-data'
          >
            <input type='text' hidden {...register('id_new')} name='id_new' />
            <input
              type='text'
              className='rounded-full border px-4 w-10/12'
              placeholder='Titulo'
              name='titulo'
              {...register('titulo')}
            />
            <EditorContent
              editorState={state.stateEditorNews}
              onEditorStateChange={onEditorStateChange}
            />
            {/* <textarea
              className="rounded border px-4 w-10/12"
              placeholder="Contenido"
              name="contenido"
              {...register('contenido')}
              id=""
              cols="30"
              rows="10"
            ></textarea> */}
            <div className='w-10/12 flex-col flex justify-center items-start'>
              <p>Seleccionar portada</p>
              <input
                className='px-4 w-10/12'
                accept='image/png, image/jpeg, image/jpg'
                type='file'
                name='portada'
                {...register('portada')}
                id=''
              />
            </div>
            {errors?.titulo && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.titulo?.message}
              </div>
            )}
            {errors?.contenido && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.contenido?.message}
              </div>
            )}
            {messageNotification !== '' && (
              <div className='w-full flex items-center justify-center text-green-600'>
                {messageNotification}
              </div>
            )}
            <MainButton text='Guardar' disabled={isLoading} />
          </form>
          <Loader isOpen={isLoading} />
        </Square>
      </section>
    </>
  );
}

export default EditNews;
