import { useEffect, useState } from 'react';
import Paginate from 'react-paginate';
import usersApi from '../../API/users/user.api';
import { userRoles } from '../../constanst/constants';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import Loader from './Loader';
import MainButton from './MainButton';

const ModalAssignmentProjectTo = ({ handleClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [evaluatorsList, setEvaluatorsList] = useState([]);

  useEffect(() => {
    getEvaluatorsUsers();
  }, []);
  useEffect(() => {
    getEvaluatorsUsers();
  }, [page]);

  const getEvaluatorsUsers = async () => {
    const user = handleStorage.getItemLocalStorage('user_condes');
    const response = await usersApi.getUsersByRoleAndCommissionsRole(
      userRoles.evaluator,
      user.commissionsRole,
      page
    );

    setIsLoading(false);

    if (response?.data?.data?.data?.length === 0) {
      setBackendMessage({
        ...backendMessage,
        message: 'No hay evaluadores para asignar',
      });
      return;
    }

    setEvaluatorsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  return (
    <div className='w-full h-full flex flex-col justify-between'>
      {backendMessage.message !== '' && (
        <p
          className={`text-center text-${
            !backendMessage.type ? 'red' : 'green'
          }-600`}
        >
          {backendMessage.message}
        </p>
      )}

      <Loader isOpen={isLoading} />

      <div className='w-full min-h-[45vh] max-h-[45vh] overflow-y-auto overflow-x-hidden py-2'>
        {evaluatorsList.length > 0 &&
          evaluatorsList.map((evaluator) => (
            <div
              key={evaluator.id}
              className='flex justify-evenly lg:justify-between items-center p-2 border border-main-blue'
            >
              <div className='w-[60%] grid gap-2'>
                <span>Evaluador(a)</span>
                <label>
                  {evaluator?.name} {evaluator.last_name}
                </label>
              </div>
              <MainButton
                type='button'
                text={'Asignar'}
                classes='w-[35%] h-10'
                event={() => handleClick(evaluator.id)}
              />
            </div>
          ))}
      </div>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
};

export default ModalAssignmentProjectTo;
