import React from "react";
import MidTittle from "../components/general/MidTittle";
import images from "../resources/images";
import "animate.css";

function LinesOfResearch() {
  return (
    <>
      <section className="bg-pattern h-50%-vh min-h-600 grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-center px-4 items-center flex-col space-y-6 md:items-start md:pl-20 flex-col">
          <h1 className="text-main-blue text-center text-4xl font-bold animate__animated animate__fadeInDown">
            Lineas de investigación
          </h1>
          <p className="animate__animated animate__fadeInDown">
            El CONDES cuenta con once campos de actuación entre los cuales se
            encuentran: ambiente (AMB), ciencias exactas (CSE), humanidades,
            artes y educación (HAE), seguridad y soberanía alimentaria (SSA),
            ingeniería, arquitectura y urbanismo (IAU), energía, petróleo,
            minería y materiales (EPM), ciencias económicas y sociales (CES),
            desarrollo industrial (DIN), tecnología, informática y
            telecomunicaciones (TIT), biología y salud (BS), innovación social
            (IS).
          </p>
        </div>
        <div className="flex items-center justify-center animate__animated animate__fadeInDown">
          <img
            className="w-10/12 object-cover rounded"
            src={images.lineas_de_investigacion}
            alt=""
          />
        </div>
      </section>
      <section className="py-8">
        <MidTittle title={"Más detalles"} />
        <div className="flex justify-center items-center">
          <div className="border border-main-blue rounded p-8 flex flex-col justify-center items-center space-y-4">
            <img src={images.folder} style={{ width: 65 }} alt="" />
            <a target="_blank" rel="noreferrer" href="https://www.mediafire.com/file/lhequdwqn8rv2k8/Lineas-de-investigacion.pdf/file">
              <p className="text-link">Lineas de investigación.pdf</p>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default LinesOfResearch;
