import React from 'react';

const Loader = ({ isOpen = false }) => {
  return (
    <div className='w-full min-h-[50px] max-h-[50px]'>
      {isOpen && (
        <div className='loaderRectangle'>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Loader;
