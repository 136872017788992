import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import userSchemas from '../../security/schemas/user.schema';
import MainButton from '../general/MainButton';
import Square from '../general/Square';
import usersApi from '../../API/users/user.api';
import Loader from '../general/Loader';

const RegisterFirstStep = ({ hidden, handleHidden, setUserId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchemas.userSchemaFirstPart),
  });

  const [errorBackend, setErrorBackend] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const registerUser = async (data) => {
    setIsLoading(true);

    const res = await usersApi.createNewUSer(data);

    setIsLoading(false);

    if (res?.data?.success) {
      setUserId(res.data.data.userID);
      handleHidden();
    } else {
      return setErrorBackend(res.data.msg);
    }
  };

  return (
    <Square classes={hidden}>
      <p className='text-right text-blue-600 w-full'>Paso 1/2</p>
      <div className='flex flex-col space-y-1'>
        {errorBackend !== '' && (
          <p className='text-center text-red-600 w-full'>{errorBackend}</p>
        )}
        {errors?.email && (
          <p className='text-center text-red-600 w-full'>
            {errors.email.message}
          </p>
        )}
        {errors?.password && (
          <p className='text-center text-red-600 w-full'>
            {errors.password.message}
          </p>
        )}
        {errors?.password_two && (
          <p className='text-center text-red-600 w-full'>
            {errors.password_two.message}
          </p>
        )}
      </div>
      <form onSubmit={handleSubmit(registerUser)} className='form-content'>
        <input
          type='text'
          className='rounded-full border px-4 w-10/12'
          placeholder='Email'
          {...register('email')}
        />
        <input
          type='password'
          className='rounded-full border px-4 w-10/12'
          placeholder='Contraseña'
          {...register('password')}
        />
        <input
          type='password'
          className='rounded-full border px-4 w-10/12'
          placeholder='Repetir contraseña'
          {...register('password_two')}
        />
        <div className='flex items-center justify-center w-full'>
          <MainButton type='submit' text='Siguiente' disabled={isLoading} />
        </div>
      </form>
      <Loader isOpen={isLoading} />
    </Square>
  );
};

export default RegisterFirstStep;
