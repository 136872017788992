import * as yup from 'yup';

const courseSchema = yup.object().shape({
  titulo: yup
    .string('Ingrese un título corecto.')
    .required('Título es requerido.'),
  date: yup.string('Ingrese un fecha corecto.').required('Fecha es requerida.'),
  duracion: yup
    .string('Ingrese una duración corecto.')
    .required('Duración es requerida.'),
  profesor: yup
    .string('Ingrese un(a) profesor corecto.')
    .required('Profesor(a) es requerido(a).'),
  telefono: yup
    .string('Ingrese un teléfono corecto.')
    .required('Teléfono es requerido.'),
  certificado: yup
    .string('Ingrese un certificado corecto.')
    .required('Certificado es requerido.')
    .typeError('Certificado es requerido.'),
});

export default courseSchema;
//   {errors?.telefono && (
//     <div className="w-full flex items-center justify-center text-red-600">
//       {errors?.telefono?.message}
//     </div>
//   )}
//   {errors?.certificado && (
//     <div className="w-full flex items-center justify-center text-red-600">
//       {errors?.certificado?.message}
//     </div>
//   )}
//   {errors?.portada && (
//     <div className="w-full flex items-center justify-center text-red-600">
//       {errors?.portada?.message}
//     </div>
//   )}
