import Slider from "../sections/home/Slider";
import News from "../sections/home/News";
import JoinNow from "../sections/home/JoinNow";
import Events from "../sections/home/Events";

function Home() {
  return (
    <>
      <div className="bg-main-background">
        <Slider />
        <News />
        <JoinNow />
        <Events />
      </div>
    </>
  );
}

export default Home;
