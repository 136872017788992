import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import frontRouter from '../../resources/constants/frontend.routes';
import userSchemas from '../../security/schemas/user.schema';
import MainButton from '../general/MainButton';
import Square from '../general/Square';
import usersApi from '../../API/users/user.api';
import countries from '../../resources/js/countries.json';
import Loader from '../general/Loader';

const RegisterSecondStep = ({ hidden, userID }) => {
  let navigate = useNavigate();
  const json = countries;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchemas.userSchemaSecondPart),
  });

  const [errorBackend, setErrorBackend] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const updateUser = async (data) => {
    data.id = userID;

    setIsLoading(true);

    const res = await usersApi.updateUserSignin(data);

    setIsLoading(false);

    if (res?.data?.success) {
      navigate(frontRouter.login);
    } else {
      return setErrorBackend(res.data.msg);
    }
  };

  return (
    <Square classes={hidden === 'hidden' ? '' : 'hidden'}>
      <p className='text-right text-blue-600 w-full'>Paso 2/2</p>
      <div className='flex flex-col space-y-1'>
        {errorBackend !== '' && (
          <p className='text-center text-red-600 w-full'>{errorBackend}</p>
        )}
        {errors?.name && (
          <p className='text-center text-red-600 w-full'>
            {errors.name.message}
          </p>
        )}
        {errors?.last_name && (
          <p className='text-center text-red-600 w-full'>
            {errors.last_name.message}
          </p>
        )}
        {errors?.country && (
          <p className='text-center text-red-600 w-full'>
            {errors.country.message}
          </p>
        )}
        {errors?.isCompany && (
          <p className='text-center text-red-600 w-full'>
            {errors.isCompany.message}
          </p>
        )}
      </div>
      <form onSubmit={handleSubmit(updateUser)} className='form-content'>
        <div className='flex flex-row space-x-2 w-10/12'>
          <input
            type='text'
            className='rounded-full border px-2 w-1/2'
            placeholder='Nombre'
            {...register('name')}
          />
          <input
            type='text'
            className='rounded-full border px-2 w-1/2'
            placeholder='Apellido'
            {...register('last_name')}
          />
        </div>
        <select
          className='rounded-full border px-2 w-10/12'
          {...register('country')}
        >
          <option disabled selected className='text-gray-200'>
            Selecciona un pais
          </option>
          {Object.keys(json).map((key, i) => (
            <option key={i} value={json[key].name}>
              {json[key].name}
            </option>
          ))}
        </select>
        <p className='text-left w-10/12'>¿Eres una empresa?</p>
        <div className='flex space-x-4 justify-start w-10/12'>
          <label>
            <input type='radio' value={true} {...register('isCompany')} /> Sí
          </label>
          <label>
            <input type='radio' value={false} {...register('isCompany')} /> No
          </label>
        </div>
        <div className='flex items-center justify-center w-full'>
          <MainButton text='Registrarme' disabled={isLoading} />
        </div>
      </form>
      <Loader isOpen={isLoading} />
    </Square>
  );
};

export default RegisterSecondStep;
