import { useState } from 'react';
import ProgramFirstStep from '../components/forms/ProgramFirstStep';
import ProgramIntroduction from '../components/forms/ProgramIntroduction';

const RegisterPrograms = () => {
  const [step, setStep] = useState(0);

  const nextStep = () => setStep(step + 1);

  return (
    <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
      <h1 className='text-center text-4xl font-bold text-main-blue'>
        Registro de programas
      </h1>
      <ProgramIntroduction step={step} next={nextStep} />
      <ProgramFirstStep step={step} />
    </section>
  );
};

export default RegisterPrograms;
