import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import MainButton from '../components/general/MainButton';
import courseAPI from '../API/course/course.api';

function Course() {
  let { id } = useParams();

  const [expanded, setExpanded] = useState();
  const [coursesData, setCoursesData] = useState({});

  useEffect(() => {
    getDataCourses();
  }, []);

  const getDataCourses = async () => {
    const res = await courseAPI.getCourseById(id);
    setCoursesData(res.data.data);
  };

  if (!coursesData?.date) {
    return '';
  }

  return (
    <section className="grid grid-cols-1 md:grid-cols-12">
      <div className="md:col-span-4 p-6">
        <div className="flex flex-col justify-center items-start border border-main-blue rounded-lg p-0">
          <img src={coursesData.pathImage} className="w-full" alt="" />
          <div className="p-2 flex flex-col space-y-2">
            <h1 className="text-link font-extrabold">{coursesData.titulo}</h1>
            <p className="text-gray-500">
              <span className="font-semibold">Fecha de inicio:</span>{' '}
              {coursesData.date}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Duración:</span>{' '}
              {coursesData.duracion}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Profesor:</span>{' '}
              {coursesData.profesor}
            </p>
            <label className="text-gray-500">
              <span className="font-semibold">Número de contacto:</span>
              <p>{coursesData.telefono}</p>
            </label>
            <Link to={'/contactanos'}>
              <MainButton text={'Registrarme'} />
            </Link>
          </div>
        </div>
      </div>
      <div className="md:col-span-8 p-6">
        {coursesData?.modules.map((module, index) => (
          <div
            key={module._id}
            className="flex flex-col justify-center items-start mb-2 border border-main-blue rounded-lg"
          >
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center border border-main-blue rounded-md py-2 px-4 font-semibold">
                <p className="text-link font-semibold">
                  {index + 1}. {module.title_module}
                </p>
                <p
                  className="border border-main-blue rounded-full px-2 cursor-pointer"
                  onClick={() => setExpanded(1)}
                >
                  +
                </p>
              </div>

              <div
                className={
                  expanded === 1 ? 'flex py-2 px-4 text-gray-500' : 'hidden'
                }
              >
                <p>{module.description_module}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Course;
