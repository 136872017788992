import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import * as programsApi from '../API/programs/programs.api';
import routes from '../resources/constants/frontend.routes';
import ContainerModal from '../components/general/ContainerModal';
import Loader from '../components/general/Loader';
import ModalAssignmentProjectTo from '../components/general/ModalAssignmentProjectTo';
import ModalToExplainUnApproval from '../components/general/ModalToExplainUnApproval';
import { userRoles } from '../constanst/constants';
import { numberOfProjectsAllowed } from '../resources/constants';
import images from '../resources/images';
import useAuth from '../security/auth/useAuth';
import handleStorage from '../services/localstorage/handelLocalStorage';

function Program() {
  const { isLogged } = useAuth();

  let params = useParams();
  const navigate = useNavigate();

  const [program, setProgram] = useState({});
  const [modalUnApprovalProgram, setModalUnApprovalProgram] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalAssignmentProgram, setModalAssignmentProgram] = useState(false);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });

  useEffect(() => {
    getProgram();
  }, []);

  const getProgram = async () => {
    const response = await programsApi.getProgramsById(params.id);
    if (response.data.success) setProgram(response.data.data);
  };

  const unApprovalProgram = () => setModalUnApprovalProgram(true);

  const handleUnApproval = (unApproval) => {
    updateApprovalProgram(false, unApproval);
  };

  const updateApprovalProgram = async (state, dataUnApproval = '') => {
    const data = {
      id_program: params.id,
      isApproval: state,
      object: dataUnApproval !== '' ? dataUnApproval : null,
    };
    const response = await programsApi.updateApprovalProgram(data);
    response.data.success && getProgram();
    response.data.success && setModalUnApprovalProgram(false);
  };

  // TODO: completar asignacion de programa
  const handleSendAssignmentProgram = async (id_evaluator) => {
    setModalAssignmentProgram(false);
    const user = handleStorage.getItemLocalStorage('user_condes');

    setIsLoading(true);

    const response = await programsApi.assignmentProgramTo({
      id_program: params.id,
      id_assignedBy: user.id,
      id_evaluator: id_evaluator,
      role: user.role,
    });

    setIsLoading(false);

    if (response.data.success === false) {
      setBackendMessage({ message: response.data.msg, type: true });
      return;
    }

    setBackendMessage({ message: response.data.msg, type: false });
    getProgram();
  };

  console.log('program ------->', program);

  return (
    <>
      {modalUnApprovalProgram && (
        <ContainerModal
          title='Especifique porque desaprueba este projecto'
          closeModal={() => setModalUnApprovalProgram(false)}
        >
          <ModalToExplainUnApproval handleClick={handleUnApproval} />
        </ContainerModal>
      )}
      {modalAssignmentProgram && (
        <ContainerModal
          title='Seleccion un evaluador'
          closeModal={() => setModalAssignmentProgram(false)}
        >
          <ModalAssignmentProjectTo handleClick={handleSendAssignmentProgram} />
        </ContainerModal>
      )}

      <div className='flex flex-col items-center justify-center space-y-8 py-8'>
        <p className='text-5xl text-main-blue font-extrabold'>
          {program?.title}
        </p>
        {isLoading ? (
          <Loader isOpen={isLoading} />
        ) : (
          <p className={`text-${backendMessage.type ? 'red' : 'green'}-600`}>
            {backendMessage.message}
          </p>
        )}

        <div className='flex w-10/12 p-8 flex-col space-y-12 items-center justify-center border border-main-blue rounded-lg'>
          <div className='flex w-full items-start justify-between'>
            <div className='flex space-x-4'>
              <div className='hidden lg:flex'>
                <img
                  className='rounded-xl'
                  src={images?.identificador_campaña}
                  width='120px'
                  alt=''
                />
              </div>
              <div className='flex flex-col space-y-2'>
                <h2 className='text-3xl text-main-blue font-bold'>
                  Metodología para programas
                </h2>
                <p>
                  <span className='text-gray-600 font-bold'>Estatus:</span>{' '}
                  {program?.status_program}
                </p>
                {isLogged.role !== userRoles.evaluator &&
                  program?.assigned_to && (
                    <p className=''>
                      <span className='text-gray-600 font-bold'>
                        Evaluador:
                      </span>{' '}
                      {program?.assigned_to?.name}{' '}
                      {program?.assigned_to?.last_name}
                    </p>
                  )}
                <div className='flex space-x-4'>
                  {[userRoles.admin, userRoles.evaluator].includes(
                    isLogged.role
                  ) &&
                    program?.projects?.length === numberOfProjectsAllowed && (
                      <>
                        {program?.status_program?.toLowerCase() ===
                          'por aprobar' && (
                          <>
                            <p
                              className='text-green-600 cursor-pointer'
                              onClick={() => updateApprovalProgram(true)}
                            >
                              Aprobar
                            </p>
                            <p
                              className='text-red-600 cursor-pointer'
                              onClick={() => {
                                if (program?.status_program !== 'Desaprobado') {
                                  unApprovalProgram();
                                }
                              }}
                            >
                              Desaprobar
                            </p>
                          </>
                        )}
                      </>
                    )}

                  {program?.projects?.length < numberOfProjectsAllowed && (
                    <p>
                      <span className='text-gray-600 font-bold'>
                        Este programa no tiene suficientes proyectos asociados
                        para poder ser aprobado.
                      </span>
                    </p>
                  )}

                  {isLogged.role === userRoles.coordinator &&
                    program?.projects?.length === numberOfProjectsAllowed &&
                    !program?.assigned_to && (
                      <p
                        className='text-green-600 cursor-pointer'
                        onClick={() => setModalAssignmentProgram(true)}
                      >
                        Asignar a
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className='flex space-x-4'>
              <p
                className='text-red-600 font-bold text-2xl cursor-pointer'
                onClick={() => navigate(-1)}
              >
                x
              </p>
            </div>
          </div>

          <div className='flex flex-col lg:flex-row space-y-8 lg:space-y-0 w-full items-start justify-between'>
            <div className='flex flex-col space-y-8 w-full lg:w-5/12'>
              <div className='flex flex-col space-y-4 border border-main-blue p-4'>
                <h2 className='text-3xl text-main-blue font-bold'>
                  Identidicación
                </h2>

                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>Tipo de programa</h3>
                  <p>{program?.type_program}</p>
                </div>

                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>
                    Código del programa
                  </h3>
                  <p>#{program?.program_code}</p>
                </div>
              </div>

              <div className='flex flex-col space-y-4 border border-main-blue p-4'>
                <h2 className='text-2xl text-main-blue font-bold'>
                  Investigador principal
                </h2>
                <div className='flex space-x-14'>
                  <p>
                    <span className='text-gray-600 font-semibold'>
                      Nombre:{' '}
                    </span>
                    {program?.name}
                  </p>
                  <p>
                    <span className='text-gray-600 font-semibold'>
                      Apellido:{' '}
                    </span>
                    {program?.last_name}
                  </p>
                </div>
                <p>
                  <span className='text-gray-600 font-semibold'>Cédula: </span>
                  {String(program?.type_identification).toUpperCase()}-
                  {program?.identification}
                </p>

                {/* Verify */}
                {/* <p>
                  <span className='text-gray-600 font-semibold'>
                    Profesión:{' '}
                  </span>
                  {program?.profession}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Especialidad:{' '}
                  </span>
                  {program?.specialty}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Ente de investigación:{' '}
                  </span>
                  {program?.research_unit}
                </p>*/}

                <p>
                  <span className='text-gray-600 font-semibold'>Email: </span>
                  {program?.email}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Teléfono:{' '}
                  </span>
                  {program?.cod_number}-{program?.phone}
                </p>
                {/* <p>
                  <span className='text-gray-600 font-semibold'>
                    Facultad/Núcleo:{' '}
                  </span>
                  {program?.faculty_core}
                </p> */}
              </div>
            </div>

            <div className='flex flex-col space-y-4 border border-main-blue p-4 w-full lg:w-5/12'>
              <h2 className='text-2xl text-main-blue font-bold'>Descripción</h2>
              {/* <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Descripción del proyecto
                </h3>
                <p>{program?.project_summary}</p>
              </div> */}
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>Objetivo general</h3>
                <p>{program?.general_objective}</p>
              </div>
            </div>
          </div>

          {program?.projects?.length > 0 && (
            <div className='w-full space-y-2'>
              <h2 className='text-3xl text-main-blue font-bold'>Proyectos</h2>
              {program?.projects?.map((project) => (
                <div
                  key={project?._id}
                  className='w-full flex border border-main-blue p-4'
                >
                  <div className='w-[50%] flex flex-col space-y-4'>
                    <p>
                      <span className='text-gray-600 font-bold'>
                        Proyecto:{' '}
                      </span>
                      {project?.title}
                    </p>
                    <p>
                      <span className='text-gray-600 font-bold'>Cód: </span>#
                      {project?.project_code}
                    </p>
                    <p>
                      <span className='text-gray-600 font-bold'>Estatus: </span>
                      {project?.status_project}
                    </p>
                  </div>
                  <div className='w-[50%] flex justify-end items-end'>
                    <Link
                      to={`${routes.Proyecto}/${project?._id}`}
                      target='_blank'
                      className='text-blue-600 cursor-pointer'
                    >
                      Ver
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Program;
