import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorPreview = ({ editorState, onEditorStateChange }) => {
  return (
    <div className="min-h-60%-vh w-full rounded">
      <Editor
        editorState={editorState}
        toolbarHidden={true}
        readOnly
        // onEditorStateChange={onEditorStateChange}
        // toolbar={{
        //   inline: { inDropdown: true },
        //   list: { inDropdown: true },
        //   textAlign: { inDropdown: true },
        //   link: { inDropdown: true },
        //   history: { inDropdown: true },
        //   image: {
        //     uploadCallback: utils.uploadImageCallBack,
        //     alt: { present: false, mandatory: false },
        //     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
        //     defaultSize: {
        //       width: '300px',
        //       height: '250px',
        //     },
        //     urlEnabled: true,
        //     inputAccept: true,
        //   },
        // }}
        editorClassName="editorClass"
      />
    </div>
  );
};

export default EditorPreview;
