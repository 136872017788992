import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Paginate from 'react-paginate';
import eventAPI from '../API/events/events.api';
import AdminOptions from '../components/admin/AdminOptions';
import ElementList from '../components/admin/ElementList';
import MainButton from '../components/general/MainButton';

function AdminEventsList() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [eventsList, setEventsList] = useState([]);

  useEffect(() => {
    getAllEvents();
  }, []);
  useEffect(() => {
    getAllEvents();
  }, [page]);

  const getAllEvents = async () => {
    const res = await eventAPI.getEventsPagination(page);
    setEventsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleSearchEvents = async ({ target }) => {
    const { data } = await eventAPI.searchEvents(target.value.toLowerCase());
    if (data.success) {
      setEventsList(data.data.data);
      setPageCount(data.data.pagination.pageCount);
    } else {
      getAllEvents();
    }
  };

  const handleDeleteEvent = async (id) => {
    const res = await eventAPI.deletedEvent(id);
    if (res.data.success) {
      getAllEvents();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-8 p-6">
      <AdminOptions />
      <div className="flex justify-center items-center w-full">
        <input
          type="text"
          onChange={handleSearchEvents}
          className="border border-main-blue rounded-l-full py-2 px-2 w-10/12 md:w-6/12"
          placeholder="Buscar eventos..."
        />
        <MainButton
          text={'Buscar'}
          classes={'rounded-l-none border border-main-blue'}
        />
      </div>
      <div className="w-full flex justify-end items-center">
        <Link to={'/panel/lista-de-eventos/agregar'}>
          <button className="px-4 py-2 bg-main-blue text-white rounded-full">
            Agregar evento
          </button>
        </Link>
      </div>
      {eventsList.map((item) => (
        <div
          key={item._id}
          className="grid grid-rows-1 w-full border border-main-blue rounded-lg"
        >
          <ElementList
            idItem={item._id}
            type="events"
            cover={item.pathImage}
            title={item.title}
            author={item.author}
            content={item.content}
            creationDate={item.updatedAt}
            onDelete={handleDeleteEvent}
          />
        </div>
      ))}
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
}

export default AdminEventsList;
