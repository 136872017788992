import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userRolesSelect } from '../constanst/constants';
import userSchemas from '../security/schemas/user.schema';
import frontRoutes from '../resources/constants/frontend.routes';
import userAPI from '../API/users/user.api';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import Loader from '../components/general/Loader';

function EditUsersList() {
  const { id } = useParams();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchemas.editUserSchemaFromAmin),
  });

  const [messageNotification, setMessageNotification] = useState('');
  const [isCompanyDefault, setIsCompanyDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDataUser(id);
  }, [id]);

  const getDataUser = async (id_user) => {
    const res = await userAPI.getDataUserByID(id_user);
    if (res.data.success) {
      const user = res.data.data[0];
      setValue('id', user.id);
      setValue('name', user.name);
      setValue('last_name', user.last_name);
      setValue('email', user.email);
      setValue('country', user.country);
      setValue('role', user.role);
      setValue('cod_number', user.cod_number);
      setValue('phone', user.phone);
      setValue('isCompany', user.isCompany);
      setIsCompanyDefault(JSON.stringify(user.isCompany));
    }
  };

  const editUser = async (data) => {
    setIsLoading(true);

    const res = await userAPI.updateDataUserFromAdmin(data);

    setIsLoading(false);

    setMessageNotification(res.data.msg);
  };

  return (
    <>
      <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
        <h1 className='text-4xl font-bold text-main-blue'>Editar usuario</h1>
        <Square>
          <div className='w-full flex items-center justify-end text-red-600'>
            <Link to={frontRoutes.panelListaDeUsuarios}>
              <p className='font-bold'>x</p>
            </Link>
          </div>

          <form className='form-content' onSubmit={handleSubmit(editUser)}>
            <div className='w-10/12 flex justify-between items-center space-x-4'>
              <input type='text' hidden {...register('id')} />
              <input
                {...register('name')}
                type='text'
                placeholder='Nombre'
                className='rounded-full border px-4 w-full md:w-1/2'
              />
              <input
                {...register('last_name')}
                type='text'
                placeholder='Apellido'
                className='rounded-full border px-4 w-full md:w-1/2'
              />
            </div>
            <input
              {...register('email')}
              type='email'
              placeholder='Email'
              className='rounded-full border px-4 w-10/12'
            />
            <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
              <input
                {...register('cod_number')}
                type='text'
                placeholder='+ Código'
                className='rounded-full border px-4 w-full md:w-1/3'
              />
              <input
                {...register('phone')}
                type='number'
                placeholder='Número de teléfono'
                className='rounded-full border px-4 w-full md:w-2/3'
              />
            </div>
            <p className='w-10/12 text-left'>Cuenta empresarial</p>
            <div className='flex w-10/12 justify-start items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <p>Sí</p>
                <input
                  type='radio'
                  value={'true'}
                  onClick={() => {
                    setValue('isCompany', 'true');
                    setIsCompanyDefault('true');
                  }}
                  checked={isCompanyDefault === 'true'}
                  {...register('isCompany')}
                />
              </div>
              <div className='flex items-center space-x-2'>
                <p>No</p>
                <input
                  type='radio'
                  value={'false'}
                  onClick={() => {
                    setValue('isCompany', 'false');
                    setIsCompanyDefault('false');
                  }}
                  checked={isCompanyDefault === 'false'}
                  {...register('isCompany')}
                />
              </div>
            </div>
            <select
              {...register('role')}
              className='rounded-full border px-4 w-10/12'
            >
              <option value='none' disabled>
                Rol
              </option>
              {Object.entries(userRolesSelect).map((role, i) => (
                <option key={i} value={role[0]} className='capitalize'>
                  {role[1]}
                </option>
              ))}
            </select>
            {errors?.name && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.name?.message}
              </div>
            )}
            {errors?.last_name && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.last_name?.message}
              </div>
            )}
            {errors?.email && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.email?.message}
              </div>
            )}
            {errors?.cod_number && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.cod_number?.message}
              </div>
            )}
            {errors?.phone && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.phone?.message}
              </div>
            )}
            {errors?.isCompany && (
              <div className='w-full flex items-center justify-center text-red-600'>
                {errors?.isCompany?.message}
              </div>
            )}
            {messageNotification !== '' && (
              <div className='w-full flex items-center justify-center text-green-600'>
                {messageNotification}
              </div>
            )}
            <MainButton text='Guardar' disabled={isLoading} />
          </form>
          <Loader isOpen={isLoading} />
        </Square>
      </section>
    </>
  );
}

export default EditUsersList;
