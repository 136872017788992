import React from "react";
import { Link } from "react-router-dom";
import images from "../resources/images";

function Footer() {
  return (
    <footer className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center md:text-left md:gap-4 bg-main-blue py-8 px-8 justify-items-center">
      <div className="flex flex-col items-center justify-center space-y-12">
        <img
          src={images.logo_condes_white}
          alt="logo"
          style={{ width: "200px" }}
        />
        <p className="text-white text-center text-sm">
          El Consejo de Desarrollo Científico Humanistico y Tecnológico es una
          dependencia académica, científica y administrativa adscrita al
          Vicerrectorado Académico de la Universidad del Zulia
        </p>
        <div className="flex justify-center items-center space-x-6">
          <a href="https://www.instagram.com/condes_luz" target="_blank" rel="noreferrer">
            <img
              src={images.instagram_white}
              alt="instagram"
              style={{ width: "25px" }}
            />
          </a>
          <a href="https:www.facebook.com/condes.luz" target="_blank" rel="noreferrer">
            <img
              src={images.facebook_white}
              alt="facebook"
              style={{ width: "25px" }}
            />
          </a>
          <a href="https://twitter.com/condesluz" target="_blank" rel="noreferrer">
            <img
              src={images.twitter_white}
              alt="twitter"
              style={{ width: "25px" }}
            />
          </a>
        </div>
      </div>
      <div className="flex flex-col space-y-8 justify-start text-white">
        <div className="flex flex-col space-y-3">
          <h2 className="text-2xl font-semibold">Nosotros</h2>
          <Link to={"/quienes-somos"}>
            <p className="text-sm">Quienes somos</p>
          </Link>
          <Link to={"/autoridades"}>
            <p className="text-sm">Autoridades</p>
          </Link>
          <Link to={"/lineas-de-investigacion"}>
            <p className="text-sm">Líneas de investigación</p>
          </Link>
        </div>
        <div className="flex flex-col space-y-3">
          <h2 className="text-2xl font-semibold">Regulaciones</h2>
          <Link to={"/normas-y-reglamentos"}>
            <p className="text-sm">Normas y reglamentos</p>
          </Link>
        </div>
        <div className="flex flex-col space-y-3">
          <h2 className="text-2xl font-semibold">Servicios</h2>
          <Link to={"/cursos"}>
            <p className="text-sm">Cursos</p>
          </Link>
          <Link to={"/congreso"}>
            <p className="text-sm">Congreso</p>
          </Link>
          <Link to={"/asesorias"}>
            <p className="text-sm">Asesorias</p>
          </Link>
          <Link to={"/solicitud-de-credenciales"}>
            <p className="text-sm">Solicitud de credenciales</p>
          </Link>
        </div>
      </div>
      <div className="flex flex-col space-y-8 justify-start text-white">
        <div className="flex flex-col space-y-3">
          <h2 className="text-2xl font-semibold">Únetenos</h2>
          <Link to={"/convenios"}>
            <p className="text-sm">Solicitud de convenio</p>
          </Link>
          <Link to={"/contactanos"}>
            <p className="text-sm">Contáctanos</p>
          </Link>
        </div>
        <div className="flex flex-col justify-center items-center space-y-3">
          <h2 className="text-2xl font-semibold">Somos parte de</h2>
          <img src={images.logo_luz} alt="LUZ" style={{ width: "100px" }} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
