import { Outlet, Navigate } from 'react-router-dom';
import { userRoles } from '../../constanst/constants';
import frontRoute from '../../resources/constants/frontend.routes';
import useAuth from './useAuth';

const PrivateAdminRoute = () => {
  const { isLogged } = useAuth();

  // useEffect(() => {
  //   verifyToken();
  // }, []);

  if (!isLogged) return <Navigate to={`${frontRoute.login}`} />;

  if (isLogged.role === userRoles.admin) return <Outlet />;

  return <Navigate to={`${frontRoute.login}`} />;
};

export default PrivateAdminRoute;
