import { Outlet, useMatch, Navigate } from 'react-router-dom';
import frontendRoutes from '../../resources/constants/frontend.routes';
import useAuth from './useAuth';

const PublicRoute = () => {
  let pathLogin = useMatch(frontendRoutes.login);
  let pathregister = useMatch(frontendRoutes.register);
  const { isLogged } = useAuth();

  if (
    isLogged &&
    isLogged?.role === 'user' &&
    pathLogin?.pathname === frontendRoutes.login
  ) {
    return <Navigate to={frontendRoutes.miCuenta} />;
  }

  if (
    isLogged &&
    isLogged?.role === 'admin' &&
    pathLogin?.pathname === frontendRoutes.login
  ) {
    return <Navigate to={frontendRoutes.miCuenta} />;
  }

  if (
    isLogged &&
    isLogged?.role === 'user' &&
    pathregister?.pathname === frontendRoutes.register
  ) {
    return <Navigate to={frontendRoutes.miCuenta} />;
  }

  if (
    isLogged &&
    isLogged?.role === 'admin' &&
    pathregister?.pathname === frontendRoutes.register
  ) {
    return <Navigate to={frontendRoutes.miCuenta} />;
  }

  return <Outlet />;
};

export default PublicRoute;
