import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validations from '../../security/schemas/formProjectsSchemas/registerProjects.schema';
import Square from '../general/Square';
import { Link } from 'react-router-dom';
import MainButton from '../general/MainButton';
import projectsApi from '../../API/projects/projects.api';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import Loader from '../general/Loader';
import ContainerModal from '../general/ContainerModal';
import ModalSelectProgram from '../general/ModalSelectProgram';

function ProyectFirstStep({ step, next }) {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations.registerProjectsStepOneSchema),
  });

  const [target, setTarget] = useState('');
  const [backendError, setBackendError] = useState('');
  const [lastDatePromotion, setLastDatePromotion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSelectProgram, setModalSelectProgram] = useState(false);

  const facultades = [
    'Facultad de Agronomía',
    'Facultad de Arquitectura y diseño',
    'Facultad Experimental de Arte',
    'Facultad Experimental de Ciencias',
    'Nucleo Costa Oriental',
    'Facultad de Ciencias Economicas y Sociales',
    'Facultad de humanidades y educación',
    'Facultad de Ingeniería',
    'Facultad de Medicina',
    'Facultad de Odontología',
    'Facultad de ciencias Jurídicas y Políticas',
    'Nucleo Punto Fijo',
    'Facultad de Ciencias Veterinaria',
    'Vicerrectorado Académico',
    'Vicerrectorado Administrativo',
  ];

  const handleSelectProgramAssociated = (id_program) => {
    setModalSelectProgram(false);
    setValue('program_associated', id_program);
  };

  const registerStepOne = async (data) => {
    if (data.faculty_core === 'default')
      return setError('faculty_core', {
        type: 'custom',
        message: 'Introduzca una facultad/núcleo.',
      });
    if (target === 'docente' && data.category === 'default')
      return setError('category', {
        type: 'custom',
        message: 'Introduzca una categoría.',
      });
    if (target === 'docente' && lastDatePromotion === null)
      return setError('last_date_promotion', {
        type: 'custom',
        message: 'Introduzca una fecha de promoción.',
      });
    if (target === 'docente' && lastDatePromotion !== null)
      data.last_date_promotion = lastDatePromotion;
    data.id_user = handleStorage.getIdUser();
    setIsLoading(true);
    const response = await projectsApi.registerNewProject(data);
    setIsLoading(false);
    if (response.data.success === false) {
      setBackendError(response.data.msg);
      return;
    }
    handleStorage.setItemLocalStorage(
      'id_new_project_condes',
      response.data.data._id
    );
    next();
  };

  return (
    <>
      {modalSelectProgram && (
        <ContainerModal closeModal={() => setModalSelectProgram(false)}>
          <ModalSelectProgram handleClick={handleSelectProgramAssociated} />
        </ContainerModal>
      )}

      <div
        className={
          step === 1
            ? 'w-full flex flex-col justify-center items-center'
            : 'hidden'
        }
      >
        <Square>
          <div className='w-10/12 flex items-center justify-between text-xl'>
            <p className='text-link'>Paso 1/5</p>
            <Link to={'/'}>
              <p className='font-bold text-red-600'>x</p>
            </Link>
          </div>

          <div className='md:w-4/5 text-sm space-y-3 md:space-x-3 md:flex md:flex-col md:justify-start md:items-start p-2'>
            <label>
              Seleccionar Programa al que pertece el proyecto (opcional).
            </label>
            <MainButton
              type='button'
              text={'Seleccionar'}
              event={() => setModalSelectProgram(true)}
            />
          </div>

          <h3 className='text-link text-center my-12'>
            IDENTIFICACIÓN DEL RESPONSABLE DEL PROYECTO
          </h3>

          {backendError.length > 0 && (
            <p className='text-red-600 text-center'>{backendError}</p>
          )}

          <form
            onSubmit={handleSubmit(registerStepOne)}
            className='form-content'
            autoComplete='off'
          >
            <div className='flex space-y-4 flex-col justify-start w-10/12'>
              <p className='px-1 text-left w-10/12'>Tipo de personal</p>
              <div className='px-1 space-y-2 md:space-y-0 flex flex-col md:flex-row md:space-x-4 justify-start w-10/12'>
                <label>
                  <input
                    onClick={() => {
                      setTarget('docente');
                    }}
                    {...register('personal_type')}
                    type='radio'
                    // name="personal"
                    value='Docente'
                  />{' '}
                  Docente
                </label>
                <label>
                  <input
                    onClick={() => {
                      setTarget('administracion');
                    }}
                    {...register('personal_type')}
                    type='radio'
                    // name="personal"
                    value='Administracion'
                  />{' '}
                  Administrativo
                </label>
                <label>
                  <input
                    onClick={() => {
                      setTarget('estudiante');
                    }}
                    {...register('personal_type')}
                    type='radio'
                    // name="personal"
                    value='Estudiante'
                  />{' '}
                  Estudiante
                </label>
              </div>
              {errors?.personal_type && (
                <p className='text-center text-red-600 w-full'>
                  {errors.personal_type.message}
                </p>
              )}
            </div>

            <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
              <input
                {...register('name')}
                type='text'
                placeholder='Nombre'
                className='rounded-full border px-4 w-full md:w-1/2'
              />
              <input
                {...register('last_name')}
                type='text'
                placeholder='Apellido'
                className='rounded-full border px-4 w-full md:w-1/2'
              />
            </div>
            {errors?.name && (
              <p className='text-center text-red-600 w-full'>
                {errors.name.message}
              </p>
            )}
            {errors?.last_name && (
              <p className='text-center text-red-600 w-full'>
                {errors.last_name.message}
              </p>
            )}

            <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
              <select
                {...register('type_identification')}
                defaultValue={'default'}
                className='rounded-full border px-4 w-full md:w-2/12'
              >
                <option value='V'>V</option>
                <option value='E'>E</option>
                <option value='J'>J</option>
              </select>

              <input
                {...register('identification')}
                type='number'
                min={1}
                step='1'
                placeholder='cedula de identidad'
                className='rounded-full border px-4 w-full md:w-10/12'
              />
            </div>
            {errors?.type_identification && (
              <p className='text-center text-red-600 w-full'>
                {errors.type_identification.message}
              </p>
            )}
            {errors?.identification && (
              <p className='text-center text-red-600 w-full'>
                {errors.identification.message}
              </p>
            )}

            <input
              {...register('profession')}
              type='text'
              placeholder='Profesión'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.profession && (
              <p className='text-center text-red-600 w-full'>
                {errors.profession.message}
              </p>
            )}

            <input
              {...register('specialty')}
              type='text'
              placeholder='Especialidad'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.specialty && (
              <p className='text-center text-red-600 w-full'>
                {errors.specialty.message}
              </p>
            )}

            <select
              {...register('faculty_core')}
              className='rounded-full border px-4 w-10/12'
            >
              <option value='default' disabled selected>
                Facultad/Núcleo
              </option>
              {facultades.map((facultad, key) => {
                return (
                  <option key={key} value={facultad}>
                    {facultad}
                  </option>
                );
              })}
            </select>
            {errors?.faculty_core && (
              <p className='text-center text-red-600 w-full'>
                {errors.faculty_core.message}
              </p>
            )}

            <select
              className={
                target === 'docente'
                  ? 'rounded-full border px-4 w-10/12'
                  : 'hidden'
              }
              {...register('category')}
            >
              <option value='default' disabled selected>
                Categoria
              </option>
              <option value='agregado'>Agregado</option>
              <option value='asistente'>Asistente</option>
              <option value='asociado'>Asociado</option>
              <option value='titular'>Titular</option>
            </select>
            {errors?.category && (
              <p className='text-center text-red-600 w-full'>
                {errors.category.message}
              </p>
            )}

            <input
              {...register('research_unit')}
              type='text'
              placeholder='Dependencia de investigación'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.research_unit && (
              <p className='text-center text-red-600 w-full'>
                {errors.research_unit.message}
              </p>
            )}

            <div
              className={
                target === 'docente'
                  ? 'flex flex-col space-y-4 w-10/12'
                  : 'hidden'
              }
            >
              <p className='px-1'>Última fecha de ascenso</p>
              <input
                type='date'
                placeholder='Fecha de ascenso'
                onChange={(e) => {
                  setLastDatePromotion(e.target.value);
                }}
                className={`${
                  target === 'docente'
                    ? 'rounded-full border px-4 w-full'
                    : 'hidden'
                }`}
                // {...register('last_date_promotion')}
              />
              {errors?.last_date_promotion && (
                <p className='text-center text-red-600 w-full'>
                  {errors.last_date_promotion.message}
                </p>
              )}
            </div>

            <input
              {...register('email')}
              type='email'
              placeholder='Email'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.email && (
              <p className='text-center text-red-600 w-full'>
                {errors.email.message}
              </p>
            )}

            <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
              <input
                {...register('cod_number')}
                type='text'
                placeholder='+ Código'
                className='rounded-full border px-4 w-full md:w-1/3'
              />
              <input
                {...register('phone')}
                type='number'
                placeholder='Número de teléfono'
                className='rounded-full border px-4 w-full md:w-2/3'
              />
            </div>
            {errors?.cod_number && (
              <p className='text-center text-red-600 w-full'>
                {errors.cod_number.message}
              </p>
            )}
            {errors?.phone && (
              <p className='text-center text-red-600 w-full'>
                {errors.phone.message}
              </p>
            )}

            <input
              {...register('dedication')}
              type='text'
              placeholder='Dedicación'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.dedication && (
              <p className='text-center text-red-600 w-full'>
                {errors.dedication.message}
              </p>
            )}

            <MainButton text={'Continuar'} />

            {/* <div
            onClick={next}
            className="bg-main-blue text-white px-6 py-2 rounded-full cursor-pointer"
          >
            Continuar
          </div> */}
          </form>
          <Loader isOpen={isLoading} />
        </Square>
      </div>
    </>
  );
}

export default ProyectFirstStep;
