import React from "react";
import images from "../../resources/images";

function AuthorityFrameAlt({ name, position }) {
  return (
    <div className="flex flex-col space-y-4 py-6 justify-start items-center">
      <div className="border border-main-blue rounded p-3">
        <img src={images.logo_luz} style={{ width: 100 }} alt="" />
      </div>
      <h3> {name} </h3>
      <h3 className="text-center"> {position} </h3>
    </div>
  );
}

export default AuthorityFrameAlt;
