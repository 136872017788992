import { Link } from 'react-router-dom';
import images from '../resources/images';

function ProgramsListElement({ title, code, status, link }) {
  return (
    <>
      <div className='flex justify-between w-full px-8'>
        <div className='flex space-x-8'>
          <div className='flex flex-col'>
            <img
              className='rounded-lg'
              width={'90px'}
              src={images.identificador_campaña}
              alt=''
            />
          </div>
          <div>
            <p>
              <span className='font-semibold'>Titulo:</span> {title}
            </p>
            <p>
              <span className='font-semibold'>Codigo de proyecto:</span> #{code}
            </p>
            <p>
              <span className='font-semibold'>Estado:</span> {status}
            </p>
          </div>
        </div>
        <div className='flex flex-col items-end justify-end '>
          <Link to={link}>
            <p className='text-link'>Ver programa</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ProgramsListElement;
