import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import * as programsApi from '../API/programs/programs.api';
import frontendRoutes from '../resources/constants/frontend.routes';
import AdminOptions from '../components/admin/AdminOptions';
import ItemProgramAdmin from '../components/admin/ItemProgramAdmin';
import MainButton from '../components/general/MainButton';
import { programsAndProjectsStatus } from '../constanst/constants';

const AdminProgramsList = () => {
  let navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [programsList, setProgramsList] = useState([]);
  const flatSearchPrograms = programsAndProjectsStatus.toBeApproved;

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    getPrograms();
  }, [page]);

  const getProgramsByFlag = async (
    flag = programsAndProjectsStatus.toBeApproved
  ) => {
    if (flag === '') return getPrograms();

    const response = await programsApi.getProgramsList(flag, page);

    setProgramsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const getPrograms = async () => {
    const response = await programsApi.getProgramsList(
      flatSearchPrograms,
      page
    );

    setProgramsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  return (
    <div className='flex flex-col justify-center items-center space-y-8 p-6'>
      <AdminOptions />
      <div className='flex justify-center items-center w-full'>
        <input
          type='text'
          className='border border-main-blue rounded-l-full py-2 px-2 w-10/12 md:w-6/12'
          placeholder='Buscar un programa...'
          onChange={() => {}}
        />
        <MainButton
          text={'Buscar'}
          classes={'rounded-l-none border border-main-blue'}
        />
      </div>
      <div className='w-full flex items-center justify-around bg-main-blue rounded-lg text-white p-2 space-x-2'>
        <p
          className='cursor-pointer'
          onClick={() =>
            getProgramsByFlag(programsAndProjectsStatus.toBeApproved)
          }
        >
          Pendientes
        </p>
        <p
          className='cursor-pointer'
          onClick={() => getProgramsByFlag(programsAndProjectsStatus.approved)}
        >
          Aprobados
        </p>
        <p
          className='cursor-pointer'
          onClick={() =>
            getProgramsByFlag(programsAndProjectsStatus.disapproved)
          }
        >
          No aprobados
        </p>
      </div>

      {programsList.length > 0 &&
        programsList.map((program) => (
          <ItemProgramAdmin
            key={program?._id}
            programId={program._id}
            programType={program.type_project}
            programCode={program.program_code}
            programAssignment={() => {}}
            redirect={() =>
              navigate(`${frontendRoutes.program}/${program._id}`)
            }
            programAssignmentTo={program.assigned_to}
            title={program.title}
            author={program.name + ' ' + program.last_name}
            creationDate={program.createdAt}
            cover='https://i.ibb.co/LPWY38D/identificador-campana.jpg'
          />
        ))}

      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
};

export default AdminProgramsList;
