import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import projectsApi from '../API/projects/projects.api';
import ContainerModal from '../components/general/ContainerModal';
import Loader from '../components/general/Loader';
import MainButton from '../components/general/MainButton';
import ModalAssignmentProjectTo from '../components/general/ModalAssignmentProjectTo';
import ModalToExplainUnApproval from '../components/general/ModalToExplainUnApproval';
import {
  commissionsRoles,
  statusProgramsAndProject,
  userRoles,
} from '../constanst/constants';
import { typesProjectsKeys } from '../resources/constants';
import images from '../resources/images';
import useAuth from '../security/auth/useAuth';
import handleStorage from '../services/localstorage/handelLocalStorage';
import { uploadFilesToGoogleDrive } from '../services/uploadFileGoogleDrive';

function Proyect() {
  const { isLogged } = useAuth();

  let params = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState({});
  const [modalUnApprovalProject, setModalUnApprovalProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalAssignmentProject, setModalAssignmentProject] = useState(false);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });
  const [isUploadingDocument, setIsUploadingDocument] = useState({
    grant_application_for_project_approval: {
      loading: false,
      msg: '',
      success: null,
    },
  });
  // start date project
  const [startDateData, setStartDateData] = useState({
    not_have_start_date: false,
    start_date_selected: '',
    reason_to_not_start: '',
  });

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    const response = await projectsApi.getProject(params.id);
    if (response.data.success) setProject(response.data.data);
  };

  const unApprovalProject = () => setModalUnApprovalProject(true);

  const handleUnApproval = (unApproval) => {
    setModalUnApprovalProject(false);
    updateApprovalProject(false, unApproval);
  };

  const updateApprovalProject = async (state, dataUnApproval = '') => {
    if (
      project?.type_project === typesProjectsKeys.financiado &&
      !project?.grant_application_for_project_approval
    ) {
      setBackendMessage({
        message: 'Projecto no puede ser aprobado sin solicitud de subvención.',
        type: true,
      });
      return;
    }

    setIsLoading(true);

    const data = {
      id: params.id,
      isApproval: state,
      object: dataUnApproval !== '' ? dataUnApproval : null,
    };

    const response = await projectsApi.updateApprovalProject(data);

    setIsLoading(false);

    if (response.data.success) {
      getProject();
      setBackendMessage({ type: false, message: response.data.message });
    }

    if (!response.data.success) {
      setBackendMessage({ type: true, message: response.data.message });
    }
  };

  const handleSendAssignmentProject = async (id_evaluator) => {
    setModalAssignmentProject(false);
    const user = handleStorage.getItemLocalStorage('user_condes');

    setIsLoading(true);

    const response = await projectsApi.assignmentProjectTo({
      id_project: params.id,
      id_assignedBy: user.id,
      id_evaluator: id_evaluator,
      role: user.role,
    });

    setIsLoading(false);

    if (response.data.success === false) {
      setBackendMessage({ message: response.data.msg, type: true });
      return;
    }

    setBackendMessage({ message: response.data.msg, type: false });
    getProject();
  };

  const checkSomeDocumentIsLoading = () =>
    Object.values(isUploadingDocument).some((loading) => loading.loading);

  const addDocument = ({ target }) => {
    if (!target.files[0]) return null;

    setIsUploadingDocument({
      ...isUploadingDocument,
      [target.name]: {
        loading: true,
        msg: 'Cargando...',
        success: false,
      },
    });

    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      const rawLog = reader.result.split(',')[1];

      const dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: 'uploadFilesToGoogleDrive',
      };

      try {
        const docUrl = await uploadFilesToGoogleDrive(dataSend);

        if (!docUrl?.url) {
          setIsUploadingDocument((_state) => ({
            ...isUploadingDocument,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        const data = {
          name: target.name,
          file: docUrl,
        };

        // update project
        const response = await projectsApi.updateProject({
          id_project: project?._id,
          grant_application_for_project_approval: data,
        });

        if (response?.data?.success === false) {
          setIsUploadingDocument((_state) => ({
            ...isUploadingDocument,
            [target.name]: {
              loading: false,
              msg: `Error al guardar documento ${file.name}, intente de nuevo.`,
              success: false,
            },
          }));

          return;
        }

        setIsUploadingDocument((_state) => ({
          ...isUploadingDocument,
          [target.name]: {
            loading: false,
            msg: `Archivo ${file.name} Guardado exitosamente.`,
            success: true,
          },
        }));

        getProject();
      } catch (error) {
        console.log('Error al subir archivo', error);
        return setIsUploadingDocument((_state) => ({
          ...isUploadingDocument,
          [target.name]: {
            loading: false,
            msg: `Error al guardar documento ${file}, intente de nuevo.`,
            success: false,
          },
        }));
      }
    };
  };

  const handleChangeStartDateProject = ({
    target: { name, value, checked },
  }) => {
    setStartDateData({
      ...startDateData,
      [name]: value,
    });
  };

  const handleUpdateStartDateProject = async () => {
    if (
      !startDateData.not_have_start_date &&
      startDateData.start_date_selected === ''
    ) {
      setBackendMessage({
        message: 'Debe ingresar una fecha de inicio.',
        type: true,
      });
      return;
    }

    if (
      startDateData.not_have_start_date &&
      startDateData.reason_to_not_start === ''
    ) {
      setBackendMessage({
        message:
          'Debe ingresar una razón por la cual no puede iniciar el proyectp.',
        type: true,
      });
      return;
    }

    setBackendMessage({
      message: '',
      type: false,
    });

    const data = {
      id_project: params.id,
      start_date_selected: startDateData.start_date_selected,
      reason_to_not_start: startDateData.reason_to_not_start,
    };

    setIsLoading(true);

    const response = await projectsApi.updateProject(data);

    setIsLoading(false);

    if (response.data.success) {
      getProject();
      setBackendMessage({ type: false, message: response.data.message });
    }

    if (!response.data.success) {
      setBackendMessage({ type: true, message: response.data.message });
    }
  };

  console.log('project ----------->', project);

  return (
    <>
      {modalUnApprovalProject && (
        <ContainerModal
          title='Especifique porque desaprueba este projecto'
          closeModal={() => setModalUnApprovalProject(false)}
        >
          <ModalToExplainUnApproval handleClick={handleUnApproval} />
        </ContainerModal>
      )}
      {modalAssignmentProject && (
        <ContainerModal
          title='Seleccion un evaluador'
          closeModal={() => setModalAssignmentProject(false)}
        >
          <ModalAssignmentProjectTo handleClick={handleSendAssignmentProject} />
        </ContainerModal>
      )}

      <div className='w-full flex flex-col items-center justify-center p-5 space-y-8'>
        <p className='text-center text-5xl text-main-blue font-extrabold'>
          {project?.title}
        </p>
        {isLoading ? (
          <Loader isOpen={isLoading} />
        ) : (
          <p className={`text-${backendMessage.type ? 'red' : 'green'}-600`}>
            {backendMessage.message}
          </p>
        )}
        <div className='w-full flex flex-col space-y-12 items-center justify-center rounded-lg lg:px-24'>
          <div className='w-full border border-main-blue relative pt-2'>
            <div className='flex flex-col space-y-4 px-4 lg:p-4'>
              <div className='hidden lg:flex'>
                <img
                  className='rounded-xl'
                  src={images?.identificador_campaña}
                  width='120px'
                  alt=''
                />
              </div>
              <div className='w-full flex flex-col space-y-2'>
                <h2 className='text-3xl text-main-blue font-bold'>
                  Metodología para proyectos
                </h2>
                <p className='break-words'>
                  <span className='text-gray-600 font-bold'>
                    investigadores:
                  </span>{' '}
                  <span>
                    {project?.name} {project?.last_name},{' '}
                  </span>
                  {project?.participants?.length > 0 &&
                    project?.participants.map((part) => (
                      <span key={part._id}>{part.name}, </span>
                    ))}
                </p>
                <p>
                  <span className='text-gray-600 font-bold'>Estatus:</span>{' '}
                  {project?.status_project}
                </p>
                {isLogged.role !== userRoles.evaluator &&
                  project?.assigned_to && (
                    <p className=''>
                      <span className='text-gray-600 font-bold'>
                        Evaluador:
                      </span>{' '}
                      {project?.assigned_to?.name}{' '}
                      {project?.assigned_to?.last_name}
                    </p>
                  )}
                <div className='flex space-x-4'>
                  {[userRoles.admin, userRoles.evaluator].includes(
                    isLogged.role
                  ) &&
                    isLogged?.id !== project?.id_user && (
                      <>
                        {project?.status_project?.toLowerCase() ===
                          'por aprobar' && (
                          <>
                            <p
                              className='text-green-600 cursor-pointer'
                              onClick={() => updateApprovalProject(true)}
                            >
                              Aprobar
                            </p>
                            <p
                              className='text-red-600 cursor-pointer'
                              onClick={() => {
                                if (project?.status_project !== 'Desaprobado') {
                                  unApprovalProject();
                                }
                              }}
                            >
                              Desaprobar
                            </p>
                          </>
                        )}
                      </>
                    )}
                  {isLogged.role === userRoles.coordinator &&
                    !project?.assigned_to &&
                    !project?.program_associated && (
                      <p
                        className='text-green-600 cursor-pointer'
                        onClick={() => setModalAssignmentProject(true)}
                      >
                        Asignar a
                      </p>
                    )}

                  {project?.program_associated && (
                    <p>
                      <span className='text-gray-600 font-bold'>
                        Proyecto asociado al programa cód:
                      </span>{' '}
                      #{project?.program_associated?.program_code}
                    </p>
                  )}

                  {project?.start_date_selected && (
                    <p>
                      <span className='text-gray-600 font-semibold'>
                        Fecha de inicio:{' '}
                      </span>
                      {new Date(
                        project?.start_date_selected
                      ).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <p
              className='absolute top-0 right-3 text-red-600 font-bold text-2xl cursor-pointer'
              onClick={() => navigate(-1)}
            >
              x
            </p>
          </div>

          {isLogged?.id === project?.id_user &&
            project?.status_project === statusProgramsAndProject.approved &&
            !project?.start_date_selected && (
              <div className='w-full border border-main-blue relative p-2'>
                <p className='text-gray-600 font-bold'>
                  Ingrese fecha de inicio:
                </p>
                <input
                  type='date'
                  placeholder='Fecha de inicio'
                  className='rounded-full border px-4 w-full'
                  disabled={startDateData.not_have_start_date}
                  value={startDateData.start_date_selected}
                  name='start_date_selected'
                  onChange={handleChangeStartDateProject}
                />
                <div className='mt-3 space-y-3 rounded-md'>
                  <div className='flex items-center space-x-3'>
                    <label htmlFor='start_project_yes'>
                      No tengo fecha de inicio:
                    </label>
                    <input
                      type='checkbox'
                      name='not_have_start_date'
                      id='start_project_yes'
                      value={startDateData.not_have_start_date}
                      onChange={(e) => {
                        setStartDateData({
                          ...startDateData,
                          not_have_start_date: e.target.checked,
                          start_date_selected: '',
                          reason_to_not_start: '',
                        });
                      }}
                    />
                  </div>
                  {startDateData.not_have_start_date && (
                    <textarea
                      className='w-full p-3 resize-none border'
                      cols='30'
                      rows='10'
                      placeholder='Motivo por el cual no puede iniciar el proyecto. '
                      value={startDateData.reason_to_not_start}
                      name='reason_to_not_start'
                      onChange={handleChangeStartDateProject}
                    ></textarea>
                  )}
                </div>
                <MainButton
                  type={'button'}
                  text={'Guardar'}
                  classes={'w-32 mt-2'}
                  event={handleUpdateStartDateProject}
                />
              </div>
            )}

          <div className='flex flex-col lg:flex-row space-y-8 lg:space-y-0 w-full items-start justify-between'>
            <div className='flex flex-col space-y-8 w-full lg:w-5/12'>
              <div className='flex flex-col space-y-4 border border-main-blue p-4'>
                <h2 className='text-2xl text-main-blue font-bold'>
                  Identificación
                </h2>
                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>
                    Tipo de investigación
                  </h3>
                  <p>{project?.type_research}</p>
                </div>
                {/* <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>Area del proyecto</h3>
                  <p>{project?.project_area}</p>
                </div> */}
                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>
                    Comisión a la que pertenece
                  </h3>
                  <p className='capitalize'>
                    {commissionsRoles[project?.commissionsRole]}
                  </p>
                </div>
                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>
                    Línea de investigacion
                  </h3>
                  <p>{project?.line_research}</p>
                </div>
                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>Tipo de proyecto</h3>
                  <p>{project?.type_project}</p>
                </div>

                <div className='flex flex-col space-y-1 border'>
                  <h3 className='text-gray-600 font-bold'>
                    Solicitud de subvención
                  </h3>
                  {isLogged?.id === project?.id_user &&
                  project?.type_project === 'financiado' &&
                  !project?.grant_application_for_project_approval ? (
                    <>
                      <input
                        className='w-4/5 md:w-1/2'
                        accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                        type='file'
                        name='grant_application_for_project_approval'
                        onChange={addDocument}
                        disabled={checkSomeDocumentIsLoading()}
                      />
                      {isUploadingDocument
                        .grant_application_for_project_approval.success !==
                        null && (
                        <p className='text-green-600'>
                          {
                            isUploadingDocument
                              .grant_application_for_project_approval.msg
                          }
                        </p>
                      )}
                    </>
                  ) : project?.type_project === 'financiado' &&
                    !project?.grant_application_for_project_approval ? (
                    <p>Proyecto no posee solicitud de subvención</p>
                  ) : (
                    <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
                      <img src={images.folder} style={{ width: 65 }} alt='' />
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={
                          project?.grant_application_for_project_approval?.file
                            ?.url
                        }
                      >
                        <p className='text-link'>Solicitud de subvención</p>
                      </a>
                    </div>
                  )}
                </div>

                <div className='flex flex-col space-y-1'>
                  <h3 className='text-gray-600 font-bold'>
                    Código del proyecto
                  </h3>
                  <p>#{project?.project_code}</p>
                </div>

                {project?.program_code && (
                  <div className='flex flex-col space-y-1'>
                    <h3 className='text-gray-600 font-bold'>
                      Código del programa
                    </h3>
                    <p>#{project?.program_code}</p>
                  </div>
                )}
              </div>

              <div className='flex flex-col space-y-4 border border-main-blue p-4 break-words'>
                <h2 className='text-2xl text-main-blue font-bold'>
                  Investigador principal
                </h2>
                <div className='flex flex-col space-y-2'>
                  <p>
                    <span className='text-gray-600 font-semibold'>
                      Nombre:{' '}
                    </span>
                    {project?.name}
                  </p>
                  <p>
                    <span className='text-gray-600 font-semibold'>
                      Apellido:{' '}
                    </span>
                    {project?.last_name}
                  </p>
                </div>
                <p>
                  <span className='text-gray-600 font-semibold'>Cédula: </span>
                  {String(project?.type_identification).toUpperCase()}-
                  {project?.identification}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Profesión:{' '}
                  </span>
                  {project?.profession}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Especialidad:{' '}
                  </span>
                  {project?.specialty}
                </p>
                {/* <p>
                  <span className="text-gray-600 font-semibold">
                    Institución:{' '}
                  </span>
                  {project?.institution}
                </p> */}
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Ente de investigación:{' '}
                  </span>
                  {project?.research_unit}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>Email: </span>
                  {project?.email}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Teléfono:{' '}
                  </span>
                  {project?.cod_number}-{project?.phone}
                </p>
                <p>
                  <span className='text-gray-600 font-semibold'>
                    Facultad/Núcleo:{' '}
                  </span>
                  {project?.faculty_core}
                </p>
              </div>
              <div className='flex flex-col space-y-4 border border-main-blue p-4'>
                <h2 className='text-2xl text-main-blue font-bold'>
                  Participantes
                </h2>
                {project?.participants?.length > 0 &&
                  project?.participants.map((participant, index) => (
                    <div key={participant?._id}>
                      <h3 className='text-gray-600 font-bold'>
                        {participant?.category}
                      </h3>
                      <div className='flex space-x-14'>
                        <p>
                          <span className='text-gray-600 font-semibold'>
                            Nombre:{' '}
                          </span>
                          {participant?.name}
                        </p>
                        <p>
                          <span className='text-gray-600 font-semibold'>
                            Apellido:{' '}
                          </span>
                          {participant?.last_name}
                        </p>
                      </div>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Cédula:{' '}
                        </span>
                        {String(participant?.type_identification).toUpperCase()}
                        -{participant?.identification}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Profesión:{' '}
                        </span>
                        {participant?.profession}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Especialidad:{' '}
                        </span>
                        {participant?.specialty}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Institución:{' '}
                        </span>
                        {participant?.institution}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Ente de investigación:{' '}
                        </span>
                        {participant?.research_unit}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Email:{' '}
                        </span>
                        {participant?.email}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Teléfono:{' '}
                        </span>
                        {participant?.cod_number}-{participant?.phone}
                      </p>
                      <p>
                        <span className='text-gray-600 font-semibold'>
                          Facultad/Núcleo:{' '}
                        </span>
                        {participant?.faculty_core}
                      </p>
                      {participant?.curriculum_vitae_teaching_staff && (
                        <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
                          <img
                            src={images.folder}
                            style={{ width: 65 }}
                            alt=''
                          />
                          <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={
                              participant?.curriculum_vitae_teaching_staff?.file
                                ?.url
                            }
                          >
                            <p className='text-link'>Curriculum Vitae</p>
                          </a>
                        </div>
                      )}
                      {participant?.letters_dedication_personnel_hours && (
                        <div className='flex flex-col justify-center items-center md:items-start space-y-4'>
                          <img
                            src={images.folder}
                            style={{ width: 65 }}
                            alt=''
                          />
                          <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={
                              participant?.letters_dedication_personnel_hours
                                ?.file?.url
                            }
                          >
                            <p className='text-link'>
                              Carta de horas de dedicación
                            </p>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className='flex flex-col space-y-4 border border-main-blue p-4 w-full lg:w-5/12'>
              <h2 className='text-2xl text-main-blue font-bold'>Descripción</h2>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Descripción del proyecto
                </h3>
                <p>{project?.project_summary}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>Objetivo general</h3>
                <p>{project?.general_objective}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Objetivos específicos
                </h3>
                <p>{project?.specific_objectives}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>Hipótesis</h3>
                <p>{project?.hypothesis}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Metodología a utilizar
                </h3>
                <p>{project?.methodology_used}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Viabilidad y aplicabilidad de los resultados
                </h3>
                <p>{project?.feasibility_research}</p>
              </div>
              <div className='flex flex-col space-y-1'>
                <h3 className='text-gray-600 font-bold'>
                  Cronograma de actividades
                </h3>
                <a
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={project?.chronogram_activities?.file?.url}
                >
                  <img src={images.folder} style={{ width: 65 }} alt='' />
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-col space-y-8 border border-main-blue w-full p-4'>
            <h2 className='text-2xl text-main-blue font-bold'>Documentos</h2>
            <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-6'>
              {project?.documents?.length > 0 &&
                project?.documents.map((document) => (
                  <div
                    key={document._id}
                    className='flex flex-col justify-center items-center space-y-4'
                  >
                    <img src={images.folder} style={{ width: 65 }} alt='' />
                    <a
                      target={'_blank'}
                      rel={'noreferrer'}
                      href={document?.file?.url}
                    >
                      <p className='text-link'>
                        {document?.name === 'faculty_council_approval'
                          ? 'Aprobación del consejo de profesores'
                          : document?.name === 'technical_board_approval'
                          ? 'Aprobación del consejo técnico'
                          : document?.name === 'curriculum_vitae_teaching_staff'
                          ? 'Curriculum vitae personal docente'
                          : document?.name === 'letters_dedication_personnel'
                          ? 'Cartas de dedicación del personal'
                          : ''}
                      </p>
                    </a>
                  </div>
                ))}
            </div>
          </div>

          {isLogged?.id === project?.id_user && (
            <div className='flex flex-col space-y-8 border border-main-blue w-full p-4'>
              <h2 className='text-2xl text-main-blue font-bold'>Formatos</h2>
              <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-6'>
                {project?.formats && (
                  <>
                    <div className='flex flex-col justify-center items-center space-y-4'>
                      <img src={images.folder} style={{ width: 65 }} alt='' />
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={
                          project?.formats
                            ?.grant_application_for_project_approval?.file?.url
                        }
                      >
                        <p className='text-link'>Solicitud de subvención</p>
                      </a>
                    </div>
                    <div className='flex flex-col justify-center items-center space-y-4'>
                      <img src={images.folder} style={{ width: 65 }} alt='' />
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={
                          project?.formats?.progress_report_eight_months?.file
                            ?.url
                        }
                      >
                        <p className='text-link'>
                          Informe de avance ocho(8) meses.
                        </p>
                      </a>
                    </div>
                    <div className='flex flex-col justify-center items-center space-y-4'>
                      <img src={images.folder} style={{ width: 65 }} alt='' />
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={
                          project?.formats?.progress_report_eighteen_months
                            ?.file?.url
                        }
                      >
                        <p className='text-link'>
                          Informe de avance ocho(8) meses.
                        </p>
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Proyect;
