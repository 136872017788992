import axios from 'axios';
import routes from '../../resources/constants/backend.routes';

const sendContactUsMessage = async (data) =>
  await axios.post(`${routes.base_url}/public/send-contact`, data);

const sendCredencialsProyects = async (data) =>
  await axios.post(`${routes.base_url}/public/send-credencials-proyect`, data);

const sendCredencials = async (data) =>
  await axios.post(`${routes.base_url}/public/send-credencials`, data);

const sendAgreements = async (data) =>
  await axios.post(`${routes.base_url}/public/send-agreements`, data);

const recoverPassword = async (data) =>
  await axios.post(`${routes.base_url}/public/recover-password`, data);

const publicApi = {
  sendContactUsMessage,
  sendCredencialsProyects,
  sendCredencials,
  sendAgreements,
  recoverPassword,
};

export default publicApi;
