import axios from 'axios';
import { headers } from '../index';
import routes from '../../resources/constants/backend.routes';

const createNews = async (data) =>
  await axios.post(`${routes.base_url}/admin/create-news`, data, {
    headers: headers(),
  });

const getNews = async () =>
  await axios.get(`${routes.base_url}/admin/get-all-news`, {
    headers: headers(),
  });

const getNewsByPagination = async (skip = 1) =>
  await axios.get(`${routes.base_url}/admin/get-news-pagination?skip=${skip}`, {
    headers: headers(),
  });

const editNews = async (data) =>
  await axios.put(`${routes.base_url}/admin/update-news`, data, {
    headers: headers(),
  });

const getNewFrombe = async (id) =>
  await axios.get(`${routes.base_url}/admin/get-mews-by-id?id=${id}`, {
    headers: headers(),
  });

const searchNews = async (query) =>
  await axios.get(`${routes.base_url}/admin/search-news?query=${query}`, {
    headers: headers(),
  });

const deleteNew = async (id) =>
  await axios.delete(`${routes.base_url}/admin/delete-news?id=${id}`, {
    headers: headers(),
  });

const newsApi = {
  createNews,
  getNews,
  getNewsByPagination,
  editNews,
  getNewFrombe,
  searchNews,
  deleteNew,
};

export default newsApi;
