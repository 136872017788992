import * as yup from 'yup';

const contactUsSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Introduzca un nombre valido.')
    .required('Nombre es requerido.'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Introduzca un apellido valido.')
    .required('Apellido es requerido.'),
  email: yup
    .string()
    .email('Introduzca un correo valido.')
    .required('Correo es requerido.'),
  country: yup.string().required('Un pais es requerido.'),
  cod_number: yup
    .string()
    .matches(/^[+0-9]+$/, 'código debe ser un formato correcto.')
    .required('codigo de teléfono es requerido.'),
  phone: yup
    .number('Ingrese un número valido')
    .typeError('Ingrese un número valido')
    .required('número es requerido.'),
});

export default contactUsSchema;
