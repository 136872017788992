import React from "react";

function MidTittle(props) {
  return (
    <>
      <div className="w-full flex flex-row items-center justify-between py-8">
        <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
        <h2
          className={
            props.classes
              ? "w-6/12 text-main-blue text-center font-bold " + props.classes
              : "w-6/12 text-4xl text-main-blue text-center font-bold"
          }
        >
          {props.title}
          {props.children}
        </h2>
        <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
      </div>
    </>
  );
}

export default MidTittle;
