// generals
const setItemLocalStorage = (name_item, data) => {
  window.localStorage.setItem(name_item, JSON.stringify(data));
};

const getItemLocalStorage = (name_item) =>
  JSON.parse(window.localStorage.getItem(name_item));

const clearItemLocalStorage = (name) => {
  window.localStorage.removeItem(name);
  return true;
};

// auth
const getTokenLocal = (name) => {
  const userData = getItemLocalStorage(name);
  if (!userData) return null;
  return userData.token;
};

const getRoleLocal = (name) => {
  const userData = getItemLocalStorage(name);
  if (!userData) return null;
  return userData.role;
};

const getNameUser = (name = 'user_condes') => {
  const userData = getItemLocalStorage(name);
  if (!userData) return null;

  const user = {
    name: userData.name,
    last_name: userData.last_name,
    email: userData.email,
  };
  return user;
};

const getIdUser = (name = 'user_condes') => {
  const userData = getItemLocalStorage(name);
  if (!userData) return null;

  return userData.id;
};

const handleStorage = {
  setItemLocalStorage,
  getItemLocalStorage,
  getTokenLocal,
  getRoleLocal,
  clearItemLocalStorage,
  getNameUser,
  getIdUser,
};

export default handleStorage;
