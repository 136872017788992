import * as yup from 'yup';
import { commissionsRoles } from '../../../constanst/constants';

export const registerNewProgramStepOneSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Introduzca un Nombre valido.')
    .required('Nombre es requerido.'),
  last_name: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Introduzca un apellido valido.')
    .required('Apellido es requerido.'),
  type_identification: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü ]+$/, 'Introduzca un tipo de documento.')
    .typeError('Introduzca un tipo de documento.')
    .required('Tipo de documento es requerido.'),
  identification: yup
    .number('Ingrese una identificación valida.')
    .typeError('Ingrese una identificación valiida.')
    .required('Identificación es requerida.'),
  email: yup
    .string()
    .email('Introduzca un correo valido.')
    .required('Correo es requerido.'),
  cod_number: yup
    .string()
    .matches(/^[+0-9]+$/, 'código debe ser un formato correcto.')
    .required('codigo de teléfono es requerido.'),
  phone: yup
    .number('Ingrese un número valido')
    .typeError('Ingrese un número valido')
    .required('número es requerido.'),
  line_research: yup
    .string()
    .matches(
      /^[a-zA-Zá-üÁ-Ü , ]+$/,
      'Introduzca una línea de investigación valida.'
    )
    .required('Línea de investigación es requerido.'),
  commissionsRole: yup
    .string()
    .equals(
      Object.keys(commissionsRoles),
      'Debe escoger a que comisión pertenecerá el usuario.'
    )
    .required('Comisión del usuario es requerido'),
  general_objective: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü;,.0-9 ]+$/, 'Introduzca un objetivo general.')
    .required('Objetivo general es requerido.'),
  title: yup
    .string()
    .matches(/^[a-zA-Zá-üÁ-Ü;,.0-9 ]+$/, 'Introduzca un Título valido.')
    .required('Título es requerido.'),
});
