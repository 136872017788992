const routes = {
  // publicas
  home: '/',
  login: '/acceder',
  register: '/registro',
  contactanos: '/contactanos',
  recuperarContrasena: '/recuperar-contrasena',
  cursos: '/cursos',
  curso: '/curso',
  consignacionDeCredenciales: '/solicitud-de-credenciales',
  quienesSomos: '/quienes-somos',
  eventos: '/eventos',
  eventosEvento: '/eventos/evento',
  noticias: '/noticias',
  noticiasNoticia: '/noticias/noticia',
  autoridades: '/autoridades',
  lineasDeInvestigacion: '/lineas-de-investigacion',
  normasYReglamentos: '/normas-y-reglamentos',
  congreso: '/congreso',
  asesorias: '/asesorias',
  convenios: '/convenios',
  salir: '/salir',
  rest: '*',

  // Proyectos
  registrarProyectos: '/registrar-proyectos',
  Proyecto: '/proyecto',

  // private
  panel: '/panel',
  panelListaDeUsuarios: '/panel/lista-de-usuarios',
  panelListaDeUsuariosEditarUsuario: '/panel/lista-de-usuarios/editar',
  panelAdminCrearNuevoUsuario: '/panel/lista-de-usuarios/crear-nuevo-usuario',
  panelListaDeProyectos: '/panel/lista-de-proyectos',
  panelListaDeProyectosEditarProyecto: '/panel/lista-de-proyectos/editar',
  panelListaDeEventos: '/panel/lista-de-eventos',
  panelListaDeEventosAgregar: '/panel/lista-de-eventos/agregar',
  panelListaDeEventosEditarEvento: '/panel/lista-de-eventos/editar',
  panelListaDeCursos: '/panel/lista-de-cursos',
  panelListaDeCursosAgregar: '/panel/lista-de-cursos/agregar',
  panelListaDeCursosEditarCurso: '/panel/lista-de-cursos/editar',
  panelListaDeNoticias: '/panel/lista-de-noticias',
  panelListaDeNoticiasEditar: '/panel/lista-de-noticias/editar',
  panelListaDeNoticiasAgregar: '/panel/lista-de-noticias/agregar',
  miCuenta: '/mi-cuenta',
  proyectosByRole: '/proyectos',
  misProyectos: '/mis-proyectos',
  panelAdminDocumentsProjects: "/panel/lista-documentos-proyectos",

  // programs
  registrarProgramas: '/registrar-programas',
  misProgramas: '/mis-programas',
  program: '/programa',
  panelListPrograms: '/panel/lista-de-programas',
  programsByRole: '/programas',
};

export default routes;
