import React from 'react';
import GridArticle from '../GridArticle';
import MidTittle from './MidTittle';

const ContentGrid = ({ title, data, handleClick }) => {
  return (
    <section className="bg-path bg-center bg-no-repeat">
      <div className="md:h-90%-vh md:min-h-800 flex items-center justify-center flex-col">
        <MidTittle title={title} />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-4 md:px-3 text-center">
          {data.map((item) => (
            <GridArticle
              key={item._id}
              handleClick={() => handleClick(item._id)}
              img={item.pathImage}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContentGrid;
