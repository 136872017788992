import { useEffect, useState } from 'react';
import Paginate from 'react-paginate';
import projectsApi from '../API/projects/projects.api';
import AdminOptions from '../components/admin/AdminOptions';
import ElementList from '../components/admin/ElementList';
import ItemProjectAssignment from '../components/admin/ItemProjectAssignment';
import ContainerModal from '../components/general/ContainerModal';
import Loader from '../components/general/Loader';
import MainButton from '../components/general/MainButton';
import ModalAssignmentProjectTo from '../components/general/ModalAssignmentProjectTo';
import ModalToExplainUnApproval from '../components/general/ModalToExplainUnApproval';
import { userRoles } from '../constanst/constants';
import { flagsToGetProjects } from '../resources/constants';
import useAuth from '../security/auth/useAuth';
import handleStorage from '../services/localstorage/handelLocalStorage';

function ProjectsListByRole() {
  const { isLogged } = useAuth();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [projectsList, setProjectsList] = useState([]);
  const flatSearchProjects = 'por aprobar';

  const [isLoading, setIsLoading] = useState(false);
  const [modalAssignmentProject, setModalAssignmentProject] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getProjects();
  }, [page]);

  const getProgramsByFlag = async (flag = 'por aprobar') => {
    if (flag === '') return getProjects();

    const res = await projectsApi.getAllProjects(page, flag);
    setProjectsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const getProjects = async (flag = flagsToGetProjects.toBeAssigned) => {
    setIsLoading(true);

    const user = await handleStorage.getItemLocalStorage('user_condes');

    const response =
      await projectsApi.getProjectsByCommissionsRoleWithoutAssignment({
        id_user: user.id,
        role: user.role,
        commissionsRole: user.commissionsRole,
        skip: page,
        flag,
      });

    setIsLoading(false);

    setProjectsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  const handleAssignmentProject = (id_project) => {
    setModalAssignmentProject(true);
    setProjectId(id_project);
  };

  const handleSendAssignmentProject = async (id_evaluator) => {
    setModalAssignmentProject(false);
    const user = handleStorage.getItemLocalStorage('user_condes');

    setIsLoading(true);

    const response = await projectsApi.assignmentProjectTo({
      id_project: projectId,
      id_assignedBy: user.id,
      id_evaluator: id_evaluator,
      role: user.role,
    });

    setIsLoading(false);

    if (response.data.success === false) {
      setBackendMessage({ message: response.data.msg, type: true });
      return;
    }

    setBackendMessage({ message: response.data.msg, type: false });
    getProjects();
  };

  return (
    <>
      {modalAssignmentProject && (
        <ContainerModal
          title='Seleccion un evaluador'
          closeModal={() => setModalAssignmentProject(false)}
        >
          <ModalAssignmentProjectTo handleClick={handleSendAssignmentProject} />
        </ContainerModal>
      )}
      <div className='flex flex-col justify-center items-center space-y-8 p-6'>
        <h1 className='text-4xl font-bold text-main-blue'>Proyetos</h1>

        {backendMessage.message !== '' && (
          <p
            className={`text-center text-${
              backendMessage.type ? 'red' : 'green'
            }-600`}
          >
            {backendMessage.message}
          </p>
        )}

        {/* <div className='flex w-full items-start justify-around bg-main-blue rounded-lg text-white p-2 space-x-4'>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('por aprobar')}
          >
            Pendientes
          </p>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('aprobado')}
          >
            Aprobados
          </p>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('desaprobado')}
          >
            No aprobados
          </p>
        </div> */}

        {isLogged.role === userRoles.coordinator && (
          <div className='flex w-full items-start justify-around bg-main-blue rounded-lg text-white p-2 space-x-4'>
            <p
              className='cursor-pointer'
              onClick={() => getProjects(flagsToGetProjects.toBeAssigned)}
            >
              Proyectos
            </p>
            <p
              className='cursor-pointer'
              onClick={() => getProjects(flagsToGetProjects.assigned)}
            >
              Asignados
            </p>
          </div>
        )}

        {projectsList.length > 0 && (
          <div className='w-full grid grid-rows-1 border border-main-blue rounded-lg'>
            {projectsList.map((project) => (
              <ItemProjectAssignment
                key={project._id}
                projectId={project._id}
                projectType={project.type_project}
                projectCode={project.program_code}
                projectAssignment={() => handleAssignmentProject(project._id)}
                projectAssignmentTo={project.assigned_to}
                title={project.title}
                author={project.name}
                creationDate={project.createdAt}
                cover='https://i.ibb.co/LPWY38D/identificador-campana.jpg'
              />
            ))}
          </div>
        )}

        <Loader isOpen={isLoading} />

        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={'m-8 flex items-center justify-center'}
          previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
          nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
          pageClassName={'border border-main-blue px-4 py-1'}
          breakClassName={'border border-main-blue px-4 py-1'}
          activeClassName={'text-indigo-600'}
        />
      </div>
    </>
  );
}

export default ProjectsListByRole;
