import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerNewProgramStepOne } from '../../API/programs/programs.api';
import { registerNewProgramStepOneSchema } from '../../security/schemas/formProjectsSchemas/registerProgramSchema';
import Loader from '../general/Loader';
import MainButton from '../general/MainButton';
import Square from '../general/Square';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import linesOfInvestigation from '../../resources/js/linesOfInvestigation.json';
import { commissionsRoles } from '../../constanst/constants';

const ProgramFirstStep = ({ step }) => {
  const {
    register,
    watch,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerNewProgramStepOneSchema),
  });
  const watchTypeProgram = watch('type_program', 'none');

  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSendRegisterNewProgram = async (data) => {
    const user_id = handleStorage.getIdUser();

    if (data.type_program === 'none') {
      return setError('type_program', {
        type: 'custom',
        message: 'Introduzca un tipo de proyecto valido.',
      });
    }

    if (data.line_research === 'none') {
      return setError('line_research', {
        type: 'custom',
        message: 'Introduzca una línea de investigación.',
      });
    }

    if (watchTypeProgram === 'financiado') {
      // * personal_payment_request

      if (isNaN(data.personal_payment_request_bsd))
        return setError('personal_payment_request_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.personal_payment_request_dollars))
        return setError('personal_payment_request_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.personal_payment_request = {
        personal_payment_request_bsd: data.personal_payment_request_bsd,
        personal_payment_request_dollars: data.personal_payment_request_dollars,
      };

      delete data.personal_payment_request_bsd;
      delete data.personal_payment_request_dollars;

      // * equipment_purchase_request

      if (isNaN(data.equipment_purchase_request_bsd))
        return setError('equipment_purchase_request_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.equipment_purchase_request_dollars))
        return setError('equipment_purchase_request_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.equipment_purchase_request = {
        equipment_purchase_request_bsd: data.equipment_purchase_request_bsd,
        equipment_purchase_request_dollars:
          data.equipment_purchase_request_dollars,
      };

      delete data.equipment_purchase_request_bsd;
      delete data.equipment_purchase_request_dollars;

      // * request_payment_materialsAndSupplies

      if (isNaN(data.request_payment_materialsAndSupplies_bsd))
        return setError('request_payment_materialsAndSupplies_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_payment_materialsAndSupplies_dollars))
        return setError('request_payment_materialsAndSupplies_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_payment_materialsAndSupplies = {
        request_payment_materialsAndSupplies_bsd:
          data.request_payment_materialsAndSupplies_bsd,
        request_payment_materialsAndSupplies_dollars:
          data.request_payment_materialsAndSupplies_dollars,
      };

      delete data.request_payment_materialsAndSupplies_bsd;
      delete data.request_payment_materialsAndSupplies_dollars;

      // * request_tickets_travels

      if (isNaN(data.request_tickets_travels_bsd))
        return setError('request_tickets_travels_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_tickets_travels_dollars))
        return setError('request_tickets_travels_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_tickets_travels = {
        request_tickets_travels_bsd: data.request_tickets_travels_bsd,
        request_tickets_travels_dollars: data.request_tickets_travels_dollars,
      };

      delete data.request_tickets_travels_bsd;
      delete data.request_tickets_travels_dollars;

      // * request_documents_amount

      if (isNaN(data.request_documents_amount_bsd))
        return setError('request_documents_amount_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_documents_amount_dollars))
        return setError('request_documents_amount_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_documents_amount = {
        request_documents_amount_bsd: data.request_documents_amount_bsd,
        request_documents_amount_dollars: data.request_documents_amount_dollars,
      };

      delete data.request_documents_amount_bsd;
      delete data.request_documents_amount_dollars;

      // * request_visibility_accessibility
      if (isNaN(data.request_visibility_accessibility_bsd))
        return setError('request_visibility_accessibility_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_visibility_accessibility_dollars))
        return setError('request_visibility_accessibility_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_visibility_accessibility = {
        request_visibility_accessibility_bsd:
          data.request_visibility_accessibility_bsd,
        request_visibility_accessibility_dollars:
          data.request_visibility_accessibility_dollars,
      };

      delete data.request_visibility_accessibility_bsd;
      delete data.request_visibility_accessibility_dollars;

      // * request_in_other
      if (isNaN(data.request_in_other_bsd))
        return setError('request_in_other_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_in_other_dollars))
        return setError('request_in_other_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_in_other = {
        request_in_other_bsd: data.request_in_other_bsd,
        request_in_other_dollars: data.request_in_other_dollars,
      };

      delete data.request_in_other_bsd;
      delete data.request_in_other_dollars;
    } else {
      // data.personal_payment_request = null;
      delete data.personal_payment_request_bsd;
      delete data.personal_payment_request_dollars;
      // // * equipment_purchase_request
      // data.equipment_purchase_request = null;
      delete data.equipment_purchase_request_bsd;
      delete data.equipment_purchase_request_dollars;
      // // * request_payment_materialsAndSupplies
      // data.request_payment_materialsAndSupplies = null;
      delete data.request_payment_materialsAndSupplies_bsd;
      delete data.request_payment_materialsAndSupplies_dollars;

      delete data.request_tickets_travels_bsd;
      delete data.request_tickets_travels_dollars;
      // // * request_documents_amount
      // data.request_documents_amount = null;
      delete data.request_documents_amount_bsd;
      delete data.request_documents_amount_dollars;
      // // * request_visibility_accessibility
      // data.request_visibility_accessibility = null;
      delete data.request_visibility_accessibility_bsd;
      delete data.request_visibility_accessibility_dollars;
      // // * request_in_other
      // data.request_in_other = null;
      delete data.request_in_other_bsd;
      delete data.request_in_other_dollars;
    }

    data.created_by = user_id;

    setIsLoading(true);

    const response = await registerNewProgramStepOne(data);

    setIsLoading(false);

    if (response.data.success === false) {
      setBackendMessage({ message: response.data.msg, type: false });
      return;
    }

    setBackendMessage({ message: response.data.msg, type: true });

    reset();
  };

  return (
    <div
      className={
        step === 1
          ? 'w-full flex flex-col justify-center items-center'
          : 'hidden'
      }
    >
      <Square>
        <div className='w-10/12 flex items-center justify-end text-xl'>
          <Link to={'/'}>
            <p className='font-bold text-red-600'>x</p>
          </Link>
        </div>
        <h3 className='text-link text-center my-12'>
          INVESTIGADOR RESPONSABLE DEL PROGRAMA
        </h3>

        {backendMessage.message !== '' && (
          <p
            className={`text-${
              !backendMessage.type ? 'red' : 'green'
            }-600 text-center`}
          >
            {backendMessage.message}
          </p>
        )}

        <form
          onSubmit={handleSubmit(handleSendRegisterNewProgram)}
          className='form-content'
          autoComplete='off'
        >
          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <input
              {...register('name')}
              type='text'
              placeholder='Nombre'
              className='rounded-full border px-4 w-full md:w-1/2'
            />
            <input
              {...register('last_name')}
              type='text'
              placeholder='Apellido'
              className='rounded-full border px-4 w-full md:w-1/2'
            />
          </div>
          {errors?.name && (
            <p className='text-center text-red-600 w-full'>
              {errors.name.message}
            </p>
          )}
          {errors?.last_name && (
            <p className='text-center text-red-600 w-full'>
              {errors.last_name.message}
            </p>
          )}

          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <select
              {...register('type_identification')}
              defaultValue={'default'}
              className='rounded-full border px-4 w-full md:w-2/12'
            >
              <option value='V'>V</option>
              <option value='E'>E</option>
              <option value='J'>J</option>
            </select>

            <input
              {...register('identification')}
              type='number'
              min={1}
              step='1'
              placeholder='cedula de identidad'
              className='rounded-full border px-4 w-full md:w-10/12'
            />
          </div>
          {errors?.type_identification && (
            <p className='text-center text-red-600 w-full'>
              {errors.type_identification.message}
            </p>
          )}
          {errors?.identification && (
            <p className='text-center text-red-600 w-full'>
              {errors.identification.message}
            </p>
          )}

          <input
            {...register('email')}
            type='email'
            placeholder='Email'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.email && (
            <p className='text-center text-red-600 w-full'>
              {errors.email.message}
            </p>
          )}

          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <input
              {...register('cod_number')}
              type='text'
              placeholder='+ Código'
              className='rounded-full border px-4 w-full md:w-1/3'
            />
            <input
              {...register('phone')}
              type='number'
              placeholder='Número de teléfono'
              className='rounded-full border px-4 w-full md:w-2/3'
            />
          </div>
          {errors?.cod_number && (
            <p className='text-center text-red-600 w-full'>
              {errors.cod_number.message}
            </p>
          )}
          {errors?.phone && (
            <p className='text-center text-red-600 w-full'>
              {errors.phone.message}
            </p>
          )}

          <input
            {...register('title')}
            type='text'
            placeholder='Titulo del programa'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.title && (
            <p className='text-center text-red-600 w-full'>
              {errors.title.message}
            </p>
          )}

          <select
            {...register('type_program')}
            className='rounded-full border px-4 w-10/12'
            defaultValue={'none'}
          >
            <option value='none' disabled>
              Tipo de programa
            </option>
            <option value='no financiado'>No financiado</option>
            <option value='financiado'>Financiado</option>
            <option value='asociado'>Asociado</option>
          </select>
          {errors?.type_program && (
            <p className='text-center text-red-600 w-full'>
              {errors.type_program.message}
            </p>
          )}

          {watchTypeProgram === 'financiado' && (
            <>
              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud para pago en personal</p>
                <input
                  {...register('personal_payment_request_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('personal_payment_request_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.personal_payment_request_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.personal_payment_request_bsd.message}
                </p>
              )}
              {errors?.personal_payment_request_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.personal_payment_request_dollars.message}
                </p>
              )}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud de monto para adquisición de equipos</p>
                <input
                  {...register('equipment_purchase_request_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('equipment_purchase_request_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.equipment_purchase_request_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.equipment_purchase_request_bsd.message}
                </p>
              )}
              {errors?.equipment_purchase_request_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.equipment_purchase_request_dollars.message}
                </p>
              )}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>
                  Solicitud de monto para adquisición de pago en materiales y
                  suministros
                </p>
                <input
                  {...register('request_payment_materialsAndSupplies_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('request_payment_materialsAndSupplies_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.request_payment_materialsAndSupplies_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_payment_materialsAndSupplies_bsd.message}
                </p>
              )}
              {errors?.request_payment_materialsAndSupplies_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_payment_materialsAndSupplies_dollars.message}
                </p>
              )}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud de monto para pasajes y viaticos</p>
                <input
                  {...register('request_tickets_travels_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('request_tickets_travels_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.request_tickets_travels_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_tickets_travels_bsd.message}
                </p>
              )}
              {errors?.request_tickets_travels_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_tickets_travels_dollars.message}
                </p>
              )}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud de monto de documentación</p>
                <input
                  {...register('request_documents_amount_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('request_documents_amount_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.request_documents_amount_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_documents_amount_bsd.message}
                </p>
              )}
              {errors?.request_documents_amount_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_documents_amount_dollars.message}
                </p>
              )}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud de monto en visibilidad y accesibilidad</p>
                <input
                  {...register('request_visibility_accessibility_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('request_visibility_accessibility_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.request_visibility_accessibility_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_visibility_accessibility_bsd.message}
                </p>
              )}
              {errors?.request_visibility_accessibility_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_visibility_accessibility_dollars.message}
                </p>
              )}

              {/* request_in_other */}

              <div className={'flex space-y-2 flex-col justify-start w-10/12'}>
                <p>Solicitud en otros</p>
                <input
                  {...register('request_in_other_bsd', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Bs.d'
                  className='rounded-full border px-4 w-10/12'
                />
                <input
                  {...register('request_in_other_dollars', {
                    required: watchTypeProgram === 'financiado' ? true : false,
                    valueAsNumber: true,
                  })}
                  type='number'
                  step='0.01'
                  min='1'
                  placeholder='Dolares'
                  className='rounded-full border px-4 w-10/12'
                />
              </div>
              {errors?.request_in_other_bsd && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_in_other_bsd.message}
                </p>
              )}
              {errors?.request_in_other_dollars && (
                <p className='text-center text-red-600 w-full'>
                  {errors.request_in_other_dollars.message}
                </p>
              )}
            </>
          )}

          <select
            {...register('line_research')}
            className='rounded-full border px-4 w-10/12'
            defaultValue={'none'}
          >
            <option value='none' disabled>
              Linea de investigación
            </option>
            {linesOfInvestigation.map((element, index) => {
              if (!element.hidden) {
                return (
                  <option key={index} value={element.nombre}>
                    {element.nombre}
                  </option>
                );
              }
            })}
          </select>
          {errors?.line_research && (
            <p className='text-center text-red-600 w-full'>
              {errors.line_research.message}
            </p>
          )}
          <select
            {...register('commissionsRole')}
            defaultValue={'none'}
            className='rounded-full border px-4 w-10/12'
          >
            <option value='none' disabled>
              Comisión
            </option>
            {Object.entries(commissionsRoles).map((commission, i) => (
              <option key={i} value={commission[0]} className='capitalize'>
                {commission[1]}
              </option>
            ))}
          </select>
          {errors?.commissionsRole && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.commissionsRole?.message}
            </div>
          )}

          <input
            {...register('general_objective')}
            type='text'
            placeholder='Objetivo general'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.general_objective && (
            <p className='text-center text-red-600 w-full'>
              {errors.general_objective.message}
            </p>
          )}

          <div className='flex justify-center items-center w-10/12'>
            <MainButton text={'Registrar'} />
          </div>
          <Loader isOpen={isLoading} />
        </form>
      </Square>
    </div>
  );
};

export default ProgramFirstStep;
