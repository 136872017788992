import { useState } from 'react';
import Square from '../general/Square';
import { Link } from 'react-router-dom';
import ProyectComplement from './ProyectComplement';
import MainButton from '../general/MainButton';
import ProyectComplementItemList from './ProyectComplementItemList';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import projectsApi from '../../API/projects/projects.api';
import Loader from '../general/Loader';

function ProyectFourthStep({ step, next, previous }) {
  const [members, setMembers] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [backendError, setBackendError] = useState('');

  const change = (number) => {
    setMembers([]);
    for (let index = 0; index < number; index++) {
      setMembers((members) => [...members, <ProyectComplement />]);
    }
  };

  const addParticipantsProject = (participant) => {
    const id_project = handleStorage.getItemLocalStorage(
      'id_new_project_condes'
    );

    participant.id_project = id_project;

    setMembersList([...membersList, participant]);
  };

  const registerStepFour = async () => {
    if (membersList.length <= 0)
      return setErrorMessage(`Debes agregar ${members.length} miembros`);

    const id_project = handleStorage.getItemLocalStorage(
      'id_new_project_condes'
    );

    const data = {
      id_project: id_project,
      membersList,
    };

    setIsLoading(true);

    const response = await projectsApi.updateProjectByFlag(data, 'stepFour');

    setIsLoading(false);

    if (response?.data?.success === true) {
      return next();
    }

    if (response.data.success === false) {
      setBackendError(response.data.msg);
    }
  };

  return (
    <>
      <div
        className={
          step === 4
            ? 'w-full flex flex-col justify-center items-center'
            : 'hidden'
        }
      >
        <Square>
          <div className='w-10/12 flex items-center justify-between text-xl'>
            <p className='text-link'>Paso 4/5</p>
            <Link to={'/'}>
              <p className='font-bold text-red-600'>x</p>
            </Link>
          </div>
          <h3 className='text-link text-center my-12'>
            IDENTIFICACIÓN DEL PROYECTO ADSCRITO
          </h3>

          {backendError !== '' && (
            <p className='text-red-600 text-center'>{backendError}</p>
          )}

          {members.length > 0 && (
            <div className=''>
              <p className={errorMessage.length > 0 ? 'text-red-600' : ''}>
                Debes agregar {members.length} miembros.
              </p>
            </div>
          )}

          <section className='form-content'>
            <select
              onChange={(e) => {
                change(e.target.value);
              }}
              defaultValue='Cuantos participantes hay'
              name='Tipo de proyecto'
              className='rounded-full border px-4 w-10/12'
            >
              <option selected disabled>
                Cuantos participantes hay
              </option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
            </select>

            {members.length > 0 && (
              <ProyectComplement handleAdd={addParticipantsProject} />
            )}

            {membersList.length > 0 &&
              membersList.map((member, index) => (
                <ProyectComplementItemList key={index} data={member} />
              ))}

            <p>
              Esta estructura es dinamica dependiendo del metodo de
              investigación
            </p>
            <div className='flex justify-between items-center w-10/12'>
              <MainButton type='button' text='Atras' event={previous} />
              <MainButton
                type='button'
                text='Continuar'
                event={registerStepFour}
              />
              {/* <div
              onClick={previous}
              className="bg-main-blue text-white px-6 py-2 rounded-full cursor-pointer"
            >
              Atras
              </div>
            <div
            onClick={next}
            className="bg-main-blue text-white px-6 py-2 rounded-full cursor-pointer"
            >
            Continuar
          </div> */}
            </div>
            <Loader isOpen={isLoading} />
          </section>
        </Square>
      </div>
    </>
  );
}

export default ProyectFourthStep;
