import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import userApi from '../../API/users/user.api';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import frontRouter from '../../resources/constants/frontend.routes';

const useAuth = () => {
  const navigate = useNavigate();

  const [isLogged, setIsLogged] = useState(
    handleStorage.getItemLocalStorage('user_condes') || null
  );

  const verifyToken = useMemo(
    () => async () => {
      const isValidToken = await userApi.verifyToken();
      // console.log('isValidToken?.data?.success', isValidToken?.data?.success);
      if (!isValidToken?.data?.success) {
        handleLogged(false);
        // handleStorage.setItemLocalStorage('user_condes', null);
        // handleStorage.setItemLocalStorage('NavBarPanelAdmin', 1);
        // setIsLogged(null);
        // navigate(`${frontRouter.login}`);
        // return;
      }
    },
    []
  );

  const handleLogged = (toggleLogged, data) => {
    switch (toggleLogged) {
      case true:
        handleStorage.setItemLocalStorage('user_condes', data);
        setIsLogged(data);
        return;

      case false:
        handleStorage.setItemLocalStorage('user_condes', null);
        handleStorage.setItemLocalStorage('NavBarPanelAdmin', 1);
        setIsLogged(null);
        navigate(`${frontRouter.login}`);
        return;

      default:
        break;
    }
  };

  return { isLogged, handleLogged, verifyToken };
};

export default useAuth;
