import React from 'react';
import frontRouter from '../../resources/constants/frontend.routes';
import { Link } from 'react-router-dom';

function AdminOptions() {
  return (
    <>
      <section className='w-full md:w-auto'>
        <div className='flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 bg-main-blue text-white mt-8 rounded-lg md:rounded-full w-full py-1 px-8'>
          <Link to={frontRouter.panelListaDeUsuarios}>
            <p className='p-2'>Lista de usuarios</p>
          </Link>
          <Link to={frontRouter.panelListaDeNoticias}>
            <p className='p-2'>Noticias</p>
          </Link>
          <Link to={frontRouter.panelListaDeEventos}>
            <p className='p-2'>Eventos</p>
          </Link>
          <Link to={frontRouter.panelListaDeCursos}>
            <p className='p-2'>Cursos</p>
          </Link>
          <Link to={frontRouter.panelListPrograms}>
            <p className='p-2'>Programas</p>
          </Link>
          <Link to={frontRouter.panelListaDeProyectos}>
            <p className='p-2'>Proyectos</p>
          </Link>
        </div>
      </section>
    </>
  );
}

export default AdminOptions;
