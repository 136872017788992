import axios from 'axios';
import { headers } from '../index';
import routes from '../../resources/constants/backend.routes';

const createNewCourse = async (data) =>
  await axios.post(`${routes.base_url}/admin/create-course`, data, {
    headers: headers('multipart/form-data'),
  });

const getAllCourses = async () =>
  await axios.get(`${routes.base_url}/admin/get-all-courses`, {
    headers: headers(),
  });

const getEventsPagination = async (skip = 1) =>
  await axios.get(
    `${routes.base_url}/admin/get-courses-pagination?skip=${skip}`,
    {
      headers: headers(),
    }
  );

const getCourseById = async (id) =>
  await axios.get(`${routes.base_url}/admin/get-course-by-id?id=${id}`, {
    headers: headers(),
  });

const searchCourses = async (query) =>
  await axios.get(`${routes.base_url}/admin/search-courses?query=${query}`, {
    headers: headers(),
  });

const updateCourseById = async (data) =>
  await axios.put(`${routes.base_url}/admin/update-course`, data, {
    headers: headers('multipart/form-data'),
  });

const deleteCourseById = async (id) =>
  await axios.delete(`${routes.base_url}/admin/delete-course?id=${id}`, {
    headers: headers('multipart/form-data'),
  });

const courseAPI = {
  createNewCourse,
  getAllCourses,
  getEventsPagination,
  getCourseById,
  searchCourses,
  updateCourseById,
  deleteCourseById,
};

export default courseAPI;
