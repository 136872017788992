import React from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import images from "../../resources/images";

SwiperCore.use([Navigation, Autoplay]);

function SliderCourses() {
  return (
    <>
      <Swiper
        navigation={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        loop={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="flex justify-center items-center h-70%-vh min-h-400 p-4">
            <img
              src={images.cursos}
              className="object-cover h-70%-vh absolute"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center items-center h-70%-vh min-h-400 p-4 backdrop-blur-sm">
            <img
              src={images.cursos}
              className="object-cover h-70%-vh absolute"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SliderCourses;
