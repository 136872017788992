import { useState, useEffect } from 'react';
import Paginate from 'react-paginate';
import { getProgramsWithProjectsPendingRegistration } from '../../API/programs/programs.api';
import { commissionsRoles } from '../../constanst/constants';
import Loader from './Loader';
import MainButton from './MainButton';

const ModalSelectProgram = ({ handleClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [backendMessage, setBackendMessage] = useState({
    message: '',
    type: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [programsList, setProgramsList] = useState([]);

  useEffect(() => {
    getProgramsToAssociate();
  }, []);

  const getProgramsToAssociate = async () => {
    setIsLoading(true);

    const response = await getProgramsWithProjectsPendingRegistration(page);

    setIsLoading(false);

    if (response?.data?.data?.data?.length === 0) {
      setBackendMessage({
        ...backendMessage,
        message: response.data.data.message,
      });
      return;
    }

    setProgramsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  return (
    <div className='w-full h-full flex flex-col justify-between'>
      {backendMessage.message !== '' && (
        <p
          className={`text-center text-${
            !backendMessage.type ? 'red' : 'green'
          }-600`}
        >
          {backendMessage.message}
        </p>
      )}

      <Loader isOpen={isLoading} />

      <div className='w-full min-h-[45vh] max-h-[45vh] overflow-y-auto overflow-x-hidden space-y-2 py-2'>
        {programsList.length > 0 ? (
          programsList.map((program) => (
            <div
              key={program._id}
              className='flex justify-evenly lg:justify-between items-center p-2 border border-main-blue'
            >
              <div className='w-[60%] grid gap-2 break-all'>
                <label>
                  <span className='font-bold text-gray-600'>Titulo: </span>
                  {program.title}
                </label>
                <label>
                  <span className='font-bold text-gray-600'>Cód: </span>#
                  {program.program_code}
                </label>
                <label className='w-full'>
                  <span className='font-bold text-gray-600'>
                    Línea de investigación:{' '}
                  </span>
                  {program.line_research}
                </label>
                <label>
                  <span className='font-bold text-gray-600'>Comisión: </span>
                  {commissionsRoles[program.commissionsRole]}
                </label>
              </div>
              <MainButton
                type='button'
                text={'Asignar'}
                classes='w-[35%] h-10'
                event={() => handleClick(program._id)}
              />
            </div>
          ))
        ) : (
          <div className='w-full p-3 font-bold text-center'>
            <p>No hay programas para asociar</p>
          </div>
        )}
      </div>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </div>
  );
};

export default ModalSelectProgram;
