import axios from 'axios';
import routes from '../../resources/constants/backend.routes';
import { headers } from '..';

export const getSettingsList = async () => {
  const response = await axios.get(`${routes.base_url}/admin/get-settings`, {
    headers: headers(),
  });

  if (response.data.success) {
    return response.data.data[0];
  }

  return null;
};

export const updateSettings = async (data) => {
  const response = await axios.put(
    `${routes.base_url}/admin/update-settings`,
    data,
    {
      headers: headers(),
    }
  );

  console.log('response ------->', response);

  return response;

  //   if (response.data.success) {
  //     return response.data.data[0];
  //   }

  //   return null;
};
