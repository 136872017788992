import { useState } from 'react';
import MainButton from './MainButton';

const ModalToExplainUnApproval = ({ handleClick }) => {
  const [objectUnApprovalProject, setObjectUnApprovalProject] = useState('');
  const [error, setError] = useState('');

  const submit = () => {
    if (objectUnApprovalProject === '') {
      return setError(
        'Debe ingresar una descripción de porque fue rechazado el proyecto.'
      );
    }

    setError('');
    handleClick(objectUnApprovalProject);
    setObjectUnApprovalProject('');
  };

  return (
    <div className="w-full h-full">
      <textarea
        className="w-full h-90% resize-none p-5 rounded-md outline-indigo-600 border border-indigo-600"
        onChange={(e) => setObjectUnApprovalProject(e.target.value)}
        value={objectUnApprovalProject}
      ></textarea>
      <div className="flex flex-col items-center justify-center w-full space-y-2">
        <p className="text-red-600">{error}</p>
        <MainButton type="button" text="Enviar" event={submit} />
      </div>
    </div>
  );
};

export default ModalToExplainUnApproval;
