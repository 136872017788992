import images from "../../resources/images";
import "../../resources/css/animations.css";
import { Link } from "react-router-dom";
function JoinNow() {
  return (
    <section className="bg-pattern grid grid-cols-1 gap-4 md:grid-cols-2 p-4">
      <div className="flex flex-col items-center justify-center space-y-4">
        <h2 className="text-main-blue text-3xl font-bold text-center">
          Sé parte de nuestro congreso
        </h2>
        <Link to={"/congreso"} >
          <button className="bg-main-blue rounded-lg p-3 text-white">
            Únete ahora
          </button>
        </Link>
      </div>
      <div className="flex items-center justify-center">
        <img
          src={images.congreso_evento}
          className="animation"
          alt=""
          style={{ height: "70%" }}
        />
      </div>
    </section>
  );
}

export default JoinNow;
