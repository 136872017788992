import { useRef, useContext, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContextApp } from '../store/GeneralContext';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import formValidations from '../security/validations/validationForms';
import courseSchema from '../security/schemas/course.schema';
import ModuleInformation from '../components/admin/ModuleInformation';
import courseAPI from '../API/course/course.api';
import utilsFunctions from '../utils/commonFunctions';

const EditCourses = () => {
  const { state, dispatch } = useContext(ContextApp);
  const { courseToEdit } = state;

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(courseSchema),
  });

  const formRef = useRef(null);

  const [messageNotification, setMessageNotification] = useState('');
  const [dataCourse, setDataCourse] = useState({});
  const [dataModules, setDataModules] = useState([]);

  // --------------------------------------------------
  const [moduleData, setModuleData] = useState({
    title_module: '',
    description_module: '',
  });
  // --------------------------------------------------

  useEffect(() => {
    if (courseToEdit) {
      setDataCourse(courseToEdit);
      setDataModules(courseToEdit.modules);
      setValue('id_course', courseToEdit._id);
      setValue('titulo', courseToEdit.titulo);
      setValue('date', courseToEdit.date);
      setValue('duracion', courseToEdit.duracion);
      setValue('telefono', courseToEdit.telefono);
      setValue('profesor', courseToEdit.profesor);
      setValue('certificado', courseToEdit.certificado);
      setValue('portada', courseToEdit.portada);
    }
  }, [courseToEdit]);

  const handleChangeNewModule = ({ target }) => {
    const { name, value } = target;
    setModuleData({ ...moduleData, [name]: value });
  };

  const handleChageEditCourse = (e) => {
    const { name, value } = e.target;
    setValue('certificado', value);
    setDataCourse({
      ...dataCourse,
      [name]: value,
    });
  };

  const addModule = () => {
    if (
      moduleData?.title_module !== '' ||
      moduleData?.description_module !== ''
    ) {
      setDataModules([...dataModules, { ...moduleData }]);
      setModuleData({
        title_module: '',
        description_module: '',
      });
    }
  };

  const handleChangeEditModules = (e, idModule) => {
    const { name, value } = e.target;

    const filtrared = dataModules.filter((mod) =>
      mod._id === idModule ? (mod[name] = value) : mod
    );

    setDataModules(filtrared);
  };

  const updateCourse = async (data) => {
    // console.log('data to edit ->', data);
    const form = formValidations.createFormData(formRef.current);
    form.append('modules', JSON.stringify(dataModules));

    const img = form.get('portada');

    if (img.name) {
      const finalPathImg = await utilsFunctions.uploadImageCallBack(img);
      form.append('finalPathImg', finalPathImg.data.link);
    }

    const response = await courseAPI.updateCourseById(form);
    setMessageNotification(response.data.msg);
    if (response.data.success) {
      setDataCourse({});
    }
  };

  return (
    <>
      <section className="bg-main-background py-8 flex flex-col items-center justify-center w-full">
        <h1 className="text-4xl font-bold text-main-blue">Editar curso</h1>
        <Square>
          <div className="w-full flex items-center justify-end text-red-600">
            <Link to={'/panel/lista-de-cursos'}>
              <p className="font-bold">x</p>
            </Link>
          </div>
          <form
            ref={formRef}
            className="form-content"
            onSubmit={handleSubmit(updateCourse)}
            encType="multipart/form-data"
          >
            <input
              type="text"
              name="id_course"
              hidden
              {...register('id_course')}
            />
            <input
              type="text"
              name="titulo"
              {...register('titulo')}
              onChange={handleChageEditCourse}
              className="rounded-full border px-4 w-10/12"
              placeholder="Titulo"
            />
            <input
              type="date"
              name="date"
              {...register('date')}
              onChange={handleChageEditCourse}
              className="rounded-full border px-4 w-10/12"
              placeholder="Fecha de inicio"
            />
            <input
              type="text"
              name="duracion"
              {...register('duracion')}
              onChange={handleChageEditCourse}
              className="rounded-full border px-4 w-10/12"
              placeholder="Duración"
            />
            <input
              type="text"
              name="profesor"
              {...register('profesor')}
              onChange={handleChageEditCourse}
              className="rounded-full border px-4 w-10/12"
              placeholder="Profesor"
            />
            <input
              type="text"
              name="telefono"
              {...register('telefono')}
              onChange={handleChageEditCourse}
              className="rounded-full border px-4 w-10/12"
              placeholder="Numero de contacto"
            />
            <div className="flex flex-col items-start justify-center space-y-4 w-10/12">
              <p>¿Certificado?</p>
              <div className="flex items-center justify-start space-x-4">
                <div className="flex items-center justify-start space-x-2">
                  <input
                    type="radio"
                    checked={dataCourse.certificado === 'digital'}
                    onChange={handleChageEditCourse}
                    // {...register('certificado')}
                    name="certificado"
                    value="digital"
                    id="digital"
                  />
                  <label htmlFor="digital">Digital</label>
                </div>
                <div className="flex items-center justify-start space-x-2">
                  <input
                    type="radio"
                    checked={dataCourse.certificado === 'fisico'}
                    onChange={handleChageEditCourse}
                    // {...register('certificado')}
                    name="certificado"
                    value="fisico"
                    id="fisico"
                  />
                  <label htmlFor="fisico">Fisico</label>
                </div>
                <div className="flex items-center justify-start space-x-2">
                  <input
                    type="radio"
                    checked={dataCourse.certificado === 'none'}
                    onChange={handleChageEditCourse}
                    // {...register('certificado')}
                    name="certificado"
                    value="none"
                    id="none"
                  />
                  <label htmlFor="none">No</label>
                </div>
              </div>
            </div>

            <ModuleInformation
              handleChange={handleChangeNewModule}
              values={moduleData}
            />

            <div className="w-10/12 flex justify-start items-center">
              <button
                type="button"
                onClick={addModule}
                className="rounded-full bg-main-blue px-4 py-2 text-white"
              >
                Agregar Module
              </button>
            </div>

            {dataModules &&
              dataModules.map((module) => (
                <Fragment key={module._id}>
                  <input
                    type="text"
                    value={module.title_module}
                    name="title_module"
                    onChange={(e) => handleChangeEditModules(e, module._id)}
                    className="rounded-full border px-4 w-10/12"
                    placeholder="Titulo del modulo"
                  />

                  <textarea
                    value={module.description_module}
                    onChange={(e) => handleChangeEditModules(e, module._id)}
                    className="rounded border px-4 w-10/12"
                    placeholder="Descripción de modulo"
                    name="description_module"
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </Fragment>
              ))}

            {/* <div className="w-10/12 flex justify-start items-center">
              <button className="rounded-full bg-main-blue px-4 py-2 text-white">
                +
              </button>
            </div> */}

            <div className="w-10/12 flex-col flex justify-center items-start">
              <p>Seleccionar portada</p>
              <input
                onChange={handleChageEditCourse}
                {...register('portada')}
                className="px-4 w-10/12"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                name="portada"
                id=""
              />
            </div>
            {messageNotification !== '' && (
              <div className="w-full flex items-center justify-center text-green-600">
                {messageNotification}
              </div>
            )}
            <MainButton text="Guardar" />
          </form>
        </Square>
      </section>
    </>
  );
};

export default EditCourses;
