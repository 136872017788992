import React from "react";
import MidTittle from "../components/general/MidTittle";
import images from "../resources/images";
import "animate.css";

function Congress() {
  return (
    <>
      <section className="bg-conferencia bg-cover flex items-center justify-center h-screen bg-fixed bg-center w-full min-h-600">
        <div className="flex justify-center items-center flex-col space-y-6 md:pl-20 text-center bg-gradient-to-tr from-gray-700/50 via-gray-900/50 to-black h-screen min-h-600 w-full z-10 px-8">
          <h1 className=" text-main-blue text-4xl font-bold GFG animate__animated animate__fadeInDown">
            CONGRESO INTERNACIONAL DE CIENCIA, TECNOLOGÍA Y HUMANIDADES
          </h1>
          <div className="w-full flex justify-center items-center">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUD36lvYfVprV9QDK1sfD8i6Wd-Yj7mBNF2BbB9k_sp0Zsjg/viewform">
              <h3 className="text-2xl font-light text-white bg-main-blue rounded-full py-2 px-4 animate__animated animate__fadeInDown">
                Únete ahora
              </h3>
            </a>
          </div>
        </div>
      </section>
      <img src={images.cintillo_congreso} className="w-full" alt="" />
      <section>
        <MidTittle>
          <div className="text-base flex flex-col justify-center items-center space-x-2 space-y-1">
            <img
              src={images.calendar}
              className="text-main-blue"
              alt=""
              width={20}
            />
            <p>Del 10 al 12 de mayo del 2022</p>
          </div>
        </MidTittle>
      </section>
      <section className="h-50%-vh min-h-1000 grid grid-cols-1 md:grid-cols-2">
        <div className="my-8 px-6 flex justify-center items-center space-y-6 text-center md:text-left md:items-start md:pl-20 flex-col">
          <h1 className="text-main-blue text-4xl font-bold">
            Te invitamos a participar en este magno evento
          </h1>
          <p className="">
            Conoce un equipo de profesionales que se encuentran entre los
            mejores en sus respectivas áreas, impartiendo conocimiento y
            opiniones ante cientos de espectadores, en donde tú podrías ser uno
            de ellos.
          </p>
        </div>
        <div className="flex items-center justify-center">
          <img
            className="w-9/12 object-cover rounded border border-main-blue"
            src={images.poster_congreso}
            alt=""
          />
        </div>
      </section>
      <MidTittle>
        <div className="text-base flex flex-col justify-center items-center space-x-2 space-y-1">
          <img
            src={images.location}
            className="text-main-blue"
            alt=""
            width={20}
          />
          <p>Hotel Tibisay Maracaibo, Venezuela</p>
        </div>
      </MidTittle>
      <section className="h-50%-vh min-h-800 grid grid-cols-1 md:grid-cols-2">
        <div className="flex items-center justify-center">
          <img
            className="w-8/12 object-cover rounded-full border border-main-blue"
            src={images.identificador_campaña}
            alt=""
          />
        </div>
        <div className="my-8 px-6 flex justify-center items-center space-y-6 text-center md:text-left md:items-start md:pl-20 flex-col">
          <h1 className="text-main-blue text-4xl font-bold">
            Intégrate a la familia de investigadores LUZ
          </h1>
          <p className="">
            Para consolidar la misión estratégica y trascendental de gestionar
            la investigación, mediante acciones de concertación y cooperación en
            ciencia, tecnología y humanidades.
          </p>
        </div>
      </section>
      <section className="py-8">
        <MidTittle>
          <div className="text-2xl flex flex-col justify-center items-center space-x-2 space-y-1">
            <p>Más información</p>
          </div>
        </MidTittle>
        <div className="flex md:flex-row flex-col justify-center items-center">
          <div className="md:flex-row space-y-6 md:space-y-0 flex-col border border-main-blue rounded p-8 flex justify-center items-center space-x-4">
            <div className="flex flex-col justify-center items-center space-y-4">
              <img src={images.folder} style={{ width: 65 }} alt="" />
              <a target={"_blank"} rel="noreferrer" href="https://www.mediafire.com/file/r67txmmh9x2usp1/Descripci%25C3%25B3n_Programa_60_Aniversario_CONDES.pdf/file">
                <p className="text-link">Aniversario CONDES.pdf</p>
              </a>
            </div>
            <div className="flex flex-col justify-center items-center space-y-4">
              <img src={images.folder} style={{ width: 65 }} alt="" />
              <a target={"_blank"} rel="noreferrer" href="https://www.mediafire.com/file/ap9ffanmyca4ymn/NORMATIVA+CONGRESO+CONDES+2022-+ACTUALIZADA.pdf/file">
                <p className="text-link">Normativas del congreso.pdf</p>
              </a>
            </div>
          </div>
        </div>
      </section>
      <MidTittle>
        <div className="text-2xl flex flex-col justify-center items-center space-x-2 space-y-1">
          <p>¿Te interesa?</p>
        </div>
      </MidTittle>
      <section className="bg-pattern bg-cover flex items-center justify-center h-screen bg-center min-h-600">
        <div className="bg-success bg-top bg-cover bg-fixed flex justify-center items-center flex-col space-y-6 md:items-start md:pl-20 text-center h-screen min-h-600 px-8 w-full z-10">
          <div className="w-full flex justify-center items-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdUD36lvYfVprV9QDK1sfD8i6Wd-Yj7mBNF2BbB9k_sp0Zsjg/viewform"
            >
              <h3 className="text-2xl font-light text-white bg-main-blue rounded-full py-2 px-4">
                Únete ahora
              </h3>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Congress;
