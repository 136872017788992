import React, { useState } from 'react';
import ProyectIntroduction from '../components/forms/ProyectIntroduction';
import ProyectFirstStep from '../components/forms/ProyectFirstStep';
import ProyectSecondStep from '../components/forms/ProyectSecondStep';
import ProyectThirdStep from '../components/forms/ProyectThirdStep';
import ProyectFourthStep from '../components/forms/ProyectFourthStep';
import ProyectFivthStep from '../components/forms/ProyectFivthStep';
import ProyectSixthStep from '../components/forms/ProyectSixthStep';
import handleStorage from '../services/localstorage/handelLocalStorage';

function RegisterProyects() {
  const [step, setStep] = useState(
    handleStorage.getItemLocalStorage('condes_project_step') || 0
  );
  const restart = () => {
    handleStorage.clearItemLocalStorage('condes_project_step');
    setStep(0);
  };
  const nextStep = () => {
    const nextStep = step + 1;
    handleStorage.setItemLocalStorage('condes_project_step', nextStep);
    setStep(nextStep);
  };
  const previousStep = () => {
    const nextStep = step - 1;
    handleStorage.setItemLocalStorage('condes_project_step', nextStep);
    setStep(nextStep);
  };
  return (
    <>
      <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
        <h1 className='text-center text-4xl font-bold text-main-blue'>
          Registro de proyectos
        </h1>
        <ProyectIntroduction step={step} next={nextStep} />
        <ProyectFirstStep step={step} next={nextStep} />
        <ProyectSecondStep
          step={step}
          next={nextStep}
          previous={previousStep}
        />
        <ProyectThirdStep step={step} next={nextStep} previous={previousStep} />
        <ProyectFourthStep
          step={step}
          next={nextStep}
          previous={previousStep}
        />
        <ProyectFivthStep step={step} next={nextStep} previous={previousStep} />
        <ProyectSixthStep step={step} restart={restart} />
      </section>
    </>
  );
}

export default RegisterProyects;
