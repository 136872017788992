import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Square from '../general/Square';
import MainButton from '../general/MainButton';
import validations from '../../security/schemas/formProjectsSchemas/registerProjects.schema';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import projectsApi from '../../API/projects/projects.api';
import linesOfInvestigation from '../../resources/js/linesOfInvestigation.json';
import Loader from '../general/Loader';
import { commissionsRoles } from '../../constanst/constants';

function ProyectSecondStep({ step, next, previous }) {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations.registerProjectsStepTwoSchema),
  });

  const [type, setType] = useState('');
  const [backendError, setBackendError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const registerStepTwo = async (data) => {
    if (data.type_project === 'none') {
      return setError('type_project', {
        type: 'custom',
        message: 'Introduzca un tipo de proyecto valido.',
      });
    }

    if (type === 'financiado') {
      // * personal_payment_request

      if (isNaN(data.personal_payment_request_bsd))
        return setError('personal_payment_request_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.personal_payment_request_dollars))
        return setError('personal_payment_request_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.personal_payment_request = {
        personal_payment_request_bsd: data.personal_payment_request_bsd,
        personal_payment_request_dollars: data.personal_payment_request_dollars,
      };

      delete data.personal_payment_request_bsd;
      delete data.personal_payment_request_dollars;

      // * equipment_purchase_request

      if (isNaN(data.equipment_purchase_request_bsd))
        return setError('equipment_purchase_request_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.equipment_purchase_request_dollars))
        return setError('equipment_purchase_request_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.equipment_purchase_request = {
        equipment_purchase_request_bsd: data.equipment_purchase_request_bsd,
        equipment_purchase_request_dollars:
          data.equipment_purchase_request_dollars,
      };

      delete data.equipment_purchase_request_bsd;
      delete data.equipment_purchase_request_dollars;

      // * request_payment_materialsAndSupplies

      if (isNaN(data.request_payment_materialsAndSupplies_bsd))
        return setError('request_payment_materialsAndSupplies_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_payment_materialsAndSupplies_dollars))
        return setError('request_payment_materialsAndSupplies_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_payment_materialsAndSupplies = {
        request_payment_materialsAndSupplies_bsd:
          data.request_payment_materialsAndSupplies_bsd,
        request_payment_materialsAndSupplies_dollars:
          data.request_payment_materialsAndSupplies_dollars,
      };

      delete data.request_payment_materialsAndSupplies_bsd;
      delete data.request_payment_materialsAndSupplies_dollars;

      // * request_tickets_travels

      if (isNaN(data.request_tickets_travels_bsd))
        return setError('request_tickets_travels_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_tickets_travels_dollars))
        return setError('request_tickets_travels_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_tickets_travels = {
        request_tickets_travels_bsd: data.request_tickets_travels_bsd,
        request_tickets_travels_dollars: data.request_tickets_travels_dollars,
      };

      delete data.request_tickets_travels_bsd;
      delete data.request_tickets_travels_dollars;

      // * request_documents_amount

      if (isNaN(data.request_documents_amount_bsd))
        return setError('request_documents_amount_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_documents_amount_dollars))
        return setError('request_documents_amount_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_documents_amount = {
        request_documents_amount_bsd: data.request_documents_amount_bsd,
        request_documents_amount_dollars: data.request_documents_amount_dollars,
      };

      delete data.request_documents_amount_bsd;
      delete data.request_documents_amount_dollars;

      // * request_visibility_accessibility
      if (isNaN(data.request_visibility_accessibility_bsd))
        return setError('request_visibility_accessibility_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_visibility_accessibility_dollars))
        return setError('request_visibility_accessibility_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_visibility_accessibility = {
        request_visibility_accessibility_bsd:
          data.request_visibility_accessibility_bsd,
        request_visibility_accessibility_dollars:
          data.request_visibility_accessibility_dollars,
      };

      delete data.request_visibility_accessibility_bsd;
      delete data.request_visibility_accessibility_dollars;

      // * request_in_other
      if (isNaN(data.request_in_other_bsd))
        return setError('request_in_other_bsd', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en bolívares',
        });

      if (isNaN(data.request_in_other_dollars))
        return setError('request_in_other_dollars', {
          type: 'custom',
          message: 'Debe ingresar una cantidad en dólares',
        });

      data.request_in_other = {
        request_in_other_bsd: data.request_in_other_bsd,
        request_in_other_dollars: data.request_in_other_dollars,
      };

      delete data.request_in_other_bsd;
      delete data.request_in_other_dollars;
    } else {
      // data.personal_payment_request = null;
      delete data.personal_payment_request_bsd;
      delete data.personal_payment_request_dollars;
      // // * equipment_purchase_request
      // data.equipment_purchase_request = null;
      delete data.equipment_purchase_request_bsd;
      delete data.equipment_purchase_request_dollars;
      // // * request_payment_materialsAndSupplies
      // data.request_payment_materialsAndSupplies = null;
      delete data.request_payment_materialsAndSupplies_bsd;
      delete data.request_payment_materialsAndSupplies_dollars;

      delete data.request_tickets_travels_bsd;
      delete data.request_tickets_travels_dollars;
      // // * request_documents_amount
      // data.request_documents_amount = null;
      delete data.request_documents_amount_bsd;
      delete data.request_documents_amount_dollars;
      // // * request_visibility_accessibility
      // data.request_visibility_accessibility = null;
      delete data.request_visibility_accessibility_bsd;
      delete data.request_visibility_accessibility_dollars;
      // // * request_in_other
      // data.request_in_other = null;
      delete data.request_in_other_bsd;
      delete data.request_in_other_dollars;
    }

    const id_project = handleStorage.getItemLocalStorage(
      'id_new_project_condes'
    );

    data.id = id_project;

    // if (data.program_code === '')
    //   data.program_code = utils.generateCodeProgram(type);

    setIsLoading(true);

    const response = await projectsApi.updateProjectByFlag(data, 'stepTwo');

    setIsLoading(false);

    if (response?.data?.success === true) {
      return next();
    }

    if (response.data.success === false) {
      setBackendError(response.data.msg);
    }
  };

  return (
    <div
      className={
        step === 2
          ? 'w-full flex flex-col justify-center items-center'
          : 'hidden'
      }
    >
      <Square>
        <div className='w-10/12 flex items-center justify-between text-xl'>
          <p className='text-link'>Paso 2/5</p>
          <Link to={'/'}>
            <p className='font-bold text-red-600'>x</p>
          </Link>
        </div>
        <h3 className='text-link text-center my-12'>
          IDENTIFICACIÓN DEL PROYECTO
        </h3>

        {backendError.length > 0 && (
          <p className='text-red-600 text-center'>{backendError}</p>
        )}

        <form
          onSubmit={handleSubmit(registerStepTwo)}
          className='form-content'
          autoComplete='off'
        >
          <input
            type='text'
            placeholder='Titulo'
            {...register('title')}
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.title && (
            <p className='text-center text-red-600 w-full'>
              {errors.title.message}
            </p>
          )}

          <select
            {...register('type_project')}
            className='rounded-full border px-4 w-10/12'
            defaultValue={'none'}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value='none' disabled>
              Tipo de proyecto
            </option>
            <option value='no financiado'>No financiado</option>
            <option value='financiado'>Financiado</option>
            <option value='asociado'>Asociado</option>
          </select>
          {errors?.type_project && (
            <p className='text-center text-red-600 w-full'>
              {errors.type_project.message}
            </p>
          )}
          {type === 'financiado' && (
            <p className='text-center text-green-600 w-full'>
              Deberá proporcionar una solicitud de subvención para el proyecto
              una vez lo haya registrado.
            </p>
          )}

          {/* <input
            {...register('project_area')}
            type='text'
            placeholder='Area del proyecto'
            className='rounded-full border px-4 w-10/12'
          />
          {errors?.project_area && (
            <p className='text-center text-red-600 w-full'>
              {errors.project_area.message}
            </p>
          )} */}

          <select
            {...register('line_research')}
            className='rounded-full border px-4 w-10/12'
            defaultValue={'none'}
          >
            <option value='none' disabled>
              Linea de investigación
            </option>
            {linesOfInvestigation.map((element, index) => {
              if (!element.hidden) {
                return (
                  <option key={index} value={element.nombre}>
                    {element.nombre}
                  </option>
                );
              }
              // return (
              //   <div key={index}>
              //     <option value={element.nombre}>{element.nombre}</option>
              //     {/* {element.hidden && (

              //   )} */}

              //     {/* {element.hidden ? (
              //   <option key={index} disabled value={element.nombre}>
              //     {element.nombre}
              //   </option>
              // ) : (
              //   <option key={index} value={element.nombre}>
              //     {element.nombre}
              //   </option>
              // )} */}
              //   </div>
              // );
            })}
          </select>
          {errors?.line_research && (
            <p className='text-center text-red-600 w-full'>
              {errors.line_research.message}
            </p>
          )}

          <select
            {...register('commissionsRole')}
            defaultValue={'none'}
            className='rounded-full border px-4 w-10/12'
          >
            <option value='none' disabled>
              Comisión
            </option>
            {Object.entries(commissionsRoles).map((commission, i) => (
              <option key={i} value={commission[0]} className='capitalize'>
                {commission[1]}
              </option>
            ))}
          </select>
          {errors?.commissionsRole && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.commissionsRole?.message}
            </div>
          )}

          <div className='flex space-y-4 flex-col justify-start w-10/12'>
            <p className='px-1 text-left w-10/12'>Tipo de investigación</p>
            <div className='px-1 space-y-2 md:space-y-0 flex flex-col md:flex-row md:space-x-4 justify-start w-10/12'>
              <label>
                <input
                  type='radio'
                  {...register('type_research')}
                  // name="investigacion"
                  value='Docente'
                />{' '}
                Básica
              </label>
              <label>
                <input
                  type='radio'
                  {...register('type_research')}
                  value='Administracion'
                />{' '}
                Aplicada
              </label>
              <label>
                <input
                  type='radio'
                  {...register('type_research')}
                  value='Estudiante'
                />{' '}
                De campo
              </label>
              <label>
                <input
                  type='radio'
                  {...register('type_research')}
                  value='other'
                />{' '}
                Otra
              </label>
            </div>
          </div>
          {errors?.type_research && (
            <p className='text-center text-red-600 w-full'>
              {errors.type_research.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud para pago en personal</p>
            <input
              {...register('personal_payment_request_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('personal_payment_request_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.personal_payment_request_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.personal_payment_request_bsd.message}
            </p>
          )}
          {errors?.personal_payment_request_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.personal_payment_request_dollars.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud de monto para adquisición de equipos</p>
            <input
              {...register('equipment_purchase_request_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('equipment_purchase_request_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.equipment_purchase_request_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.equipment_purchase_request_bsd.message}
            </p>
          )}
          {errors?.equipment_purchase_request_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.equipment_purchase_request_dollars.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>
              Solicitud de monto para adquisición de pago en materiales y
              suministros
            </p>
            <input
              {...register('request_payment_materialsAndSupplies_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('request_payment_materialsAndSupplies_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.request_payment_materialsAndSupplies_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_payment_materialsAndSupplies_bsd.message}
            </p>
          )}
          {errors?.request_payment_materialsAndSupplies_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_payment_materialsAndSupplies_dollars.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud de monto para pasajes y viaticos</p>
            <input
              {...register('request_tickets_travels_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('request_tickets_travels_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.request_tickets_travels_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_tickets_travels_bsd.message}
            </p>
          )}
          {errors?.request_tickets_travels_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_tickets_travels_dollars.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud de monto de documentación</p>
            <input
              {...register('request_documents_amount_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('request_documents_amount_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.request_documents_amount_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_documents_amount_bsd.message}
            </p>
          )}
          {errors?.request_documents_amount_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_documents_amount_dollars.message}
            </p>
          )}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud de monto en visibilidad y accesibilidad</p>
            <input
              {...register('request_visibility_accessibility_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('request_visibility_accessibility_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.request_visibility_accessibility_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_visibility_accessibility_bsd.message}
            </p>
          )}
          {errors?.request_visibility_accessibility_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_visibility_accessibility_dollars.message}
            </p>
          )}

          {/* request_in_other */}

          <div
            className={
              type === 'financiado'
                ? 'flex space-y-2 flex-col justify-start w-10/12'
                : 'hidden'
            }
          >
            <p>Solicitud en otros</p>
            <input
              {...register('request_in_other_bsd', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Bs.d'
              className='rounded-full border px-4 w-10/12'
            />
            <input
              {...register('request_in_other_dollars', {
                required: type === 'financiado' ? true : false,
                valueAsNumber: true,
              })}
              type='number'
              step='0.01'
              min='1'
              placeholder='Dolares'
              className='rounded-full border px-4 w-10/12'
            />
          </div>
          {errors?.request_in_other_bsd && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_in_other_bsd.message}
            </p>
          )}
          {errors?.request_in_other_dollars && (
            <p className='text-center text-red-600 w-full'>
              {errors.request_in_other_dollars.message}
            </p>
          )}

          {/* <div className='flex space-y-4 flex-col justify-start w-10/12'>
            <span className='text-green-600'>
              Si no ingresa un código de proyecto, se generará uno
              automaticamente.
            </span>
            <input
              {...register('program_code')}
              type='text'
              placeholder='Código del programa'
              className='rounded-full border px-4 w-10/12'
            />
            {errors?.program_code && (
              <p className='text-center text-red-600 w-full'>
                {errors.program_code.message}
              </p>
            )}
          </div> */}

          <div className='flex justify-between items-center w-10/12'>
            <MainButton type='button' event={previous} text={'Atras'} />
            <MainButton type='submit' text={'Continuar'} />
          </div>
        </form>
        <Loader isOpen={isLoading} />
      </Square>
    </div>
  );
}

export default ProyectSecondStep;
