import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import frontendRoutes from '../resources/constants/frontend.routes';
import eventsApi from '../API/events/events.api';
import utilsFunctions from '../utils/commonFunctions';
import EditorPreview from '../components/forms/EditorPreview';

const ArticleEvent = () => {
  let { id } = useParams();

  const [evento, setEvento] = useState({});
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    getEvent(id);
  }, []);

  const getEvent = async (id) => {
    const response = await eventsApi.getEventoById(id);
    if (response.data.success) {
      const state = utilsFunctions.createEditorStateFromHTML(
        response.data.data.content
      );
      setEditorState(state);
      setEvento(response.data.data);
    }
  };

  let time = evento.content;
  if (evento.content) {
    time = time.replace( /(<([^>]+)>)/ig, '');
    time = (time.split(" ").length/155).toFixed(0);
    if(time < 1) {
      time = "<1 min"
    }else{
      time = time + " min"
    }
  }

  return (
    <>
      <section className="flex justify-center items-center w-full max-h-400 overflow-hidden">
        <div className="absolute w-full h-full max-h-400 overflow-hidden z-10 bg-white/75" />
        <img
          src={evento.pathImage}
          alt=""
          className="object-cover w-full h-full z-0"
        />
        <p className="text-main-blue px-4 text-center font-bold text-4xl absolute z-20">
          {evento.title}
        </p>
      </section>
      <section className="flex flex-col space-y-4 md:space-y-0 md:flex-row bg-main-blue px-6 md:px-8 py-6 md:py-2 flex justify-between items-center text-white font-semibold">
        <p>Autor: {evento.author}</p>
        <p>Tiempo de lectura: {time} </p>
      </section>
      <section className="text-gray-500 py-4 px-6">
        <EditorPreview editorState={editorState} />
      </section>
      <section className="text-link text-center my-4 font-semibold">
        <Link to={frontendRoutes.eventos}>
          <p>Volver a eventos</p>
        </Link>
      </section>
    </>
  );
};

export default ArticleEvent;
