export const userRoles = {
  admin: 'admin',
  user: 'user',
  coordinator: 'coordinator',
  evaluator: 'evaluator',
};

export const userRolesSelect = {
  admin: 'administrador',
  user: 'usuario',
  coordinator: 'coordinador',
  evaluator: 'evaluador',
};

export const programsAndProjectsStatus = {
  toBeApproved: 'por aprobar',
  approved: 'aprobados',
  disapproved: 'desaprobados',
};

export const statusProgramsAndProject = {
  toBeApproved: 'por aprobar',
  approved: 'aprobado',
  disapproved: 'rechazado',
};

export const commissionsRoles = {
  humanisticCommission: 'comisión humanística',
  scientificCommission: 'comisión científica',
};
