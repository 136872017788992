import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import usersApi from '../API/users/user.api';
import userSchemas from '../security/schemas/user.schema';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import Loader from '../components/general/Loader';

function MyAccount() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(userSchemas.userDataSchema),
  });

  const formRef = useRef(null);

  const [messageNotification, setMessageNotification] = useState('');
  const [isCompanyDefault, setIsCompanyDefault] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const dataUser = await usersApi.getDataUser();
    if (dataUser.data.success) {
      const { data } = dataUser.data;

      setValue('id', data.id);
      setValue('name', data.name);
      setValue('last_name', data.last_name);
      setValue('type_identification', 'V');
      setValue(
        'identification_number',
        data?.identification_number?.length === 4
          ? ''
          : data.identification_number
      );
      setValue('email', data.email);
      setValue('cod_number', data.cod_number);
      setValue('phone', data.phone);
      setValue('isCompany', data.isCompany);
      setIsCompanyDefault(JSON.stringify(data.isCompany));
    }
  };

  const updateData = async (data) => {
    let isValidPassword = {};

    const isValidNameAndLastName =
      await userSchemas.nameAndLastNameSchema.isValid({
        name: data.name,
        last_name: data.last_name,
      });

    if (!isValidNameAndLastName) {
      return setError('name', {
        type: 'manual',
        message: 'Formato invalido de nombre o apellido.',
      });
    }

    const isValidEmail = await userSchemas.emailSchema.isValid({
      email: data.email,
    });

    if (!isValidEmail) {
      return setError('email', {
        type: 'manual',
        message: 'Introduce un correo valido.',
      });
    }

    if (data.password !== '') {
      await userSchemas.passwordsSchema
        .validate({
          password: data.password,
          password_two: data.password_two,
        })
        .catch((err) => {
          isValidPassword = {
            name: err.name,
            error: err.errors,
          };
        });
    }

    if (isValidPassword?.name === 'ValidationError') {
      return setError('password', {
        type: 'manual',
        message: isValidPassword.error[0],
      });
    }

    if (!data.identification_number) {
      return setError('identification_number', {
        type: 'manual',
        message: 'Inrese una identificación.',
      });
    }

    setIsLoading(true);

    const res = await usersApi.updateDataUser(data);

    setIsLoading(false);

    setMessageNotification(res.data.msg);

    if (res.data.success) {
      getData();
      setIsEditMode(false);
    }
  };

  return (
    <div className='bg-main-background flex flex-col items-center justify-center py-8'>
      <h1 className='text-4xl font-bold text-main-blue'>Mi cuenta</h1>

      <Square>
        <form
          className='form-content relative'
          onSubmit={handleSubmit(updateData)}
          ref={formRef}
        >
          <p
            className='text-main-blue cursor-pointer absolute top-0 right-0'
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Cancelar' : 'Editar'}
          </p>
          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <input type='text' hidden {...register('id')} />
            <input
              {...register('name')}
              type='text'
              placeholder='Nombre'
              className='rounded-full border px-4 w-full md:w-1/2'
              disabled={!isEditMode}
            />
            <input
              {...register('last_name')}
              type='text'
              placeholder='Apellido'
              className='rounded-full border px-4 w-full md:w-1/2'
              disabled={!isEditMode}
            />
          </div>
          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <select
              className='rounded-full border px-4 w-full md:w-2/12'
              defaultValue={'V'}
              {...register('type_identification')}
              disabled={!isEditMode}
            >
              <option value='V'>V</option>
              <option value='E'>E</option>
              <option value='J'>J</option>
            </select>

            <input
              type='number'
              placeholder='Documento de identidad'
              className='rounded-full border px-4 w-full md:w-10/12'
              {...register('identification_number')}
              disabled={!isEditMode}
            />
          </div>
          <input
            {...register('email')}
            type='email'
            placeholder='Email'
            className='rounded-full border px-4 w-10/12'
            disabled={!isEditMode}
          />
          <div className='flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12'>
            <input
              {...register('cod_number')}
              type='text'
              placeholder='+ Código'
              className='rounded-full border px-4 w-full md:w-1/3'
              disabled={!isEditMode}
            />
            <input
              {...register('phone')}
              type='number'
              placeholder='Número de teléfono'
              className='rounded-full border px-4 w-full md:w-2/3'
              disabled={!isEditMode}
            />
          </div>
          {isEditMode && (
            <>
              <div className='w-10/12 flex flex-col md:flex-row items-start space-x-2'>
                <input
                  {...register('password')}
                  type={seePassword ? 'text' : 'password'}
                  placeholder='Contraseña'
                  className='w-full rounded-full border px-4'
                />
                {seePassword ? (
                  <IoIosEyeOff
                    onClick={() => setSeePassword(!seePassword)}
                    className='w-8 h-8 text-main-blue cursor-pointer'
                  />
                ) : (
                  <IoIosEye
                    onClick={() => setSeePassword(!seePassword)}
                    className='w-8 h-8 text-main-blue cursor-pointer'
                  />
                )}
              </div>
              <div className='w-10/12 flex flex-col md:flex-row items-start space-x-2'>
                <input
                  {...register('password_two')}
                  type={seePassword ? 'text' : 'password'}
                  placeholder='Repetir contraseña'
                  className='w-full rounded-full border px-4'
                />
              </div>
            </>
          )}
          <p className='w-10/12 text-left'>Cuenta empresarial</p>
          <div className='flex w-10/12 justify-start items-center space-x-6'>
            <div className='flex items-center space-x-2'>
              <p>Sí</p>
              <input
                type='radio'
                value={'true'}
                onClick={() => {
                  setValue('isCompany', 'true');
                  setIsCompanyDefault('true');
                }}
                checked={isCompanyDefault === 'true'}
                {...register('isCompany')}
                disabled={!isEditMode}
              />
            </div>
            <div className='flex items-center space-x-2'>
              <p>No</p>
              <input
                type='radio'
                value={'false'}
                onClick={() => {
                  setValue('isCompany', 'false');
                  setIsCompanyDefault('false');
                }}
                checked={isCompanyDefault === 'false'}
                {...register('isCompany')}
                disabled={!isEditMode}
              />
            </div>
          </div>
          {errors?.name && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.name?.message}
            </div>
          )}
          {errors?.last_name && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.last_name?.message}
            </div>
          )}
          {errors?.identification_number && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.identification_number?.message}
            </div>
          )}
          {errors?.email && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.email?.message}
            </div>
          )}
          {errors?.cod_number && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.cod_number?.message}
            </div>
          )}
          {errors?.phone && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.phone?.message}
            </div>
          )}
          {errors?.password && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.password?.message}
            </div>
          )}
          {errors?.password_two && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.password_two?.message}
            </div>
          )}
          {errors?.isCompany && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.isCompany?.message}
            </div>
          )}
          {messageNotification !== '' && (
            <div className='w-full flex items-center justify-center text-green-600'>
              {messageNotification}
            </div>
          )}
          {isEditMode && <MainButton text='Enviar' disabled={isLoading} />}
        </form>
        <Loader isOpen={isLoading} />
      </Square>
    </div>
  );
}

export default MyAccount;
