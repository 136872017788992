import { useEffect, useState } from 'react';
import Paginate from 'react-paginate';
import * as programsApi from '../API/programs/programs.api';
import Square from '../components/general/Square';
import ProgramsListElement from '../components/ProgramListElement';
import ProyectListElement from '../components/ProyectListElement';
import routes from '../resources/constants/frontend.routes';
import handleStorage from '../services/localstorage/handelLocalStorage';

function MyPrograms() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [programsList, setProgramsList] = useState([]);

  useEffect(() => {
    getAllPrograms();
  }, []);

  useEffect(() => {
    getAllPrograms();
  }, [page]);

  const getAllPrograms = async () => {
    const id_user = handleStorage.getIdUser();
    const response = await programsApi.getProjectsByIdUser(id_user, page);
    setProgramsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  return (
    <>
      <div className='bg-main-background flex flex-col items-center justify-center py-8'>
        <h1 className='text-4xl font-bold text-main-blue'>Mis programas</h1>
        <Square>
          {programsList.length > 0 &&
            programsList.map((program) => (
              <ProgramsListElement
                key={program._id}
                title={program.title}
                code={program.program_code}
                status={program.status_program}
                link={`${routes.program}/${program._id}`}
              />
            ))}
        </Square>
      </div>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </>
  );
}

export default MyPrograms;
