import { useRef, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import courseAPI from '../API/course/course.api';
import ModuleInformation from '../components/admin/ModuleInformation';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import courseSchema from '../security/schemas/course.schema';
import formValidations from '../security/validations/validationForms';
import utilsFunctions from '../utils/commonFunctions';

function AddCourse() {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(courseSchema),
  });

  const formRef = useRef(null);

  const [messageNotification, setMessageNotification] = useState('');
  const [arrModule, setArrModule] = useState([]);
  const [moduleData, setModuleData] = useState({
    title_module: '',
    description_module: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setModuleData({ ...moduleData, [name]: value });
  };

  const addModule = () => {
    if (
      moduleData?.title_module !== '' ||
      moduleData?.description_module !== ''
    ) {
      setArrModule([...arrModule, { ...moduleData }]);
      setModuleData({
        title_module: '',
        description_module: '',
      });
    }
  };

  const handleSubmitCourse = async (data) => {
    const form = formValidations.createFormData(formRef.current);
    form.append('modules', JSON.stringify(arrModule));

    const img = form.get('portada');

    if (!img.name) {
      return setError('portada', {
        type: 'manual',
        message: 'Debe escoger una imagen.',
      });
    }

    const finalPathImg = await utilsFunctions.uploadImageCallBack(img);
    form.append('finalPathImg', finalPathImg.data.link);

    const response = await courseAPI.createNewCourse(form);
    setMessageNotification(response.data.msg);
    if (response.data.success) {
      reset();
      setArrModule([]);
    }
  };

  return (
    <>
      <section className="bg-main-background py-8 flex flex-col items-center justify-center w-full">
        <h1 className="text-4xl font-bold text-main-blue">Agregar curso</h1>
        <Square>
          <div className="w-full flex items-center justify-end text-red-600">
            <Link to={'/panel/lista-de-cursos'}>
              <p className="font-bold">x</p>
            </Link>
          </div>
          {errors?.titulo && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.titulo?.message}
            </div>
          )}
          {errors?.date && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.date?.message}
            </div>
          )}
          {errors?.duracion && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.duracion?.message}
            </div>
          )}
          {errors?.profesor && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.profesor?.message}
            </div>
          )}
          {errors?.telefono && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.telefono?.message}
            </div>
          )}
          {errors?.certificado && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.certificado?.message}
            </div>
          )}
          {errors?.portada && (
            <div className="w-full flex items-center justify-center text-red-600">
              {errors?.portada?.message}
            </div>
          )}
          {messageNotification !== '' && (
            <div className="w-full flex items-center justify-center text-green-600">
              {messageNotification}
            </div>
          )}
          <form
            ref={formRef}
            className="form-content"
            onSubmit={handleSubmit(handleSubmitCourse)}
            encType="multipart/form-data"
          >
            <input
              type="text"
              className="rounded-full border px-4 w-10/12"
              placeholder="Titulo"
              name="titulo"
              {...register('titulo')}
            />
            <input
              type="date"
              className="rounded-full border px-4 w-10/12"
              placeholder="Fecha de inicio"
              name="date"
              {...register('date')}
            />
            <input
              type="text"
              className="rounded-full border px-4 w-10/12"
              placeholder="Duración"
              name="duracion"
              {...register('duracion')}
            />
            <input
              type="text"
              className="rounded-full border px-4 w-10/12"
              placeholder="Profesor"
              name="profesor"
              {...register('profesor')}
            />
            <input
              type="text"
              className="rounded-full border px-4 w-10/12"
              placeholder="Numero de contacto"
              name="telefono"
              {...register('telefono')}
            />
            <div className="flex flex-col items-start justify-center space-y-4 w-10/12">
              <p>¿Certificado?</p>
              <div className="flex items-center justify-start space-x-4">
                <div className="flex items-center justify-start space-x-2">
                  <input
                    {...register('certificado')}
                    type="radio"
                    name="certificado"
                    value="digital"
                    id="digital"
                  />
                  <label htmlFor="digital">Digital</label>
                </div>
                <div className="flex items-center justify-start space-x-2">
                  <input
                    type="radio"
                    name="certificado"
                    {...register('certificado')}
                    value="fisico"
                    id="fisico"
                  />
                  <label htmlFor="fisico">Fisico</label>
                </div>
                <div className="flex items-center justify-start space-x-2">
                  <input
                    type="radio"
                    name="certificado"
                    {...register('certificado')}
                    value="none"
                    id="none"
                  />
                  <label htmlFor="none">No</label>
                </div>
              </div>
            </div>

            <ModuleInformation
              handleChange={handleChange}
              values={moduleData}
            />

            <div className="w-10/12 flex justify-start items-center">
              <button
                type="button"
                onClick={addModule}
                className="rounded-full bg-main-blue px-4 py-2 text-white"
              >
                Agregar Module
              </button>
            </div>

            {arrModule.map((item, index) => (
              <ModuleInformation
                key={index}
                isDisabled={true}
                // handleChange={handleChange}
                values={item}
              />
            ))}

            {/* <div className="w-10/12 flex justify-start items-center">
              <button
                onClick={addModule}
                className="rounded-full bg-main-blue px-4 py-2 text-white"
              >
                +
              </button>
            </div> */}

            <div className="w-10/12 flex-col flex justify-center items-start">
              <p>Seleccionar portada</p>
              <input
                className="px-4 w-10/12"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                name="portada"
                {...register('portada')}
                id=""
              />
            </div>
            <MainButton text="Guardar" />
          </form>
        </Square>
      </section>
      0
    </>
  );
}

export default AddCourse;
