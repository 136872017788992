import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <section className="h-60%-vh flex flex-col items-center justify-center space-y-4">
        <h1 className="text-8xl text-main-blue font-bold text-center">404</h1>
        <h2 className="text-center text-main-blue text-4xl font-light">¡Ups! Algo salio mal</h2>
        <Link to={"/"}>
        <p className="text-center text-link text-xl font-light">Regresar al inicio</p>
        </Link>
        
      </section>
    </>
  );
}

export default NotFound;
