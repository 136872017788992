import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import newsApi from '../API/news/news.api';
import ContentGrid from '../components/general/ContentGrid';
import frontendRoutes from '../resources/constants/frontend.routes';
// nuevos cambios
const News = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(6);
  const [newsList, setnewsList] = useState([]);

  useEffect(() => {
    getAllNews();
  }, []);
  useEffect(() => {
    getAllNews();
  }, [page]);

  const getAllNews = async () => {
    const res = await newsApi.getNewsByPagination(page);
    setnewsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handleVewNew = (id) => {
    navigate(`${frontendRoutes.noticiasNoticia}/${id}`);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  return (
    <>
      <ContentGrid
        handleClick={handleVewNew}
        data={newsList}
        title={'NOTICIAS'}
      ></ContentGrid>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </>
  );
};

export default News;
