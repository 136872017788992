import React from "react";
import AdminOptions from "../components/admin/AdminOptions";

function AdminPanel() {
  return (
    <div className="my-12 flex justify-center items-center">
      <AdminOptions />
    </div>
    
  );
}

export default AdminPanel;
