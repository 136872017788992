import axios from 'axios';
import routes from '../../resources/constants/backend.routes';
import { headers } from '..';

export const registerNewProgramStepOne = async (data) =>
  await axios.post(`${routes.base_url}/user/register-program`, data, {
    headers: headers(),
  });

export const getProgramsList = async (flag, skip) =>
  await axios.get(
    `${routes.base_url}/user/get-all-programs?flag=${flag}&skip=${skip}`,
    { headers: headers() }
  );

export const getProjectsByIdUser = async (id_user, skip = 1) =>
  await axios.get(
    `${routes.base_url}/user/get-programs-by-id-user?id_user=${id_user}&skip=${skip}`,
    { headers: headers() }
  );

export const getProgramsById = async (id_program) =>
  await axios.get(
    `${routes.base_url}/user/get-program-by-id?id_program=${id_program}`,
    { headers: headers() }
  );

export const getProgramsByCommissionsRoleWithoutAssignment = async ({
  id_user,
  role,
  commissionsRole,
  skip = 1,
  flag,
}) =>
  await axios.get(
    `${routes.base_url}/user/get-programs-by-commissions-role?id_user=${id_user}&role=${role}&commissionsRole=${commissionsRole}&skip=${skip}&flag=${flag}`,
    {
      headers: headers(),
    }
  );

export const getProgramsWithProjectsPendingRegistration = async (skip = 1) =>
  await axios.get(
    `${routes.base_url}/user/get-programs-with-projects-pending-registration?skip=${skip}`,
    { headers: headers() }
  );

export const assignmentProgramTo = async (assignment_data) =>
  await axios.post(
    `${routes.base_url}/user/assign-program-evaluator`,
    assignment_data,
    {
      headers: headers(),
    }
  );

export const updateApprovalProgram = async (options) =>
  await axios.put(`${routes.base_url}/user/update-approval-program`, options, {
    headers: headers(),
  });
