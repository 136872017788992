import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import eventAPI from '../API/events/events.api';
import ContentGrid from '../components/general/ContentGrid';
import frontendRoutes from '../resources/constants/frontend.routes';

const Events = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [eventsList, setnewsList] = useState([]);

  useEffect(() => {
    getAllEvents();
  }, []);
  useEffect(() => {
    getAllEvents();
  }, [page]);

  const getAllEvents = async () => {
    const res = await eventAPI.getEventsPagination(page);
    setnewsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const handleVewEvent = (id) => {
    navigate(`${frontendRoutes.eventosEvento}/${id}`);
  };

  return (
    <>
      <ContentGrid
        data={eventsList}
        handleClick={handleVewEvent}
        title={'EVENTOS'}
      ></ContentGrid>
      <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageClick}
        containerClassName={'m-8 flex items-center justify-center'}
        previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
        nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
        pageClassName={'border border-main-blue px-4 py-1'}
        breakClassName={'border border-main-blue px-4 py-1'}
        activeClassName={'text-indigo-600'}
      />
    </>
  );
};

export default Events;
