const ModuleInformation = ({ isDisabled, values, handleChange }) => {
  return (
    <>
      <input
        type="text"
        name="title_module"
        value={values.title_module}
        onChange={handleChange}
        disabled={isDisabled ? isDisabled : false}
        className="rounded-full border px-4 w-10/12"
        placeholder="Titulo del modulo"
      />

      <textarea
        id=""
        name="description_module"
        value={values.description_module}
        onChange={handleChange}
        disabled={isDisabled ? isDisabled : false}
        className="rounded border px-4 w-10/12 resize-none"
        placeholder="Descripción de modulo"
        cols="30"
        rows="10"
      ></textarea>
    </>
  );
};

export default ModuleInformation;
