import React from 'react';

function Square({ children, classes }) {
  return (
    <section
      className={
        'flex flex-col items-center justify-center space-y-8 w-10/12 sm:w-2/3 lg:w-1/2 border border-main-blue rounded-lg bg-white my-12 px-6 py-12 ' +
        classes
      }
    >
      {children}
    </section>
  );
}

export default Square;
