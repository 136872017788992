import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userRoles } from '../../constanst/constants';
import frontRouter from '../../resources/constants/frontend.routes';
import useAuth from '../../security/auth/useAuth';
import timeUtils from '../../utils/handletime.utils';

const ItemProjectAssignment = ({
  projectId,
  cover,
  title,
  projectType,
  projectCode,
  projectAssignmentTo,
  author,
  creationDate,
  releaseDate,
  projectAssignment,
}) => {
  const { isLogged } = useAuth();
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`${frontRouter.Proyecto}/${projectId}`);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-12 p-4 gap-4 border-b border-main-blue rounded-lg'>
      <div className='md:col-span-2 flex justify-center items-center w-full'>
        <img src={`${cover}`} alt='' />
      </div>
      <div className='md:col-span-6 text-gray-500 flex flex-col justify-center space-y-2'>
        <p>
          <span className='font-semibold'>Titulo:</span> {title}
        </p>
        {projectType ? (
          <p>
            <span className='font-semibold'>Tipo de proyecto: </span>{' '}
            {projectType}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        {projectCode ? (
          <p>
            <span className='font-semibold'>Código de proyecto: </span>{' '}
            {projectCode}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        <p>
          <span className='font-semibold'>Autor</span> {author}
        </p>
        {creationDate ? (
          <p>
            <span className='font-semibold'>Fecha de publicación: </span>{' '}
            {timeUtils.formatDateofISOS(creationDate)}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        {releaseDate ? (
          <p>
            <span className='font-semibold'>Fecha de inicio: </span>{' '}
            {timeUtils.formatDateofISOS(releaseDate)}{' '}
          </p>
        ) : (
          <span className='hidden'></span>
        )}
        {isLogged.role === userRoles.coordinator && projectAssignmentTo && (
          <p className=''>
            <span className='text-gray-500 font-bold'>Evaluador:</span>{' '}
            {projectAssignmentTo?.name} {projectAssignmentTo?.last_name}
          </p>
        )}
      </div>
      <div className='md:col-span-4 flex justify-end space-x-3'>
        <p className='text-blue-600 cursor-pointer' onClick={redirect}>
          Ver
        </p>
        {/* {isLogged.role === userRoles.coordinator && !projectAssignmentTo && (
          <p className='text-green-600' onClick={() => projectAssignment()}>
            Asignar a
          </p>
        )} */}

        {/* <p className='text-green-600'>Aprobar</p>
        <p className='text-red-600'>Desaprobar</p> */}
      </div>
    </div>
  );
};

export default ItemProjectAssignment;
