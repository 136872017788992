import React from 'react';
import images from '../resources/images';
import 'animate.css';

function AboutUs() {
  return (
    <div className="bg-main-color flex-col items-center justify-center">
      <section className="grid grid-cols-1 lg:grid-cols-2 bg-main-blue py-6">
        <div className="flex justify-start items-center px-8 text-4xl font-semibold text-white">
          <h2 className="text-center md:text-left animate__animated animate__fadeInDown">
            Consejo de desarrollo científico humanístico y tecnológico (CONDES)
          </h2>
        </div>
        <div className="flex justify-center items-center text-center p-8 animate__animated animate__fadeInDown">
          <img className="rounded-xl border border-white" width={500} src={images.identificador_campaña} alt="logo" />
        </div>
      </section>
      <section className="p-8 flex flex-col items-center justify-center space-y-6">
        <img src={images.logo_condes} width={45} alt="" />
        <h1 className="text-center text-2xl font-semibold text-main-blue">
          Nosotros
        </h1>
        <p className="text-gray-500">
          Somos un cuerpo colegiado, ubicado a nivel de gobierno de la
          Universidad del Zulia, fundamentado en la Ley de Universidades, 1958 y
          creado el 2 de Mayo de 1962, por resolución del Consejo Universitario
          de LUZ en el oficio N° 460-62, promulgó el Reglamento del Consejo de
          Desarrollo, Científico, Humanístico y tecnológico. En su Reglamento
          Artículo 22. SEÑALA “El CONDES es una dependencia adscripta al
          vicerrectorado académico de LUZ, cuya finalidad es promover,
          coordinar, estimular y evaluar la investigación científica
          universitaria, su difusión y transparencia del conocimiento; así como
          el diseño de planes y políticas, que permitan tener como resultados
          conocimiento crítico, creativo y con pertinencia social”.
        </p>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-8 flex items-center justify-center flex-col space-y-6 border border-main-blue">
          <img src={images.mision} width={45} alt="" />
          <h2 className="text-center text-2xl font-semibold text-main-blue">
            Misión
          </h2>
          <p className="text-gray-500">
            Coordinar, evaluar y promover la investigación, difusión y
            transparencia de conocimiento científico, humanístico y social,
            mediante el diseño de planes y políticas, así como la ejecución
            transparente de programas de apoyo dirigido a los investigadores de
            LUZ, que permitan obtener como resultado: conocimiento crítico,
            creativo y con pertinencia social en el ámbito nacional, regional e
            internacional.
          </p>
        </div>
        <div className="p-8 flex items-center justify-center flex-col space-y-6 border border-main-blue">
          <img src={images.vision} width={45} alt="" />
          <h2 className="text-center text-2xl font-semibold text-main-blue">
            Visión
          </h2>
          <p className="text-gray-500">
            Ser líder en el diseño y promoción de políticas y planes de
            investigación, difusión y transparencia de conocimiento científico,
            que hagan posible la proyección de LUZ como institución de
            vanguardia en la actividad científica crítica, innovadora y creativa
            que aporte sostenidamente al desarrollo autónomo del país.
          </p>
        </div>
      </section>
      <section className="flex flex-col justify-center items-center py-8">
        <img src={images.target} width={45} alt="" />
        <h2 className="text-center text-2xl font-semibold text-main-blue my-8">
          Objetivos
        </h2>
        <div className="grid grid-rows-1 md:grid-rows-3">
          <ul className="list-disc grid grid-cols-1 md:grid-cols-3 px-10 gap-10 mb-8 text-gray-500">
            <li>
              Producir conocimiento que permitan avanzar en la resolución de
              problemas locales, regionales, nacionales, continentales y
              universales
            </li>
            <li>Contribuir a la formación de investigadores.</li>
            <li>
              Estimular en el estudiante la vocación por la investigación.
            </li>
          </ul>

          <ul className="list-disc grid grid-cols-1 md:grid-cols-3 px-10 gap-10 mb-8 text-gray-500">
            <li>
              <p>
                Contribuir al desarrollo científico, tecnológico y a la
                innovación.
              </p>
            </li>
            <li>
              <p>
                Fortalecer la incorporación de la generación de conocimiento en
                la docencia y la extensión.
              </p>
            </li>
            <li>
              <p>
                Promover la creación de redes de investigación
                transdisciplinaria con otros entes de investigación nacionales e
                internacionales.
              </p>
            </li>
          </ul>

          <ul className="list-disc grid grid-cols-1 md:grid-cols-3 px-10 gap-10 mb-8 text-gray-500">
            <li>
              Fomentar el pensamiento creador y el espíritu crítico y
              emprendedor.
            </li>
            <li>Consolidar los programas de postgrado.</li>
            <li>
              Estimular la investigación con pertinencia social de acuerdo con
              los planes de la Nación sobre Ciencia, Tecnología e innovación.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
