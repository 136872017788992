import handleStorage from '../../services/localstorage/handelLocalStorage';

const validateObject = async (schema, data) => {
  const valid = {
    valid: true,
    msg: '',
  };

  try {
    await schema.validate(data);
    return valid;
  } catch (error) {
    console.log('error ', error);

    valid.valid = false;
    valid.msg = error.message;
    return valid;
  }
};

const createFormData = (formRef) => {
  const user = handleStorage.getNameUser('user_condes');
  const form = new FormData(formRef);
  form.append('author', `${user.name} ${user.last_name}`);
  return form;
};

const formValidations = { validateObject, createFormData };

export default formValidations;
