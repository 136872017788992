import { Outlet } from 'react-router-dom';
import useAuth from '../../security/auth/useAuth';

const PrivateLayout = ({ children }) => {
  const { isLogged } = useAuth();

  // useEffect(() => {
  //   verifyToken();
  // }, []);

  return (
    <div>
      <Outlet />
      {children}
    </div>
  );
};

export default PrivateLayout;
