import React from "react";
import Square from "../general/Square";
import { Link } from "react-router-dom";

function ProyectIntroduction({ step, next }) {
  return (
    <div className={(step === 0) ? "w-full flex justify-center items-center" : "hidden"}>
      <Square>
        <div className="w-full flex items-center justify-between text-xl">
          <div></div>
          <h3 className="text-link text-center">Introducción</h3>
          <Link to={"/"}>
            <p className="font-bold text-red-600">x</p>
          </Link>
        </div>
        <p>
          Antes de continuar con el formulario es importante que leas un poco
          sobre la información y documentos que necesitaras.
        </p>

        <p className="text-left w-full">Puedes encontrar la información aquí</p>

        <div
          onClick={next}
          className="bg-main-blue text-white px-6 py-2 rounded-full cursor-pointer"
        >
          Continuar
        </div>

      </Square>
    </div>
  );
}

export default ProyectIntroduction;
