import handleStorage from '../services/localstorage/handelLocalStorage';

export const headers = (ContentType = 'application/json') => {
  const token = handleStorage.getTokenLocal('user_condes');
  if (token) {
    return {
      'Content-Type': ContentType,
      authorization: `bearer ${token}`,
    };
  }
};

export const roleUser = () => {
  const role = handleStorage.getRoleLocal('user_condes');
  return role;
};
