import axios from 'axios';
import routes from '../../resources/constants/backend.routes';
import { headers } from '../index';

const registerNewProject = async (data) =>
  await axios.post(`${routes.base_url}/user/register-project`, data, {
    headers: headers(),
  });

const getAllProjects = async (skip = 1, flag = '') =>
  await axios.get(
    `${routes.base_url}/user/get-all-projects-pagination?skip=${skip}&flag=${flag}`,
    { headers: headers() }
  );

const getProject = async (idProject) =>
  await axios.get(`${routes.base_url}/user/get-project-by-id?id=${idProject}`, {
    headers: headers(),
  });

const getProjectsByIdUser = async (id_user, skip = 1) =>
  await axios.get(
    `${routes.base_url}/user/get-projects-by-id-user?id_user=${id_user}&skip=${skip}`,
    { headers: headers() }
  );

const getProjectsByCommissionsRoleWithoutAssignment = async ({
  id_user,
  role,
  commissionsRole,
  skip = 1,
  flag,
}) =>
  await axios.get(
    `${routes.base_url}/user/get-projects-by-commissions-role?id_user=${id_user}&role=${role}&commissionsRole=${commissionsRole}&skip=${skip}&flag=${flag}`,
    {
      headers: headers(),
    }
  );

const updateProjectByFlag = async (data, flag) =>
  await axios.put(
    `${routes.base_url}/user/update-project-by-flag?flag=${flag}`,
    data,
    {
      headers: headers(),
    }
  );

const updateProject = async (data) =>
  await axios.put(`${routes.base_url}/user/update-project`, data, {
    headers: headers(),
  });

const updateApprovalProject = async (options) =>
  await axios.put(`${routes.base_url}/user/update-approval-project`, options, {
    headers: headers(),
  });

const searchProjectsByIdAndName = async (query) =>
  await axios.get(`${routes.base_url}/user/search-projects?query=${query}`, {
    headers: headers(),
  });

const assignmentProjectTo = async (assignment_data) =>
  await axios.post(
    `${routes.base_url}/user/assign-project-evaluator`,
    assignment_data,
    {
      headers: headers(),
    }
  );

const projectsApi = {
  registerNewProject,
  getAllProjects,
  getProject,
  getProjectsByIdUser,
  getProjectsByCommissionsRoleWithoutAssignment,
  updateProject,
  updateProjectByFlag,
  updateApprovalProject,
  searchProjectsByIdAndName,
  assignmentProjectTo,
};

export default projectsApi;
