import React from 'react';
import { userRoles } from '../../constanst/constants';
import useAuth from '../../security/auth/useAuth';
import timeUtils from '../../utils/handletime.utils';

const ItemProgramAdmin = ({
  programId,
  programType,
  programCode,
  programAssignment,
  programAssignmentTo,
  releaseDate,
  redirect,
  title,
  author,
  creationDate,
  cover,
}) => {
  const { isLogged } = useAuth();

  return (
    <div className='w-full grid grid-rows-1 border border-main-blue rounded-lg'>
      <div className='grid grid-cols-1 md:grid-cols-12 p-4 gap-4 border-b border-main-blue rounded-lg'>
        <div className='md:col-span-2 flex justify-center items-center w-full'>
          <img src={`${cover}`} alt='' />
        </div>
        <div className='md:col-span-6 text-gray-500 flex flex-col justify-center space-y-2'>
          <p>
            <span className='font-semibold'>Titulo:</span> {title}
          </p>
          {programType ? (
            <p>
              <span className='font-semibold'>Tipo de programa: </span>{' '}
              {programType}{' '}
            </p>
          ) : (
            <span className='hidden'></span>
          )}
          {programCode ? (
            <p>
              <span className='font-semibold'>Código de programa: </span>{' '}
              {programCode}{' '}
            </p>
          ) : (
            <span className='hidden'></span>
          )}
          <p>
            <span className='font-semibold'>Autor</span> {author}
          </p>
          {creationDate ? (
            <p>
              <span className='font-semibold'>Fecha de publicación: </span>{' '}
              {timeUtils.formatDateofISOS(creationDate)}{' '}
            </p>
          ) : (
            <span className='hidden'></span>
          )}
          {isLogged.role === userRoles.coordinator && programAssignmentTo && (
            <p className=''>
              <span className='text-gray-500 font-bold'>Evaluador:</span>{' '}
              {programAssignmentTo?.name} {programAssignmentTo?.last_name}
            </p>
          )}
        </div>
        <div className='md:col-span-4 flex justify-end items-end space-x-3'>
          <p className='text-blue-600 cursor-pointer' onClick={redirect}>
            Ver
          </p>
          {/* {isLogged.role === userRoles.coordinator && !programAssignmentTo && (
            <p
              className='text-green-600 cursor-pointer'
              onClick={() => programAssignment()}
            >
              Asignar a
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ItemProgramAdmin;
