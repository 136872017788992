import { convertToRaw, EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { v4 as uuidv4 } from 'uuid';

const API_IMG_URL = process.env.REACT_APP_CDN_IMAGE_API_URL;
const API_IMG_KEY = process.env.REACT_APP_CDN_IMAGE_API_KEY;

const uploadImageCallBack = async (file) => {
  const data = new FormData();
  data.append('image', file);

  try {
    const responseImgData = await fetch(`${API_IMG_URL}?key=${API_IMG_KEY}`, {
      method: 'POST',
      body: data,
    });

    const resJson = await responseImgData.json();

    const objRes = { data: { link: resJson.data.display_url } };

    return objRes;
  } catch (error) {
    console.log('error al subir imagen', error);
    return null;
  }
};

const getStringHTMLFromStateEditor = (stateEditor) => {
  const content = convertToRaw(stateEditor);
  const contentStringHTML = draftToHtml(content);
  return contentStringHTML;
};

const createEditorStateFromHTML = (html) => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const state = EditorState.createWithContent(contentState);
  return state;
};

const generateCodeProgram = (typeProject) => {
  const content = {
    'no financiado': 'NF-',
    financiado: 'FI-',
    asociado: 'AS-',
  };

  return content[typeProject] + uuidv4();
};

const utils = {
  uploadImageCallBack,
  getStringHTMLFromStateEditor,
  createEditorStateFromHTML,
  generateCodeProgram,
};
export default utils;
