import React from 'react';
import { EditorState } from 'draft-js';
import newsTypes from './types/news.types';
import eventsTypes from './types/events.types';
import coursesTypes from './types/courses.types';

export const ContextApp = React.createContext();

const reducer = (state, { type, payload }) => {
  switch (type) {
    // news
    case newsTypes.HANDLE_CHANGE_EDITOR_NEWS:
      return {
        ...state,
        stateEditorNews: payload,
      };

    case newsTypes.SET_NEW_TO_EDIT:
      return {
        ...state,
        newsToEdit: payload,
        stateEditorNews: payload.content,
      };

    case newsTypes.RESET_NEW_TO_EDIT:
      return {
        ...state,
        newsToEdit: {},
        stateEditorNews: EditorState.createEmpty(),
      };

    // events
    case eventsTypes.HANDLE_CHANGE_EDITOR_EVENTS:
      return {
        ...state,
        stateEditorEvents: payload,
      };

    case eventsTypes.SET_EVENT_TO_EDIT:
      return {
        ...state,
        eventsToEdit: payload,
        stateEditorEvents: payload.content,
      };

    case eventsTypes.RESET_EVENT_TO_EDIT:
      return {
        ...state,
        eventsToEdit: {},
        stateEditorEvents: EditorState.createEmpty(),
      };

    // courses
    case coursesTypes.SET_COURSE_TO_EDIT:
      return {
        ...state,
        courseToEdit: payload,
      };

    case coursesTypes.RESET_COURSE_TO_EDIT:
      return {
        ...state,
        courseToEdit: {},
      };

    default:
      return { ...state };
  }
};

const GeneralContext = ({ children }) => {
  const initialState = {
    // news
    newsToEdit: {},
    stateEditorNews: EditorState.createEmpty(),
    // events
    eventsToEdit: {},
    stateEditorEvents: EditorState.createEmpty(),
    // courses
    courseToEdit: {},
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ContextApp.Provider value={{ state, dispatch }}>
      {children}
    </ContextApp.Provider>
  );
};

export default GeneralContext;
