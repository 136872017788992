import { useEffect, useState } from 'react';
import frontendRoutes from '../../resources/constants/frontend.routes';
import GridArticle from '../../components/GridArticle';
import { Link, useNavigate } from 'react-router-dom';
import eventAPI from '../../API/events/events.api';
function Events() {
  const navigate = useNavigate();

  const [eventsList, seteventsList] = useState([]);

  useEffect(() => {
    const getAllEvents = async () => {
      const res = await eventAPI.getEventsPagination();
      seteventsList(res.data.data.data);
    };

    getAllEvents();
  }, []);

  const handleVewEvent = (id) => {
    navigate(`${frontendRoutes.eventosEvento}/${id}`);
  };

  return (
    <section className="bg-half-background bg-cover bg-center bg-no-repeat">
      <div className="md:h-90%-vh md:min-h-800 md:flex md:items-center md:justify-center md:flex-col">
        <div className="w-full flex flex-row items-center justify-between py-8">
          <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
          <h2 className="w-6/12 text-2xl text-main-blue text-center font-bold">
            EVENTOS
          </h2>
          <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-4 md:px-3 text-center">
          {eventsList.map((item) => (
            <GridArticle
              key={item._id}
              img={item.pathImage}
              title={item.title}
              handleClick={() => handleVewEvent(item._id)}
            />
          ))}
          {/* <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          <GridArticle
            img={images.conferencia}
            title={"Conferencias: un metodo para informar"}
          />
          */}
        </div>
        <div className="flex items-center justify-center py-8">
          <Link to={'/eventos'}>
            <p className="text-link font-medium cursor-pointer">Ver más</p>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Events;
