import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paginate from 'react-paginate';
import frontRoutes from "../resources/constants/frontend.routes"
import projectsApi from '../API/projects/projects.api';
import AdminOptions from '../components/admin/AdminOptions';
import ElementList from '../components/admin/ElementList';
import ContainerModal from '../components/general/ContainerModal';
import MainButton from '../components/general/MainButton';
import ModalToExplainUnApproval from '../components/general/ModalToExplainUnApproval';

function AdminProyectsList() {

  const navigate = useNavigate()

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [projectsList, setProjectsList] = useState([]);
  const flatSearchProjects = 'por aprobar';

  const [modalUnApprovalProject, setModalUnApprovalProject] = useState(false);
  const [unApprovalId, setUnApprovalId] = useState('');

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getProjects();
  }, [page]);

  const getProjectsByFlag = async (flag = 'por aprobar') => {
    if (flag === '') return getProjects();

    const res = await projectsApi.getAllProjects(page, flag);
    setProjectsList(res.data.data.data);
    setPageCount(res.data.data.pagination.pageCount);
  };

  const getProjects = async () => {
    const response = await projectsApi.getAllProjects(page, flatSearchProjects);
    setProjectsList(response.data.data.data);
    setPageCount(response.data.data.pagination.pageCount);
  };

  const handlePageClick = (data) => setPage(data.selected + 1);

  const handleUnApproval = (state, idProject) => {
    setModalUnApprovalProject(true);
    setUnApprovalId(idProject);
  };

  const handleSendUnApproval = (unApproval) => {
    setModalUnApprovalProject(false);
    updateApprovalProject(false, unApprovalId, unApproval);
  };

  const updateApprovalProject = async (state, id, dataUnApproval = '') => {
    const data = {
      id: id,
      isApproval: state,
      object: dataUnApproval !== '' ? dataUnApproval : null,
    };
    const response = await projectsApi.updateApprovalProject(data);
    response.data.success &&
      alert('Correo de actualización de estado del proyecto ha sido enviado.');
    getProjects();
  };

  const handleSearchProjects = async ({ target }) => {
    if (target.value.trim() === '') {
      return getProjectsByFlag();
    }

    const projectsList = await projectsApi.searchProjectsByIdAndName(
      target.value.toLowerCase()
    );

    if (projectsList.data.success) {
      setProjectsList(projectsList.data.data.data);
      setPageCount(projectsList.data.data.pagination.pageCount);
    }
  };

  return (
    <>
      {modalUnApprovalProject && (
        <ContainerModal
          title='Especifique porque desaprueba este projecto'
          closeModal={() => setModalUnApprovalProject(false)}
        >
          <ModalToExplainUnApproval handleClick={handleSendUnApproval} />
        </ContainerModal>
      )}
      <div className='flex flex-col justify-center items-center space-y-8 p-6'>
        <AdminOptions />
        <div className='flex justify-center items-center w-full'>
          <input
            type='text'
            className='border border-main-blue rounded-l-full py-2 px-2 w-10/12 md:w-6/12'
            placeholder='Buscar un proyecto...'
            onChange={handleSearchProjects}
          />
          <MainButton
            text={'Buscar'}
            classes={'rounded-l-none border border-main-blue'}
          />
        </div>

        <div className='w-full py-2 flex justify-end'>
        <MainButton
          text={'Documentos de proyectos'}
          classes={'border border-main-blue'}
          event={() => navigate(frontRoutes.panelAdminDocumentsProjects)}
        />
      </div>

        <div className='flex w-full items-start justify-around bg-main-blue rounded-lg text-white p-2 space-x-4'>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('por aprobar')}
          >
            Pendientes
          </p>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('aprobado')}
          >
            Aprobados
          </p>
          <p
            className='cursor-pointer'
            onClick={() => getProjectsByFlag('desaprobado')}
          >
            No aprobados
          </p>
        </div>
        <div className='grid grid-rows-1 w-full border border-main-blue rounded-lg'>
          {projectsList?.length > 0 &&
            projectsList.map((project) => (
              <ElementList
                key={project._id}
                idItem={project._id}
                type='proyects'
                projectType={project.type_project}
                projectCode={project.program_code}
                projectApproval={updateApprovalProject}
                projectUnApproval={handleUnApproval}
                projectState={project.status_project}
                title={project.title}
                author={project.name}
                creationDate={project.createdAt}
                cover='https://i.ibb.co/LPWY38D/identificador-campana.jpg'
              />
            ))}
        </div>
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={'m-8 flex items-center justify-center'}
          previousClassName={'border border-main-blue rounded-l-full px-4 py-1'}
          nextClassName={'border border-main-blue rounded-r-full px-4 py-1'}
          pageClassName={'border border-main-blue px-4 py-1'}
          breakClassName={'border border-main-blue px-4 py-1'}
          activeClassName={'text-indigo-600'}
        />
      </div>
    </>
  );
}

export default AdminProyectsList;
