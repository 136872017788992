function ProyectComplement(props) {
  const {
    name,
    last_name,
    type_identification,
    identification,
    profession,
    specialty,
    institution,
    faculty_core,
    category,
    research_entity,
    last_date_promotion,
    email,
    cod_number,
    phone,
    position,
  } = props.data;

  return (
    <>
      <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
        <input
          disabled={true}
          type="text"
          placeholder="Nombre"
          className="rounded-full border px-4 w-full md:w-1/2"
          value={name}
        />
        <input
          disabled={true}
          type="text"
          placeholder="Apellido"
          className="rounded-full border px-4 w-full md:w-1/2"
          value={last_name}
        />
      </div>
      <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
        <select
          disabled={true}
          className="rounded-full border px-4 w-full md:w-2/12"
          value={type_identification}
        >
          <option value="V">V</option>
          <option value="E">E</option>
          <option value="J">J</option>
        </select>

        <input
          disabled={true}
          type="number"
          placeholder="cedula de identidad"
          className="rounded-full border px-4 w-full md:w-10/12"
          value={identification}
        />
      </div>
      <input
        disabled={true}
        type="text"
        placeholder="Profesión"
        className="rounded-full border px-4 w-10/12"
        value={profession}
      />
      <input
        disabled={true}
        type="text"
        placeholder="Especialidad"
        className="rounded-full border px-4 w-10/12"
        value={specialty}
      />
      <input
        disabled={true}
        type="text"
        placeholder="Institución"
        className="rounded-full border px-4 w-10/12"
        value={institution}
      />

      <input
        disabled={true}
        className="rounded-full border px-4 w-10/12"
        value={faculty_core}
        placeholder="Facultad/Núcleo"
      />

      <select
        disabled={true}
        className="rounded-full border px-4 w-10/12"
        placeholder="categoria"
        value={category}
      >
        <option disabled selected>
          Categoria
        </option>
        <option value="">Agregado</option>
        <option value="">Asistente</option>
        <option value="">Asociado</option>
        <option value="">Titular</option>
      </select>

      <input
        disabled={true}
        type="text"
        placeholder="Ente de investigación"
        className="rounded-full border px-4 w-10/12"
        value={research_entity}
      />

      <div className="flex flex-col space-y-4 w-10/12">
        <p className="px-1">Última fecha de ascenso</p>
        <input
          disabled={true}
          type="date"
          placeholder="Fecha de ascenso"
          className="rounded-full border px-4 w-full"
          value={new Date(last_date_promotion).toString()}
        />
      </div>

      <input
        disabled={true}
        type="email"
        placeholder="Email"
        className="rounded-full border px-4 w-10/12"
        value={email}
      />

      <div className="flex flex-col items-center justify-center md:flex-row md:space-x-4 space-y-8 md:space-y-0 w-10/12">
        <input
          disabled={true}
          type="text"
          placeholder="+ Código"
          className="rounded-full border px-4 w-full md:w-1/3"
          value={cod_number}
        />
        <input
          disabled={true}
          type="number"
          placeholder="Número de teléfono"
          className="rounded-full border px-4 w-full md:w-2/3"
          value={phone}
        />
      </div>

      <input
        disabled={true}
        type="text"
        placeholder="Cargo"
        className="rounded-full border px-4 w-10/12"
        value={position}
      />

      {/* <select disabled={true} className="rounded-full border px-4 w-10/12">
        <option selected disabled>
          Cargo
        </option>
        {positions.map((position, key) => {
          return (
            <option key={key} value={position}>
              {position}
            </option>
          );
        })}
      </select> */}

      <div className="border border-gray-600/25 w-10/12" />
    </>
  );
}

export default ProyectComplement;
