import Square from '../general/Square';
import { Link } from 'react-router-dom';
import MainButton from '../general/MainButton';

function ProyectSixthStep({ step, restart }) {
  return (
    <div
      className={
        step === 6
          ? 'w-full flex flex-col justify-center items-center'
          : 'hidden'
      }
    >
      <Square>
        <div className='w-10/12 flex items-center justify-between text-xl'>
          <p className='text-link'></p>
          <Link to={'/'}>
            <p className='font-bold text-red-600'>x</p>
          </Link>
        </div>
        <h3 className='text-link text-center text-2xl font-bold my-12'>
          Proyecto registrado
        </h3>
        <p>
          Sera notificado por correo cuando su proyecto sea aprobado o no.
          Tambien podra verificarlo en la seccion de "Mis proyectos"
        </p>
        <MainButton text={'Registrar otro proyecto'} event={restart} />
      </Square>
    </div>
  );
}

export default ProyectSixthStep;
