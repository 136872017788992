export const uploadFilesToGoogleDrive = async (dataSend) => {
  try {
    const response = await fetch(process.env.REACT_APP_GOOGLE_DRIVE_API, {
      method: 'POST',
      body: JSON.stringify(dataSend),
    });

    const docUrl = await response.json();

    return docUrl;
  } catch (error) {
    return null;
  }
};
