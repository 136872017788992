import axios from 'axios';
import routes from '../../resources/constants/backend.routes';
import handleStorage from '../../services/localstorage/handelLocalStorage';
import { headers } from '../index';

// verificar token
const verifyToken = async () =>
  await axios.post(
    `${routes.base_url}/user/verify-token`,
    {},
    { headers: headers() }
  );

const createNewUSer = async (data) =>
  await axios.post(`${routes.base_url}/user/signin`, data);

const updateUserSignin = async (data) =>
  await axios.put(`${routes.base_url}/user/update-sigin-user`, data);

const login = async (data) =>
  await axios.post(`${routes.base_url}/user/login`, data);

const getDataUser = async () => {
  const user = handleStorage.getItemLocalStorage('user_condes');
  if (user === null) return;
  return await axios.get(
    `${routes.base_url}/user/get-data-user?id=${user.email}`,
    {
      headers: headers(),
    }
  );
};

const updateDataUser = async (data) => {
  return await axios.put(`${routes.base_url}/user/update-data-user`, data, {
    headers: headers(),
  });
};

// const updateDataUser = async (data) => {
//   const res = await axios({
//     method: 'POST',
//     url: routes.base_url + '/admin/update-user-from-user',
//     data: data,
//     headers: headers(),
//   });

//   return res;
//   // console.log('data ->', data);
//   // return await axios.post(
//   //   `${routes.base_url}/admin/update-user-from-user`,
//   //   data,
//   //   {
//   //     headers: headers(),
//   //   }
//   // );
// };

const createUserFromAdmin = async (data) =>
  await axios.post(`${routes.base_url}/admin/create-user-for-admin`, data, {
    headers: headers(),
  });

const updateDataUserFromAdmin = async (data) =>
  await axios.put(`${routes.base_url}/admin/update-data-user`, data, {
    headers: headers(),
  });

const getUserList = async (skip = 1) => {
  const id_user = handleStorage.getIdUser();
  return await axios.get(
    `${routes.base_url}/admin/get-users-list?id_user=${id_user}&skip=${skip}`,
    {
      headers: headers(),
    }
  );
};

const getUsersByRoleAndCommissionsRole = async (
  role,
  commissionsRole,
  skip = 1
) =>
  await axios.get(
    `${routes.base_url}/admin/get-users-by-role-and-commissions-role?role=${role}&commissionsRole=${commissionsRole}&skip=${skip}`,
    {
      headers: headers(),
    }
  );

const getDataUserByID = async (id) =>
  await axios.get(`${routes.base_url}/admin/get-data-user?id=${id}`, {
    headers: headers(),
  });

const searchUsers = async (query) =>
  await axios.get(`${routes.base_url}/admin/search-users?query=${query}`, {
    headers: headers(),
  });

const usersApi = {
  verifyToken,
  createNewUSer,
  updateUserSignin,
  login,
  updateDataUser,
  createUserFromAdmin,
  updateDataUserFromAdmin,
  getDataUser,
  getUserList,
  getDataUserByID,
  getUsersByRoleAndCommissionsRole,
  searchUsers,
};

export default usersApi;
