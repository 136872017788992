import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import newsApi from '../../API/news/news.api.js';
import frontendRoutes from '../../resources/constants/frontend.routes.js';
import GridArticle from '../../components/GridArticle';

const News = () => {
  const navigate = useNavigate();

  const [newsList, setnewsList] = useState([]);

  useEffect(() => {
    const getAllNews = async () => {
      const res = await newsApi.getNewsByPagination();
      setnewsList(res.data.data.data);
    };

    getAllNews();
  }, []);

  const handleVewNew = (id) => {
    navigate(`${frontendRoutes.noticiasNoticia}/${id}`);
  };

  return (
    <section className="bg-path bg-center bg-no-repeat">
      <div className="md:h-90%-vh md:min-h-800 md:flex md:items-center md:justify-center md:flex-col">
        <div className="w-full flex flex-row items-center justify-between py-8">
          <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
          <h2 className="w-6/12 text-2xl text-main-blue text-center font-bold">
            NOTICIAS
          </h2>
          <div className="w-3/12 md:w-1/3 border border-main-blue border-solid" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-4 md:px-3 text-center">
          {newsList.map((item) => (
            <GridArticle
              key={item._id}
              img={item.pathImage}
              title={item.title}
              handleClick={() => handleVewNew(item._id)}
            />
          ))}
        </div>
        <div className="flex items-center justify-center py-8">
          <Link to={'/noticias'}>
            <p className="text-link font-medium cursor-pointer">Ver más</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default News;
