import { Outlet, Navigate } from 'react-router-dom';
import { userRoles } from '../../constanst/constants';
import frontRoute from '../../resources/constants/frontend.routes';
import useAuth from './useAuth';

const PrivateRoute = () => {
  const { isLogged, verifyToken } = useAuth();

  // useEffect(() => {
  //   verifyToken();
  // }, []);

  if (!isLogged) return <Navigate to={`${frontRoute.login}`} />;

  // if (isLogged.role === 'admin' || isLogged.role === 'user') return <Outlet />;

  if (Object.values(userRoles).includes(isLogged.role)) return <Outlet />;

  return <Navigate to={`${frontRoute.login}`} />;
};

export default PrivateRoute;
