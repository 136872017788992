import axios from 'axios';
import { headers } from '../index';
import routes from '../../resources/constants/backend.routes';

const createEvent = async (data) =>
  await axios.post(`${routes.base_url}/admin/create-event`, data, {
    headers: headers(),
  });

const getAllEvent = async () =>
  await axios.get(`${routes.base_url}/admin/get-all-events`, {
    headers: headers(),
  });

const getEventsPagination = async (skip = 1) =>
  await axios.get(
    `${routes.base_url}/admin/get-events-pagination?skip=${skip}`,
    {
      headers: headers(),
    }
  );

const getEventoById = async (id) =>
  await axios.get(`${routes.base_url}/admin/get-event-by-id?id=${id}`, {
    headers: headers(),
  });

const searchEvents = async (query) =>
  await axios.get(`${routes.base_url}/admin/search-events?query=${query}`, {
    headers: headers(),
  });

const editEvent = async (data) =>
  await axios.put(`${routes.base_url}/admin/update-event`, data, {
    headers: headers(),
  });

const deletedEvent = async (id) =>
  await axios.delete(`${routes.base_url}/admin/delete-event?id=${id}`, {
    headers: headers('multipart/form-data'),
  });

const eventAPI = {
  createEvent,
  getAllEvent,
  getEventsPagination,
  getEventoById,
  searchEvents,
  editEvent,
  deletedEvent,
};

export default eventAPI;
