import { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContextApp } from '../store/GeneralContext';
import utilsFunctions from '../utils/commonFunctions.js';
import MainButton from '../components/general/MainButton';
import Square from '../components/general/Square';
import newsTypes from '../store/types/news.types';
import newsSchema from '../security/schemas/news.schema';
import newsApi from '../API/news/news.api';
import formValidations from '../security/validations/validationForms';
import EditorContent from '../components/forms/EditorContent';
import Loader from '../components/general/Loader';
import handleStorage from '../services/localstorage/handelLocalStorage';

function AddNews() {
  const { dispatch, state } = useContext(ContextApp);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newsSchema),
  });

  const formRef = useRef(null);

  const [messageNotification, setMessageNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const saveNews = async (data) => {
    const user = handleStorage.getNameUser('user_condes');

    if (!data.portada[0]) {
      return setError('portada', {
        type: 'manual',
        message: 'Debe escoger una imagen.',
      });
    }

    const contentStringHTML = utilsFunctions.getStringHTMLFromStateEditor(
      state.stateEditorNews.getCurrentContent()
    );

    setIsLoading(true);

    const finalPathImg = await utilsFunctions.uploadImageCallBack(
      data.portada[0]
    );

    if (finalPathImg === null) {
      setIsLoading(false);
      return setError('portada', {
        type: 'manual',
        message: 'Error al subir imagen de portada.',
      });
    }

    const newsForm = {
      titulo: data.titulo,
      author: `${user.name} ${user.last_name}`,
      contenido: contentStringHTML,
      finalPathImg: finalPathImg.data.link,
    };

    const res = await newsApi.createNews(newsForm);

    setIsLoading(false);

    setMessageNotification(res.data.msg);

    if (res.data.success) {
      reset();
      dispatch({
        type: newsTypes.RESET_NEW_TO_EDIT,
      });
    }
  };

  const onEditorStateChange = (editorState) => {
    dispatch({
      type: newsTypes.HANDLE_CHANGE_EDITOR_NEWS,
      payload: editorState,
    });
  };

  return (
    <>
      <section className='bg-main-background py-8 flex flex-col items-center justify-center w-full'>
        <h1 className='text-4xl font-bold text-main-blue'>Agregar noticia</h1>
        <Square classes='lg:min-w-[70%] lg:py-8'>
          <div className='w-full flex items-center justify-end text-red-600'>
            <Link to={'/panel/lista-de-noticias'}>
              <p className='font-bold'>x</p>
            </Link>
          </div>
          {errors?.titulo && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.titulo?.message}
            </div>
          )}
          {errors?.contenido && (
            <div className='w-full flex items-center justify-center text-red-600'>
              {errors?.contenido?.message}
            </div>
          )}
          {messageNotification !== '' && (
            <div className='w-full flex items-center justify-center text-green-600'>
              {messageNotification}
            </div>
          )}
          <form
            ref={formRef}
            className='form-content'
            onSubmit={handleSubmit(saveNews)}
          >
            <input
              type='text'
              className='rounded-full border px-4 w-10/12 lg:w-full'
              placeholder='Titulo'
              name='titulo'
              {...register('titulo')}
            />
            <EditorContent
              editorState={state.stateEditorNews}
              onEditorStateChange={onEditorStateChange}
            />
            {/* <textarea
              className="rounded border px-4 w-10/12 resize-none"
              placeholder="Contenido"
              name="contenido"
              {...register('contenido')}
              cols="30"
              rows="10"
            ></textarea> */}
            <div className='w-10/12 lg:w-full flex-col flex justify-center items-start'>
              <p>Seleccionar portada</p>
              <input
                className='px-4 w-10/12'
                accept='image/png, image/jpeg, image/jpg'
                type='file'
                name='portada'
                {...register('portada')}
              />
              {errors?.portada && (
                <div className='w-full flex items-center justify-center text-red-600'>
                  {errors?.portada?.message}
                </div>
              )}
            </div>
            <MainButton text='Guardar' disabled={isLoading} />
          </form>
          <Loader isOpen={isLoading} />
        </Square>
      </section>
    </>
  );
}

export default AddNews;
